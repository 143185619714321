import { Box, Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useLocation } from "react-router";
import PageHeading from "../../../../components/PageHeading";
import { priColor, title } from "../../../../constants";
import useSettings from "../../../../hooks/useSettings";
import { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import {
  getDateTime,
  getDeptName,
  getInstallationDate2,
  getPanchayatTextAppend,
  getSGMStrength,
  setSiteSurveyAndDevicePhotos,
} from "../../../../utils/helper";
import { getAllDeviceTelemetry } from "../../../../slices/debugging";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DialogUI from "../../../../components/ImageViewer";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  checkIfBarCodeAssignedToWard,
  linkDeviceToWardAction,
  linkDeviceUpdateWardStatus,
  resetBarcodeAssignedToWard,
  resetLinkDeviceToWard,
  resetManualWardStatusUpdate,
} from "../../../../slices/firmwareRegistration";
import { Tooltip } from "@mui/material";
import { CheckBoxRounded, InfoSharp } from "@material-ui/icons";
import {
  resetSaveSurveydetailsReponse,
  saveSurveyDetails,
} from "../../../../slices/siteInfo";
import { fileUpload, manualUpdateWardImages } from "./_fileUpload";
import ShowWhen from "../../../../components/common/ShowWhen";
import toast from "react-hot-toast";
import { Loader } from "../../../../components/Loader/Loader";
import ImagesWrapper from "../../../../components/common/ImagesWrapper";

function TestItem({ data }) {
  const status = data.toLowerCase().includes("pass");
  return (
    <span
      style={{
        marginLeft: 8,
        fontWeight: "bold",
        color: status ? "green" : "red",
      }}
    >
      {data}
    </span>
  );
}

function OtherDetailsItem(props) {
  return (
    <Grid item md={12} style={{ display: "flex", flexDirection: "row" }}>
      <Typography color="textSecondary" variant="subtitle2">
        {props.title}
      </Typography>
      <span
        style={{
          marginLeft: 8,
          fontWeight: "bold",
        }}
      >
        <select
          defaultValue={props.value}
          style={{ borderColor: props.error ? "red" : "" }}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
        >
          <option value="">Select Value</option>
          {Object.keys(props.options).map((e, i) => (
            <option key={i} value={e}>
              {props.options[e]}
            </option>
          ))}
        </select>
      </span>
    </Grid>
  );
}

export const isDisabled = (deviceId) => {
  if (Number(deviceId) >= 9000000001) {
    return true;
  } else {
    return false;
  }
};

function InstallationReportDeviceInfoManualEntry() {
  const siteImageObjectInitialValues = {
    anurakshak: null,
    site: null,
    "electricity meter": null,
    starter: null,
    deviceImage: null,
  };
  const [siteImageObject, setSiteImageObject] = useState(
    siteImageObjectInitialValues
  );
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const {
    barCodeAssignedToWard,
    linkDeviceToWard,
    manualLinkingUpdateWardStatus,
  } = useSelector((state) => state.firmwareRegistration);
  const { saveSurveyDetailsData } = useSelector((state) => state.siteinfo);
  const { allDeviceTelemetry: allDeviceData } = useSelector(
    (state) => state.debugging
  );
  const [open, setOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(null);
  const [surveyImages, setSurveyImages] = useState([]);
  const [deviceImages, setDeviceImages] = useState([]);
  const settings = useSettings();
  const [wardData, setWardData] = useState({});
  const [deviceBarcode, setDeviceBarcode] = useState("");
  const [dateOfInstallation, setDateOfInstallation] = useState("");

  const [enableLinkingDevice, setEnableLinkingDevice] = useState(null);
  const [devRegData, setDevRegData] = useState(null);

  const [linkingStatus, setLinkingStatus] = useState(null);
  const [wardUpdateStatus, setWardUpdateStatus] = useState(null);

  const [testStatus, setTestStatus] = useState(null);

  useEffect(() => {
    if (saveSurveyDetailsData && saveSurveyDetailsData.statusCode == 200) {
      setWardUpdateStatus("Success");
    }
  }, [saveSurveyDetailsData]);

  useEffect(() => {
    setWardUpdateStatus(null);
    dispatch(resetSaveSurveydetailsReponse());
    dispatch(resetManualWardStatusUpdate());
    dispatch(resetBarcodeAssignedToWard());
    setLinkingStatus(null);
    dispatch(resetLinkDeviceToWard());

    if (location.state.wardData) {
      let wdt = location.state.wardData;
      let keys = ["electricity_meter", "starter"];
      keys.forEach((key) => {
        if (wdt && wdt?.survey_data && wdt?.survey_data?.[key]?.status) {
          let eStatus = wdt?.survey_data?.[key]?.status;
          if (typeof eStatus === "string" && eStatus?.length > 0) {
            eStatus = eStatus.trim().toLowerCase();
            if (eStatus === "working" || eStatus === "true") {
              wdt.survey_data[key].status = true;
            } else {
              wdt.survey_data[key].status = false;
            }
          }
        }
      });

      setWardData(wdt);
      setDeviceBarcode(wdt?.regData?.barcode);
      setDateOfInstallation(wdt?.installation_data?.date);
      const { siteSurveyImages, device_images } =
        setSiteSurveyAndDevicePhotos(wdt);
      setSurveyImages(siteSurveyImages);
      setDeviceImages(device_images);
    }
  }, [location.state.wardData]);

  const validationDeviceDetails = () => {
    if (deviceBarcode && deviceBarcode != "" && dateOfInstallation) {
      dispatch(checkIfBarCodeAssignedToWard(deviceBarcode));
    } else {
      toast.error("Enter device barcode & date of installation");
    }
  };

  useEffect(() => {
    if (barCodeAssignedToWard && barCodeAssignedToWard.statusCode === 200) {
      const data = barCodeAssignedToWard.data;
      if (data.regdata && data.regdata.deviceId) {
        setEnableLinkingDevice(data.canbeAssigned);
        setDevRegData(data.regdata);
      } else {
        toast.error("Provide valid details for the device!");
      }
    } else if (
      barCodeAssignedToWard &&
      barCodeAssignedToWard.statusCode === 404
    ) {
      toast.error(
        barCodeAssignedToWard.message || "Provided barcode details not found!"
      );
    }
  }, [barCodeAssignedToWard]);

  useEffect(() => {
    if (
      manualLinkingUpdateWardStatus &&
      manualLinkingUpdateWardStatus.statusCode == 200
    ) {
      toast.success("Status updated successfully");
    }
  }, [manualLinkingUpdateWardStatus]);

  const linkDeviceToWardHandler = () => {
    let date = new Date().setHours(0, 0, 0, 0) / 1000;
    if (dateOfInstallation) {
      date = new Date(dateOfInstallation).setHours(0, 0, 0, 0) / 1000;
    }
    dispatch(linkDeviceToWardAction(devRegData.deviceId, wardData.id, date));
  };

  useEffect(() => {
    if (linkDeviceToWard && linkDeviceToWard.statusCode == 200) {
      setLinkingStatus("Device Linking Success");
    } else {
      // setLinkingStatus("Device Linking Failed")
    }
  }, [linkDeviceToWard]);

  const runTest = () => {
    if (devRegData) {
      const deviceId = devRegData.deviceId;
      const dateFrom = new Date(dateOfInstallation).setHours(0, 0, 0, 0) / 1000;
      const dateTo = new Date().getTime() / 1000;

      dispatch(
        getAllDeviceTelemetry(deviceId, "", parseInt(dateTo - dateFrom) / 60)
      );
    } else {
      toast.error("First link the device");
    }
  };

  useEffect(() => {
    let testStatus = {};
    if (allDeviceData && allDeviceData.statusCode == 200) {
      const telemetryData = allDeviceData.data;
      if (telemetryData.length) {
        testStatus["power_status"] = "Test Pass";
        testStatus["device_hb"] = getDateTime(telemetryData[0]["_ts"]);
        testStatus["newtwork_status"] = getSGMStrength(telemetryData[0]["g"]);
        const motorOnData = telemetryData.map((e) => {
          if (e.p == "1" && e.l == "1" && e.m == "4") {
            return e;
          }
        });

        if (motorOnData.length) {
          testStatus["motor_on_status"] = "Test Pass";
          testStatus["overall"] = "pass";
        } else {
          testStatus["motor_on_status"] = "Test Failed";
          testStatus["overall"] = "fail";
        }
      } else {
        testStatus["power_status"] = "Test Failed";
        testStatus["device_hb"] = null;
        testStatus["motor_on_status"] = "Test Failed";
        testStatus["overall"] = "fail";
      }
    }
    setTestStatus(testStatus);
  }, [allDeviceData]);

  const onImageChange = (e, title) => {
    let siteImageObjectTemp = { ...siteImageObject };
    siteImageObjectTemp[title] = e.target.files.length
      ? e.target.files[0]
      : null;
    setSiteImageObject(siteImageObjectTemp);
  };

  const saveImages = async () => {
    let error = false;

    if (
      siteImageObject?.anurakshak === null &&
      siteImageObject?.deviceImage === null &&
      siteImageObject?.["electricity meter"] === null &&
      siteImageObject?.site === null &&
      siteImageObject?.starter === null
    ) {
      error = true;
    }

    if (error) {
      toast.error("Please select images");
    } else {
      const uploadedURLS = {};
      const imagetitles = Object.keys(siteImageObject);

      try {
        setLoading(true);
        for (let i = 0; i < imagetitles.length; i++) {
          if (siteImageObject[imagetitles[i]] !== null) {
            const imageURL = await fileUpload(siteImageObject[imagetitles[i]]);
            uploadedURLS[imagetitles[i]] = imageURL;
          }
        }

        if (uploadedURLS) {
          manualUpdateWardImages(wardData.id, uploadedURLS)
            .then((resData) => {
              setLoading(false);
              if (resData) {
                toast.success(
                  "Ward updated successfully. Check Details from installation report page"
                );
                setSiteImageObject(siteImageObjectInitialValues);
              }
            })
            .finally(setLoading(false));
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const surveyDate = ({ date }) => {
    if (!date) return;
    const { date: newDate, time } = getDateTime(date);
    return (
      <>
        <span>{`${newDate} ${time}`}</span>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title.label2} | Installation Device Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Installation"}
            prevPath={[
              { name: " Installation Report" },
              { name: "Scheme Installation Approval" },
            ]}
          />

          <Box sx={{ py: 1 }}>
            <Grid container>
              <Grid
                item
                md={6}
                sx={{
                  border: "1px solid #CCC",
                  backgroundColor: "#FFF",
                  borderRadius: 1,
                  padding: 2,
                }}
              >
                <Grid
                  item
                  md={12}
                  display="flex"
                  flexDirection={"row"}
                  justifyContent="center"
                  marginBottom={5}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    style={{ color: priColor }}
                  >
                    Site Survey
                  </Typography>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <Typography color={priColor} variant="subtitle2">
                      Site Details
                    </Typography>

                    <Box sx={{ py: 2 }}>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            {" "}
                            District:{" "}
                          </Typography>{" "}
                          <span style={{ marginLeft: 8 }}>
                            {wardData.districtName}
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Division:{" "}
                          </Typography>{" "}
                          <span style={{ marginLeft: 8 }}>
                            {wardData.division}
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Block:{" "}
                          </Typography>{" "}
                          <span style={{ marginLeft: 8 }}>
                            {" "}
                            {wardData.blockName}
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Panchayat{getPanchayatTextAppend()}:{" "}
                          </Typography>{" "}
                          <span style={{ marginLeft: 8 }}>
                            {wardData.panchayatName}
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Ward:{" "}
                          </Typography>{" "}
                          <span style={{ marginLeft: 8 }}>
                            {" "}
                            {wardData.name}
                          </span>
                        </Grid>
                        <ShowWhen
                          when={getDeptName() === "UDHD"}
                          component={
                            <Grid
                              item
                              md={12}
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Typography
                                color="textSecondary"
                                variant="subtitle2"
                              >
                                Installation Location:{" "}
                              </Typography>{" "}
                              <span style={{ marginLeft: 8 }}>
                                {" "}
                                {wardData.installation_location || "—"}
                              </span>
                            </Grid>
                          }
                        />
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Survey Date:{" "}
                          </Typography>{" "}
                          <span style={{ marginLeft: 8 }}>
                            {" "}
                            {wardData?.survey_data?._ts
                              ? surveyDate({ date: wardData?.survey_data?._ts })
                              : "—"}
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={6} borderLeft={"1px solid #CCC"}>
                    <Typography color={priColor} variant="subtitle2">
                      Device Details
                    </Typography>
                    <Box sx={{ py: 2 }}>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Device Barcode:
                          </Typography>
                          <span style={{ marginLeft: 8 }}>
                            {/* {wardData.regData ? wardData.regData.barcode : ""} */}
                            <input
                              onChange={(e) => setDeviceBarcode(e.target.value)}
                              value={deviceBarcode}
                              style={{ width: 150 }}
                            />
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Date of Installation:
                          </Typography>
                          <ShowWhen
                            when={wardData?.installation_data?.date}
                            component={<>{getInstallationDate2(wardData)}</>}
                            otherwise={
                              <input
                                type="date"
                                value={dateOfInstallation}
                                onChange={(e) =>
                                  setDateOfInstallation(e.target.value)
                                }
                                style={{ width: 150 }}
                              />
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            SIM Number:
                          </Typography>
                          <span style={{ marginLeft: 8 }}>
                            {wardData
                              ? wardData?.regData?.simNumber
                                ? wardData.regData?.simNumber
                                : devRegData
                                ? devRegData?.simNumber || devRegData?.source
                                : "Not Available"
                              : "-"}
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Carrier Name:
                          </Typography>
                          <span style={{ marginLeft: 8 }}>
                            {wardData
                              ? wardData?.regData?.carrierName
                                ? wardData.regData?.carrierName
                                : devRegData
                                ? devRegData?.carrierName || devRegData?.source
                                : "Not Available"
                              : "-"}
                          </span>
                        </Grid>
                        <Grid
                          md={12}
                          item
                          display="flex"
                          flexDirection={"row-reverse"}
                          pt={1}
                        >
                          {enableLinkingDevice && !enableLinkingDevice && (
                            <Tooltip title="Reason for cannot link : Already assigned to ward or Sim number, Carrier name missing">
                              <InfoSharp fontSize="small" />
                            </Tooltip>
                          )}
                          {linkingStatus !== null ? (
                            <>{linkingStatus}</>
                          ) : (
                            <>
                              <Button
                                variant="outlined"
                                size="small"
                                disabled={!enableLinkingDevice}
                                onClick={linkDeviceToWardHandler}
                              >
                                Link Device to this ward
                              </Button>
                              &nbsp;
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={validationDeviceDetails}
                              >
                                Validate
                              </Button>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={6} borderTop={"1px solid #CCC"}>
                    <Typography color={priColor} variant="subtitle2">
                      TE Details
                    </Typography>
                    <Box sx={{ py: 2 }}>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          pr={2}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Name:
                          </Typography>
                          <span style={{ marginLeft: 8 }}>
                            {wardData.installation_user_mapping
                              ? wardData.installation_user_mapping.name
                              : ""}
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          pr={2}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Mobile Number:
                          </Typography>
                          <span style={{ marginLeft: 8 }}>
                            {wardData.installation_user_mapping
                              ? wardData.installation_user_mapping.mobile_number
                              : "Not Mapped"}
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    borderLeft={"1px solid #CCC"}
                    borderTop="1px solid #CCC"
                  ></Grid>
                  <Grid item md={12} style={{ borderTop: "1px solid #CCC" }}>
                    <Typography color={priColor} variant="subtitle2">
                      Other Details
                    </Typography>
                    <Formik
                      initialValues={{
                        electricity_meter_status:
                          `${wardData?.survey_data?.electricity_meter?.status}` ||
                          false,
                        starter_status:
                          `${wardData?.survey_data?.starter?.status}` || false,
                        anurakshak_name:
                          wardData?.survey_data?.anurakshak?.name || "",
                        anurakshak_phone:
                          Number(
                            wardData?.survey_data?.anurakshak?.mobile_number
                          ) || "",
                        isSiteOpen:
                          `${wardData?.survey_data?.isSiteOpen}` || false,
                      }}
                      enableReinitialize
                      onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting }
                      ) => {
                        let data = {
                          anurakshak: {
                            status: true,
                            name: values.anurakshak_name,
                            mobile_number: values.anurakshak_phone,
                            photo:
                              wardData?.survey_data?.anurakshak?.photo || "",
                          },
                          siteDetails: {
                            photo:
                              wardData?.survey_data?.siteDetails?.photo || "",
                          },
                          electricity_meter: {
                            status: values.electricity_meter_status == "true",
                            photo:
                              wardData?.survey_data?.electricity_meter?.photo ||
                              "",
                          },
                          starter: {
                            status: values.starter_status == "true",
                            photo: wardData?.survey_data?.starter?.photo || "",
                          },
                          isSiteOpen: values.isSiteOpen == "true",
                          status: "pending",
                        };
                        dispatch(saveSurveyDetails(data, wardData.id));
                      }}
                      validationSchema={Yup.object().shape({
                        electricity_meter_status: Yup.string().required(),
                        starter_status: Yup.string().required(),
                        isSiteOpen: Yup.string().required(),
                        anurakshak_name: Yup.string().required(),
                        anurakshak_phone: Yup.string()
                          .required()
                          .matches(/^[0-9]+$/, "Must be only digits")
                          .min(10, "Must be exactly 10 digits")
                          .max(10, "Must be exactly 10 digits"),
                      })}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Box sx={{ py: 2 }}>
                            <Grid container>
                              <Grid
                                item
                                md={12}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Anurakshak Name:
                                </Typography>
                                <span style={{ marginLeft: 8 }}>
                                  <input
                                    type="text"
                                    name={"anurakshak_name"}
                                    value={values.anurakshak_name}
                                    onChange={handleChange}
                                    style={{
                                      width: 150,
                                      borderColor: Boolean(
                                        touched.anurakshak_name &&
                                          errors.anurakshak_name
                                      )
                                        ? "red"
                                        : "",
                                    }}
                                  />
                                </span>
                                <span style={{ marginLeft: 10 }}>
                                  {touched.anurakshak_name &&
                                    errors.anurakshak_name}
                                </span>
                              </Grid>
                              <Grid
                                item
                                md={12}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle2"
                                >
                                  Anurakshak Mobile Number:
                                </Typography>
                                <span style={{ marginLeft: 8 }}>
                                  <input
                                    type="text"
                                    name={"anurakshak_phone"}
                                    value={values.anurakshak_phone}
                                    onChange={handleChange}
                                    style={{
                                      width: 150,
                                      borderColor: Boolean(
                                        touched.anurakshak_phone &&
                                          errors.anurakshak_phone
                                      )
                                        ? "red"
                                        : "",
                                    }}
                                  />
                                </span>
                                <span style={{ marginLeft: 10 }}>
                                  {touched.anurakshak_phone &&
                                    errors.anurakshak_phone}
                                </span>
                              </Grid>

                              <OtherDetailsItem
                                value={values.isSiteOpen}
                                onBlur={handleBlur}
                                name={"isSiteOpen"}
                                onChange={handleChange}
                                title="Site Open:"
                                options={{ true: "Yes", false: "No" }}
                                error={Boolean(
                                  touched.isSiteOpen && errors.isSiteOpen
                                )}
                                item={"isSiteOpen"}
                              />
                              <OtherDetailsItem
                                value={values.electricity_meter_status}
                                onBlur={handleBlur}
                                name={"electricity_meter_status"}
                                onChange={handleChange}
                                title="Electricity Meter:"
                                options={{
                                  true: "Working",
                                  false: "Not Working",
                                }}
                                error={Boolean(
                                  touched.electricity_meter_status &&
                                    errors.electricity_meter_status
                                )}
                                item={"electricity_meter"}
                              />

                              <OtherDetailsItem
                                value={values.starter_status}
                                onBlur={handleBlur}
                                name={"starter_status"}
                                onChange={handleChange}
                                title="Starter Status:"
                                options={{
                                  true: "Working",
                                  false: "Not Working",
                                }}
                                error={Boolean(
                                  touched.starter_status &&
                                    errors.starter_status
                                )}
                                item={"starter"}
                              />
                            </Grid>
                            <Grid
                              md={12}
                              item
                              display="flex"
                              flexDirection={"row-reverse"}
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                type="submit"
                              >
                                {wardUpdateStatus !== null ? "Update" : "Save"}
                              </Button>{" "}
                              &nbsp;&nbsp;
                              {wardUpdateStatus}&nbsp;&nbsp;
                            </Grid>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  display="flex"
                  flexDirection={"row"}
                  justifyContent="center"
                  style={{ borderTop: "1px solid #CCC" }}
                >
                  <Typography color={priColor} variant="h6">
                    Testing Details
                  </Typography>
                  <br />
                </Grid>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: 0 }}
                  onClick={runTest}
                  disabled={isDisabled(
                    wardData?.regData?.deviceId || devRegData?.deviceId
                  )}
                >
                  Run Test
                </Button>
                <Grid container spacing={1}>
                  <Grid item md={8}>
                    <Box sx={{ py: 2 }}>
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Device Barcode:
                          </Typography>
                          <span style={{ marginLeft: 8 }}>
                            {_.has(wardData, "regData.barcode")
                              ? wardData.regData.barcode
                              : ""}
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Power On Test:
                          </Typography>
                          <TestItem
                            data={
                              _.has(testStatus, "power_status")
                                ? testStatus.power_status
                                : "Not Found"
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Network Status:
                          </Typography>{" "}
                          <span style={{ marginLeft: 8 }}>
                            {" "}
                            {_.has(testStatus, "newtwork_status")
                              ? `${testStatus.newtwork_status} (last received)`
                              : "-"}
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Device Heartbeat Details:
                          </Typography>{" "}
                          <span style={{ marginLeft: 1 }}>
                            {_.has(testStatus, "device_hb.date")
                              ? `${testStatus.device_hb.date} ${testStatus.device_hb.time}`
                              : "-"}
                          </span>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color="textSecondary" variant="subtitle2">
                            Motor On Test:
                          </Typography>{" "}
                          <TestItem
                            data={
                              _.has(testStatus, "power_status")
                                ? testStatus.motor_on_status
                                : "Not Found"
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          marginTop={3}
                          marginBottom={3}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Typography color={priColor} variant="h5">
                            Test result:
                          </Typography>
                          <Typography
                            color={
                              _.has(testStatus, "overall")
                                ? testStatus.overall == "pass"
                                  ? "green"
                                  : "red"
                                : ""
                            }
                            variant="h5"
                            marginLeft={3}
                          >
                            {_.has(testStatus, "overall")
                              ? testStatus.overall.toUpperCase()
                              : "Not Found"}
                          </Typography>
                        </Grid>
                        {testStatus && (
                          <Grid item>
                            {" "}
                            Looks good?{" "}
                            <Button
                              variant="outlined"
                              size="small"
                              disabled={
                                !dateOfInstallation ||
                                !devRegData ||
                                isDisabled(
                                  wardData?.regData?.deviceId ||
                                    devRegData?.deviceId
                                )
                              }
                              onClick={() => {
                                if (dateOfInstallation) {
                                  const datetime = getDateTime(
                                    new Date(dateOfInstallation).getTime() /
                                      1000
                                  );
                                  const ts = new Date().getTime() / 1000;
                                  dispatch(
                                    linkDeviceUpdateWardStatus(
                                      {
                                        installation_data: {
                                          status: "completed",
                                          date: `${datetime.date} ${datetime.time}`,
                                          _ts: ts,
                                        },
                                        installation_ts: ts,
                                      },
                                      wardData.id,
                                      {
                                        ...devRegData,
                                        installation_testing_status: {
                                          power_status:
                                            testStatus["power_status"],
                                          motor_on_status:
                                            testStatus["motor_on_status"],
                                          overall: testStatus["overall"],
                                          date: `${datetime.date} ${datetime.time}`,
                                        },
                                      }
                                    )
                                  );
                                }
                              }}
                            >
                              Update Status
                            </Button>{" "}
                          </Grid>
                        )}

                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {/* <Typography
                            color="textSecondary"
                            variant="subtitle2"
                          >
                            Status:
                          </Typography>{" "}
                          <span
                            style={{
                              marginLeft: 8,
                              fontWeight: "bold",
                              color: InstallationStatusColor(
                                getInstallationStatus(wardData)
                              ),
                            }}
                          >
                            {getInstallationStatus(wardData)}
                          </span>
                          {getInstallationStatus(wardData) == "Approved" && (
                            <>
                              {wardData.verification &&
                                wardData.verification.report_url ? (
                                <a
                                  href={wardData.verification.report_url}
                                  target="_blank"
                                  style={{ marginLeft: 20 }}
                                >
                                  View Report
                                </a>
                              ) : (
                                ""
                              )}
                            </> */}
                          {/* )} */}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={0.1} />
              <Grid
                item
                md={5}
                sx={{
                  border: "1px solid #CCC",
                  backgroundColor: "#FFF",
                  borderRadius: 1,
                  padding: 2,
                }}
              >
                <Grid
                  item
                  md={12}
                  display="flex"
                  flexDirection={"row"}
                  marginBottom={2}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    style={{ color: priColor }}
                  >
                    Site Survey Photos
                  </Typography>
                </Grid>
                <Grid container spacing={2} direction="column">
                  {Object.keys(siteImageObject).map((eTitle) => {
                    return (
                      <Grid item key={eTitle}>
                        <Button
                          variant="contained"
                          component="label"
                          // disabled={!selectedDeviceId  || selectedFile}
                        >
                          Select {eTitle} photo
                          {/* {selectedFile ? "You have Selected one file" : "Select Images"} */}
                          <input
                            type="file"
                            onChange={(e) => onImageChange(e, eTitle)}
                            hidden
                            accept="image/png, image/jpeg"
                          />
                        </Button>
                        {siteImageObject[eTitle] ? (
                          <CheckBoxRounded
                            style={{ color: "green", marginLeft: 10 }}
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                    );
                  })}
                  <Grid item>
                    <Typography>
                      Select all the images and click Save Images
                    </Typography>
                    <Button variant="outlined" onClick={saveImages}>
                      Save images
                    </Button>
                  </Grid>
                </Grid>

                <Grid item sx={{ mt: 4 }}>
                  <ShowWhen
                    when={surveyImages?.length > 0}
                    component={
                      <ImagesWrapper
                        images={surveyImages}
                        title={"Site Survey Photos"}
                      />
                    }
                  />
                  <ShowWhen
                    when={deviceImages?.length > 0}
                    component={
                      <ImagesWrapper
                        images={deviceImages}
                        title={"Device Installation & Testing"}
                        mt={3}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>

        {currentUrl && (
          <DialogUI
            handleClose={() => setOpen(false)}
            open={open}
            image={currentUrl}
          />
        )}
        <ShowWhen
          when={
            loading ||
            saveSurveyDetailsData?.status === "loading" ||
            barCodeAssignedToWard?.status === "loading" ||
            linkDeviceToWard?.status === "loading" ||
            allDeviceData?.status === "loading" ||
            manualLinkingUpdateWardStatus?.status === "loading"
          }
          component={<Loader />}
        />
      </Box>
    </>
  );
}

export default InstallationReportDeviceInfoManualEntry;
