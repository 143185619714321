export const AZURE_STORAGE_CONN_STRING =
  "DefaultEndpointsProtocol=https;AccountName=rollzproddocuments;AccountKey=DHPhnyTPM0uLQXrF5Cn0TmF4fJXPOGaot8ai9qSv7I32hvwu5OcsK2Vml7k8P/CfQekbY00QGxBb+AStTgz0fA==;EndpointSuffix=core.windows.net";
export const AZURE_STORAGE_KEY =
  "DHPhnyTPM0uLQXrF5Cn0TmF4fJXPOGaot8ai9qSv7I32hvwu5OcsK2Vml7k8P/CfQekbY00QGxBb+AStTgz0fA==";
export const STORAGE_ACCOUNT = "rollzproddocuments";
export const REPORT_CONTAINER = "report";

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const title = {
  label1: "Bihar Government",
  label2: "Har Ghar Nal Jal Yojna",
  subTitle: {
    phed: "Public Health Engineering Department",
    udhd: "Urban Development and Housing Department",
    prd: "Panchayati Raj Department",
  },
};

export const colors = {
  textGreen: "#10b759",
  textYellow: "#fbbc06",
};

export const priColor = "#727cf5";

export const operationalInitialValue = {
  nonFunctional: 0,
  functional: 0,
  functionalWLS: 0,
  functionalPWS: 0,
  nonFunctionalWLS: 0,
  nonFunctionalPWS: 0,
};

export const ISSUE_TYPES = {
  "SCHEME NON-FUNCTIONAL": [
    "CT Removed",
    "CT Not Working",
    "Motor Burn",
    "Starter Burn",
    "No Starter/ Direct Motor Operation",
    "Motor Not Running Due To Pipe Leakege",
    "Motor Not Running Due To Operator's Non Payment",
    "Other",
  ],
  "DEVICE NON-FUNCTIONAL": [
    "Power Cable Removed/Burn",
    "All Device Setup Burn",
    "Electric Meter Burn",
    "No Electricity Supply/Fault At Scheme",
    "Electricity Prepaid Meter Not Rechrge/Fault",
    "Power Not Showing In Device",
    "All Device Setup Removed By Operator/Contractor",
    "Other",
  ],
};

export const userIdentityTypes = [
  { label: "PHED User", value: "PHED_USER" },
  { label: "NIC User", value: "NIC_USER" },
  { label: "Geron/Rollz Employee", value: "GERON_" },
  { label: "Other", value: "OTHER" },
];

export const geronEmpTypes = [
  { label: "Firmware operator", value: "GERON_FIRMWARE_OPERATOR" },
  { label: "Data Operator", value: "GERON_DATA_OPERATOT" },
  { label: "GIS Operator", value: "GERON_GIS_OPERATOR" },
  { label: "Mobile App User", value: "MOBILE_APP_USER" },
  { label: "Caller", value: "GERON_CALLING" },
];

export const mobileAppUserType = [
  { label: "Head /Zonal Office", value: "HO" },
  { label: "Division Supervisor", value: "DS" },
  { label: "Technical Executive", value: "TE" },
];

export const surveyDataKeys = [
  "survey_data",
  "installation_data",
  "verification",
  "isInstalled",
  "installation_ts",
  "deviceImage",
  "fw_device_id",
  "doi",
  "regData",
  "id",
];

//Temp solution
export const NON_FUNCTIONAL_REPORTS = [
  {
    id: "1",
    name: "Muzaffarpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Muzaffarpur.pdf",
  },
  {
    id: "2",
    name: "Sitamarhi",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Sitamarhi.pdf",
  },
  {
    id: "3",
    name: "Sheohar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Sheohar.pdf",
  },
  {
    id: "4",
    name: "Saran",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Saran.pdf",
  },
  {
    id: "5",
    name: "GopalGanj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Gopalganj.pdf",
  },
  {
    id: "6",
    name: "Siwan",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Siwan.pdf",
  },
  {
    id: "7",
    name: "Aurangabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Aurangabad.pdf",
  },
  {
    id: "8",
    name: "Buxar",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Buxar.pdf",
  },
  {
    id: "9",
    name: "Jehanabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Jehanabad.pdf",
  },
  {
    id: "10",
    name: "Sheikhpura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Sheikhpura.pdf",
  },
  {
    id: "11",
    name: "LAKHISARAI",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Lakhisarai.pdf",
  },
  {
    id: "12",
    name: "Jamui",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Jamui.pdf",
  },
  {
    id: "13",
    name: "KHAGARIA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Khagaria.pdf",
  },
  {
    id: "14",
    name: "Banka",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Banka.pdf",
  },
  {
    id: "15",
    name: "MUNGER",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Munger.pdf",
  },
  {
    id: "16",
    name: "SUPAUL",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Supaul.pdf",
  },
  {
    id: "17",
    name: "Saharsa",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Saharsa.pdf",
  },
  {
    id: "18",
    name: "MADHEPURA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Madhepura.pdf",
  },
  {
    id: "19",
    name: "ARARIA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Araria.pdf",
  },
  {
    id: "20",
    name: "Purnia",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Purnia.pdf",
  },
  {
    id: "21",
    name: "KISHANGANJ",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Kishanganj.pdf",
  },
  {
    id: "22",
    name: "KATIHAR",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Katihar.pdf",
  },
  {
    id: "25",
    name: "Arwal",
    report: "https://rollzproddocuments.blob.core.windows.net/report/Arwal.pdf",
  },
  //? UDHD DEPARTMENT DIVISIONS
  {
    id: "201",
    name: "aurangabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Aurangabad.pdf",
  },
  {
    id: "202",
    name: "begusarai",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Begusarai.pdf",
  },
  {
    id: "203",
    name: "Chapra",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Chapra.pdf",
  },
  {
    id: "204",
    name: "Gaya",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Gaya.pdf",
  },
  {
    id: "205",
    name: "Jehanabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Jehanabad.pdf",
  },
  {
    id: "206",
    name: "Katihar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Katihar.pdf",
  },
  {
    id: "207",
    name: "Lakhisarai",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Lakhisarai.pdf",
  },
  {
    id: "208",
    name: "Madhepura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Madhepura.pdf",
  },
  {
    id: "209",
    name: "Munger",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Munger.pdf",
  },
  {
    id: "210",
    name: "Muzaffarpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Muzaffarpur.pdf",
  },
  {
    id: "211",
    name: "Nalanda",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nalanda.pdf",
  },
  {
    id: "212",
    name: "PATNA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Patna.pdf",
  },
  {
    id: "213",
    name: "PATNA EAST",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Patnaeast.pdf",
  },
  {
    id: "214",
    name: "PATNA WEST",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Patnawest.pdf",
  },
  {
    id: "215",
    name: "PURNEA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Purnea.pdf",
  },
  {
    id: "216",
    name: "Sitamarhi",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Sitamarhi.pdf",
  },
  {
    id: "217",
    name: "Siwan",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Siwan.pdf",
  },
  {
    id: "218",
    name: "West Champaran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Westchamparan.pdf",
  },
  {
    id: "219",
    name: "Sheikhpura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Sheikhpura.pdf",
  },
  {
    id: "220",
    name: "Araria",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Araria.pdf",
  },
  {
    id: "221",
    name: "Arwal",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Arwal.pdf",
  },
  {
    id: "222",
    name: "Banka",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Banka.pdf",
  },
  {
    id: "223",
    name: "Bhagalpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Bhagalpur.pdf",
  },
  {
    id: "224",
    name: "Bhojpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Bhojpur.pdf",
  },
  {
    id: "225",
    name: "Biharsharif",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Biharsharif.pdf",
  },
  {
    id: "226",
    name: "Buxar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Buxar.pdf",
  },
  {
    id: "227",
    name: "East Champaran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Eastchamparan.pdf",
  },
  {
    id: "228",
    name: "Gopalganj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Gopalganj.pdf",
  },
  {
    id: "229",
    name: "Jamui",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Jamui.pdf",
  },
  {
    id: "230",
    name: "Khagaria",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Khagaria.pdf",
  },
  {
    id: "231",
    name: "Kishanganj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Kishanganj.pdf",
  },
  {
    id: "232",
    name: "Nawada",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nawada.pdf",
  },
  {
    id: "233",
    name: "Sheohar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Sheohar.pdf",
  },
  {
    id: "234",
    name: "Supaul",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Supaul.pdf",
  },
  {
    id: "235",
    name: "Vaishali",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Vaishali.pdf",
  },
  {
    id: "236",
    name: "Samastipur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Samastipur.pdf",
  },
  //? PRD DEPT
  {
    id: "301",
    name: "BETTIAH",
    report:
      "https://rollzproddocuments.blob.core.windows.net/prdreports/Bettiah.pdf",
  },
  {
    id: "302",
    name: "SITAMARHI",
    report:
      "https://rollzproddocuments.blob.core.windows.net/prdreports/Sitamarhi.pdf",
  },
];

export const NON_REPORTING_REPORTS = [
  {
    id: "1",
    name: "Muzaffarpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Muzaffarpur.pdf",
  },
  {
    id: "2",
    name: "Sitamarhi",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Sitamarhi.pdf",
  },
  {
    id: "3",
    name: "Sheohar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Sheohar.pdf",
  },
  {
    id: "4",
    name: "Saran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Saran.pdf",
  },
  {
    id: "5",
    name: "GopalGanj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Gopalganj.pdf",
  },
  {
    id: "6",
    name: "Siwan",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Siwan.pdf",
  },
  {
    id: "7",
    name: "Aurangabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Aurangabad.pdf",
  },
  {
    id: "8",
    name: "Buxar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Buxar.pdf",
  },
  {
    id: "9",
    name: "Jehanabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Jehanabad.pdf",
  },
  {
    id: "10",
    name: "Sheikhpura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Sheikhpura.pdf",
  },
  {
    id: "11",
    name: "LAKHISARAI",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Lakhisarai.pdf",
  },
  {
    id: "12",
    name: "Jamui",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Jamui.pdf",
  },
  {
    id: "13",
    name: "KHAGARIA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Khagaria.pdf",
  },
  {
    id: "14",
    name: "Banka",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Banka.pdf",
  },
  {
    id: "15",
    name: "MUNGER",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Munger.pdf",
  },
  {
    id: "16",
    name: "SUPAUL",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Supaul.pdf",
  },
  {
    id: "17",
    name: "Saharsa",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Saharsa.pdf",
  },
  {
    id: "18",
    name: "MADHEPURA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Madhepura.pdf",
  },
  {
    id: "19",
    name: "ARARIA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Araria.pdf",
  },
  {
    id: "20",
    name: "Purnia",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Purnia.pdf",
  },
  {
    id: "21",
    name: "KISHANGANJ",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Kishanganj.pdf",
  },
  {
    id: "22",
    name: "KATIHAR",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Katihar.pdf",
  },
  {
    id: "25",
    name: "Arwal",
    report:
      "https://rollzproddocuments.blob.core.windows.net/report/Nonreporting_Arwal.pdf",
  },
  //? UDHD DEPARTMENT DIVISIONS
  {
    id: "201",
    name: "aurangabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Aurangabad.pdf",
  },
  {
    id: "202",
    name: "begusarai",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Begusarai.pdf",
  },
  {
    id: "203",
    name: "Chapra",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Chapra.pdf",
  },
  {
    id: "204",
    name: "Gaya",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Gaya.pdf",
  },
  {
    id: "205",
    name: "Jehanabad",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Jehanabad.pdf",
  },
  {
    id: "206",
    name: "Katihar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Katihar.pdf",
  },
  {
    id: "207",
    name: "Lakhisarai",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Lakhisarai.pdf",
  },
  {
    id: "208",
    name: "Madhepura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Madhepura.pdf",
  },
  {
    id: "209",
    name: "Munger",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Munger.pdf",
  },
  {
    id: "210",
    name: "Muzaffarpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Muzaffarpur.pdf",
  },
  {
    id: "211",
    name: "Nalanda",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Nalanda.pdf",
  },
  {
    id: "212",
    name: "PATNA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Patna.pdf",
  },
  {
    id: "213",
    name: "PATNA EAST",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Patnaeast.pdf",
  },
  {
    id: "214",
    name: "PATNA WEST",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Patnawest.pdf",
  },
  {
    id: "215",
    name: "PURNEA",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Purnea.pdf",
  },
  {
    id: "216",
    name: "Sitamarhi",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Sitamarhi.pdf",
  },
  {
    id: "217",
    name: "Siwan",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Siwan.pdf",
  },
  {
    id: "218",
    name: "West Champaran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Westchamparan.pdf",
  },
  {
    id: "219",
    name: "Sheikhpura",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Sheikhpura.pdf",
  },
  {
    id: "220",
    name: "Araria",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Araria.pdf",
  },
  {
    id: "221",
    name: "Arwal",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Arwal.pdf",
  },
  {
    id: "222",
    name: "Banka",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Banka.pdf",
  },
  {
    id: "223",
    name: "Bhagalpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Bhagalpur.pdf",
  },
  {
    id: "224",
    name: "Bhojpur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Bhojpur.pdf",
  },
  {
    id: "225",
    name: "Biharsharif",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Biharsharif.pdf",
  },
  {
    id: "226",
    name: "Buxar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Buxar.pdf",
  },
  {
    id: "227",
    name: "East Champaran",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Eastchamparan.pdf",
  },
  {
    id: "228",
    name: "Gopalganj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Gopalganj.pdf",
  },
  {
    id: "229",
    name: "Jamui",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Jamui.pdf",
  },
  {
    id: "230",
    name: "Khagaria",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Khagaria.pdf",
  },
  {
    id: "231",
    name: "Kishanganj",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Kishanganj.pdf",
  },
  {
    id: "232",
    name: "Nawada",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Nawada.pdf",
  },
  {
    id: "233",
    name: "Sheohar",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Sheohar.pdf",
  },
  {
    id: "234",
    name: "Supaul",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Supaul.pdf",
  },
  {
    id: "235",
    name: "Vaishali",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Vaishali.pdf",
  },
  {
    id: "236",
    name: "Samastipur",
    report:
      "https://rollzproddocuments.blob.core.windows.net/udhdreports/Nonreporting_Samastipur.pdf",
  },
  //? PRD DEPT
  {
    id: "301",
    name: "BETTIAH",
    report:
      "https://rollzproddocuments.blob.core.windows.net/prdreports/Nonreporting_Bettiah.pdf",
  },
  {
    id: "302",
    name: "SITAMARHI",
    report:
      "https://rollzproddocuments.blob.core.windows.net/prdreports/Nonreporting_Sitamarhi.pdf",
  },
];
