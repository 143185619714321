import CopyToClipboard from "react-copy-to-clipboard";
import { stringShortner } from "../../utils/helper";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const CopyTextToClipBoard = ({ text }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      toast("copied", { duration: 1000 });
    }
  }, [copied]);
  return (
    <CopyToClipboard text={text} className="prevent-select">
      <Button
        onDoubleClick={() => setCopied(!copied)}
        variant="text"
        title="double click to copy"
        sx={{ color: "#6b778c" }}
      >
        {stringShortner(text, 9)}
      </Button>
    </CopyToClipboard>
  );
};

export default CopyTextToClipBoard;
