import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const CopyTextToClipBoard2 = ({ text }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      toast("copied", { duration: 1000 });
    }
  }, [copied]);
  return (
    <CopyToClipboard
      onDoubleClick={() => setCopied(!copied)}
      title={text || "double click to copy"}
      text={text}
      className="prevent-select"
      style={{ cursor: "pointer"}}
    >
      <Button variant="text" sx={{ color: "#6b778c" }}>{text}</Button>
    </CopyToClipboard>
  );
};

export default CopyTextToClipBoard2;
