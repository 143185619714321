import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
    Box,
    Grid,
    Typography,
    Container,
    Breadcrumbs,
    Link,
    Button,
    TextField,
    MenuItem,
} from "@material-ui/core";
import ChevronRightIcon from "../../icons/ChevronRight";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { LocationFilter } from "../../components/dashboard/shemes";
import { useSelector } from "react-redux";
import { resetSchemes, setFilters } from "../../slices/siteInfo";
import { useDispatch } from "react-redux";
import { historyDate } from "../../utils/helper";
import { getNonFunctionalDevices } from "../../slices/maintenance";
import { Loader } from "../../components/Loader/Loader";
import MaintenanceSchemeList from "../../components/dashboard/maintenance/MaintenanceSchemeList";

const range = [
    { id: 0, value: "" },
    { id: 3, value: "Past 3 Days" },
    { id: 7, value: "Past 7 Days" },
    { id: 15, value: "Past 15 Days" },
    { id: 30, value: "Past 30 Days" },
];

const Maintenance = () => {
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();
    const {nonFunctionalDevices: nonFunctionalDevices} = useSelector((state) => state.maintenance);
    const { district, schemes, lastSearchFilter } = useSelector((state) => state.siteinfo);
    const [schemeLocation, setSchemeLocation] = useState({});
    const [allSchemes, setAllSchemes] = useState([]);
    const [nonFuncDeviceList, setNonFuncDeviceList] = useState([])
    const [selectedRange, setSelectedRange] = useState(3);
    const onChangeLocation = (data) => {
        const keys = Object.keys(data);
        let allReset = true;
        for (let i = 0; i < keys.length; i++) {
            allReset = allReset && !data[keys[i]];
        }

        if (allReset) {
            setAllSchemes([]);
            dispatch(resetSchemes());
        }
        setSchemeLocation(data);
    };

    useEffect(() => {
      if(nonFunctionalDevices["status"] == "SUCCESS"){
        setNonFuncDeviceList(nonFunctionalDevices["data"])
      }
    }, [nonFunctionalDevices])

    useEffect(() => {
        if (
            schemeLocation.district ||
            schemeLocation.block ||
            schemeLocation.panchayat ||
            schemeLocation.ward
        ) {
            let d = {};
            if (schemeLocation.district) {
                d["district"] = schemeLocation.district;
            }
            if (schemeLocation.division) {
                d["division"] = schemeLocation.division;
            }
            if (schemeLocation.block) {
                d["block"] = schemeLocation.block;
            }
            if (schemeLocation.panchayat) {
                d["panchayat"] = schemeLocation.panchayat;
            }
            if (schemeLocation.ward) {
                d["ward"] = schemeLocation.ward;
            }
            dispatch(setFilters(schemeLocation));
            dispatch(getNonFunctionalDevices(d, historyDate(selectedRange)));
        }
    }, [schemeLocation, selectedRange]);

    return (
        <>
            <Helmet>
                <title>Maintenance | Har Ghar Nal Jal Yojna</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    minHeight: "100%",
                    py: 2,
                }}
            >
                <Container maxWidth={"xl"}>
                    <Grid container justifyContent="space-between" spacing={3}>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                Maintenance
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/overview"
                                    variant="subtitle2"
                                >
                                    Home
                                </Link>
                                <Typography color="textSecondary" variant="subtitle2">
                                    Maintenance
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ py: 3 }}>
                        <LocationFilter
                            enableAll={true}
                            onChange={onChangeLocation}
                            initialValues={lastSearchFilter}
                            schemes={allSchemes}
                            downloadExcel={false}
                        />
                    </Box>
                    <Box sx={{ py: 3 }}>
                        <Grid item md={2} sm={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Select Range"
                                name="range"
                                onChange={(e) => {
                                    setSelectedRange(e.target.value);
                                }}
                                select
                                value={selectedRange}
                                variant="outlined"
                            >
                                {range.map((e) => (
                                    <MenuItem key={e.id} value={e.id}>
                                        {e.value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Box>
                    <Box>
                      <MaintenanceSchemeList schemes = {nonFuncDeviceList} range={selectedRange}/>
                    </Box>
                </Container>
                {nonFunctionalDevices["status"] == "loading" && <Loader />}
            </Box>
        </>
    );
};

export default Maintenance;
