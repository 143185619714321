import { Box, Button, Grid, TextField } from "@material-ui/core";
import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { Loader } from "../../../../components/Loader/Loader";
import { priColor } from "../../../../constants";
import { approveWard, rejectWardWithRemark,  } from "../../../../slices/installation"
import { useDispatch, useSelector } from "../../../../store";

function SubmitControl(props) {
  const { approve: approveApiResponse, reject: rejectApiResponse } = useSelector(state => state.installation)
  const [rejectRemark, setRejectRemark] = useState("");
  const [rejectRemarkError, setRejectRemarkError] = useState(false);
  const [rejectWithRemark, setRejectWithRemark] = useState(false);
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  // const [loading, setLoading] = useState(false);

  const onApprove = () => {
    dispatch(approveWard(props.wardData))
  };

  const onRejectWithRemark = () => {
    dispatch(rejectWardWithRemark({ ward: props.wardData, remark: rejectRemark }))
  };

  // useEffect(() => {
  //   if (approveApiResponse && approveApiResponse.status == "SUCCESS") {
  //     if (approveApiResponse.data && approveApiResponse.data.filePath) {
  //       var link = document.createElement("a");
  //       link.href = approveApiResponse.data.filePath;
  //       link.target = "_blank";
  //       link.download = approveApiResponse.data.filename;
  //       link.dispatchEvent(new MouseEvent("click"));
  //     }

  //     // alert("Ward Approved Successfully");
  //     dispatch(resetInstallationState());
  //     navigate(-1);
  //   }
  // }, [approveApiResponse]);

  // useEffect(() => {
  //   if(rejectApiResponse && rejectApiResponse.status == "SUCCESS"){
  //       alert("Ward Reject with Remark")
  //       dispatch(resetInstallationState())
  //       navigate(-1)
  //   }
  // }, [rejectApiResponse])

  return (
    <Box>
      <Grid container spacing={2} marginTop={4}>
        <Grid item md={4}>
          <Button
            fullWidth
            disabled={rejectWithRemark}
            variant="contained"
            style={{ backgroundColor: rejectWithRemark?"#CCC":"green" }}
            onClick={onApprove}
          >
            Approve and Generate Report
          </Button>
        </Grid>
        <Grid item md={4}>
          <Button
            fullWidth
            variant="contained"
            style={{ backgroundColor: "red" }}
            onClick={() => {
              setRejectWithRemark(true);
            }}
          >
            Reject With Remark
          </Button>
        </Grid>
      </Grid>
      {rejectWithRemark && (
        <Box>
          <Grid container spacing={2} marginTop={2}>
            <Grid item md={8}>
              <TextField
                label={"Remark"}
                value={rejectRemark}
                error={rejectRemark == "" && rejectRemarkError}
                onChange={(e) => setRejectRemark(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container marginTop={1} spacing={2}>
            <Grid item md={4}>
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: priColor }}
                onClick={() => {
                  if (rejectRemark == "") {
                    setRejectRemarkError(true);
                  } else {
                    onRejectWithRemark()
                  }
                }}
              >
                Reassign to TE With Remark
              </Button>
            </Grid>
            <Grid item md={2}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => {
                  setRejectWithRemark(false);
                  setRejectRemark("")
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {approveApiResponse.status==="loading" ? <Loader />:""}
      {rejectApiResponse.status==="loading" ? <Loader />:""}
    </Box>
  );
}

export default SubmitControl;
