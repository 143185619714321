import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props["dupDevices"].map((e) => {
        let cDevData = props["dupDevicesDetails"].filter((e1 => e1.other.deviceid == e["deviceid"]))
        return <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Device Id: {e["deviceid"]} - No of duplicates: {e["count"]}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {cDevData.map((devElement, i) => {
                return <div>
                  <div>
                    Name: {devElement["name"]}
                  </div>
                  <div>
                    District Name: {devElement["districtName"]}
                  </div>
                  <div>
                    Block Name: {devElement["blockName"]}
                  </div>
                  <div>
                    Panchayat Name: {devElement["panchayatName"]}
                  </div>
                  <hr />
                </div>
              })}
              {cDevData.length && <button onClick={() => props.deleteDevice(cDevData.map(cde => cde.id))}>Delete</button> }

            </Typography>
          </AccordionDetails>
        </Accordion>
      })}

    </div>
  );
}
