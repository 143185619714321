import { API } from "../../../../config";
import { filepost, post } from "../../../../slices/service";

function fileUpload(file) {
    return new Promise(async function (resolve, reject) {
        filepost(API.base_url + 'phed/uploadFileAndGetURL', { uifile: file })
            .then(rawData => rawData)
            .then(function (response) {
                response = response || {};

                if (response.data && response.data.fileUrl)
                    resolve(response.data.fileUrl);
                else resolve('URL not found');
            })
            .catch(function (errResponse) {
                reject(`Error ${JSON.stringify(errResponse)}}`);
            });
    });
}

function manualUpdateWardImages(wardid, images) {
    return new Promise(async function (resolve, reject) {
        post(API.base_url + 'phed/manualUpdateWardImages', { images, wardid})
            .then(rawData => rawData)
            .then(function (response) {
                response = response || {};

                if (response.data)
                    resolve(response.data);
                else resolve('URL not found');
            })
            .catch(function (errResponse) {
                reject(`Error ${JSON.stringify(errResponse)}}`);
            });
    });
}

export {
    fileUpload,
    manualUpdateWardImages
}