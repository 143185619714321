import { Button, Card } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import {
  getInstallationFieldObj,
  getStatusColor,
  getStatusText,
  historyDate,
} from "../../../../utils/helper";
import CustomNoRowsOverlay from "../../../../components/common/CustomNoRowsOverlay";
import CustomToolbar from "../../../../components/common/CustomToolbar";
import { useNavigate } from "react-router";
import CopyTextToClipBoard from "../../../../components/common/CopyTextToClipBoard";
import CopyTextToClipBoard2 from "../../../../components/common/CopyTextToClipBoard2";

const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});

const NotAssignedList = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      const dr = historyDate(0, "currentDay");
      props.schemes.map((page, i) => {
        if (props.showWardsIds && props.showWardsIds.length) {
          if (!props.showWardsIds.includes(page["id"])) {
            return;
          }
        }

        tableDataTemp.push({
          ...page,
          ...page.other,
          nicId: page.nicdata ? page.nicdata.NICDeviceId : "",
          did: page.other.deviceid,
          assignedOn: page.installation_user_mapping
            ? page.installation_user_mapping.date
            : "",
          assignedTo: page.installation_user_mapping
            ? page.installation_user_mapping.name
            : "",
          action: page,
          id: page.id,
          wardId: page.id,
          slno: i + 1,
          installation_status: getStatusText(page),
          newBarcode: page?.regData?.barcode ? page?.regData?.barcode : "-NA-",
          lastHearBeatDate: page?.regData?.lastHearBeatDate
            ? page?.regData?.lastHearBeatDate
            : "",
          gis_map_status: page.wardGisData?.id ? "Yes" : "No",
        });
      });
    }

    setTableData(tableDataTemp);
  }, [props.schemes]);

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 50,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      headerName: "Ward Id",
      hide: false,
      width: 100,
      sortable: false,
      headerClassName: "scheme-data-table",
      renderCell: (rowItem) => {
        return <CopyTextToClipBoard text={`${rowItem.value}`} />;
      },
    },
    {
      field: "districtName",
      headerName: "District",
      width: 130,
      sortable: false,
    },
    {
      field: "division",
      headerName: "Division",
      width: 130,
      sortable: false,
      disableExport: true,
      hide: true,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: false,
      renderCell: (rowItem) => {
        return <CopyTextToClipBoard2 text={rowItem.value} />;
      },
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: false,
      renderCell: (rowItem) => {
        return <CopyTextToClipBoard2 text={rowItem.value} />;
      },
    },
    {
      field: "name",
      headerName: "Ward",
      width: 130,
      sortable: false,
    },
    {
      field: "subScheme",
      headerName: "Scheme Type",
      width: 130,
      sortable: false,
      disableExport: true,
      hide: true,
    },
    {
      field: "installation_status",
      headerName: "Installation Status",
      width: 180,
      sortable: false,
      valueFormatter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return (
          <span style={{ color: getStatusColor(params.value) }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "newBarcode",
      headerName: "New Barcode",
      width: 170,
      sortable: false,
      hide: false,
    },
    {
      field: "lastHearBeatDate",
      headerName: "Last Heart Beat",
      width: 180,
      sortable: false,
      hide: true,
    },
    {
      field: "gis_map_status",
      headerName: "GIS Map Status",
      width: 170,
      sortable: false,
      disableExport: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        const obj = getInstallationFieldObj(params["formattedValue"]);
        let disabled = true;
        if (
          obj.text === "Completed" ||
          obj.text === "Approval Pending" ||
          obj.text === "Rejected" ||
          obj.text === "Installation Pending"
        ) {
          disabled = false;
        }
        return (
          <Button
            disabled={disabled}
            onClick={() => handleClick(params["formattedValue"])}
          >
            View Details
          </Button>
        );
      },
    },
  ];

  const handleClick = (data) => {
    navigate("device-info", {
      state: {
        wardData: data,
        assignmentType: "New Device Installation",
      },
    });
  };

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          disableSelectionOnClick
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              title: props?.title,
              fileName: props?.fileName,
              locationName: props?.locationName,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{ noRowsLabel: "No Records  Found :)" }}
        />
      </div>
    </Card>
  );
};

export default NotAssignedList;
