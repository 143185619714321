import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get, post } from './service';

const initialState = {
    deviceTelemetry: {
        status: null,
        data: null
    },
    allDeviceTelemetry: {
        status: null,
        data: null
    },
    saveReport: {
        status: null,
        data: null
    },
    deviceTelemetryHistory: {
        status: null,
        data: null
    },
    batchMonitorManual: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'debugging',
    initialState,
    reducers: {
        setDeviceTelemetry(state, action) {
            state.deviceTelemetry = action.payload
        },
        setAllDeviceTelemetry(state, action) {
            state.allDeviceTelemetry = action.payload
        },
        setSaveReport(state, action) {
            state.saveReport = action.payload
        },
        setDeviceTelemetryHistory(state, action) {
            state.deviceTelemetryHistory = action.payload
        },
        setBatchMonitorManual(state, action) {
            state.batchMonitorManual = action.payload
        }
    }
});

export const { reducer } = slice;

export const resetDeviceTelemetry = () => async (dispatch) => {
    dispatch(slice.actions.setDeviceTelemetry(initialState.deviceTelemetry));
}

export const getAllDeviceTelemetry = (deviceId, reqType, fromTime=0) => async (dispatch) => {
    dispatch(slice.actions.setAllDeviceTelemetry({ ...initialState.allDeviceTelemetry, status: "loading" }));

    get(API.base_url + "phed/debugging/allDeviceTelemetry", { deviceId, time_diff: fromTime })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setAllDeviceTelemetry({ ...response, reqType }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setAllDeviceTelemetry({ ...errResponse, status: "failed" }))
        });
};

export const getDeviceTelemetry = (deviceId, reqType, fromTime=0) => async (dispatch) => {
    dispatch(slice.actions.setDeviceTelemetry({ ...initialState.deviceTelemetry, status: "loading" }));

    get(API.base_url + "phed/debugging/deviceTelemetry", { deviceId, time_diff: fromTime })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDeviceTelemetry({ ...response, reqType }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDeviceTelemetry({ ...errResponse, status: "failed" }))
        });
};

export const getDeviceTelemetryHistory = (deviceId, reqType) => async (dispatch) => {
    dispatch(slice.actions.setDeviceTelemetryHistory({ ...initialState.deviceTelemetryHistory, status: "loading" }));

    get(API.base_url + "phed/debugging/deviceTelemetryHistory", { deviceId, type: reqType })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDeviceTelemetryHistory({ ...response, reqType }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDeviceTelemetryHistory({ ...errResponse, status: "failed" }))
        });
};

export const saveReport = (data) => async (dispatch) => {
    dispatch(slice.actions.setSaveReport({ ...initialState.saveReport, status: "loading" }));

    post(API.base_url + "phed/debugging/saveReport", { reportData: data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setSaveReport(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setSaveReport({ ...errResponse, status: "failed" }))
        });
};

export const getBatchMonitorDeviceDetails = (location) => async (dispatch) => {
    dispatch(slice.actions.setBatchMonitorManual({ ...initialState.batchMonitorManual, status: "loading" }));

    post(API.base_url + "phed/debugging/downloadDeviceMonitorData", { location })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setBatchMonitorManual(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setBatchMonitorManual({ ...errResponse, status: "failed" }))
        });
};

export default slice;