import { getDeptName } from "./utils/helper";

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const API = {
  base_url: process.env.NODE_ENV == 'production' ? "https://rollzdashboardapi.azurewebsites.net/api/" : 'http://localhost:5000/api/',
  // static_url: process.env.NODE_ENV == 'production' ? "https://rollzddbapi.azurewebsites.net/static/" : 'http://localhost:5000/static/'
}
const subdomain = getDeptName()
export const BASE_URL = process.env.NODE_ENV == 'production' ? `https://${subdomain.toLowerCase()}.rollzdashboard.com` : `https://${subdomain.toLowerCase()}.rollzdashboard.com:3000`