import { Button, Card, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  formatDate,
  getInstallationFieldObj,
  getStatusColor,
  getStatusText,
} from "../../../../utils/helper";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import DateRangePicker from "../../../../components/common/DateRangePicker";
import CustomNoRowsOverlay from "../../../../components/common/CustomNoRowsOverlay";
import CopyTextToClipBoard from "../../../../components/common/CopyTextToClipBoard";
import CopyTextToClipBoard2 from "../../../../components/common/CopyTextToClipBoard2";

const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});

function CustomToolbar(props) {
  let from = moment(new Date(props?.dateRange?.[0])).format("DD/MM/YYYY");
  let to = moment(new Date(props?.dateRange?.[1])).format("DD/MM/YYYY");

  return (
    <GridToolbarContainer
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography color="textPrimary" variant="h6">
        {props?.isDateRange ? (
          <DateRangePicker
            disabled={props?.loading || false}
            placeholderText={"MM/DD/YYYY-MM/DD/YYYY"}
            dateRange={props?.dateRange}
            setDateRange={props?.setDateRange}
          />
        ) : (
          props.title
        )}
      </Typography>
      <GridToolbarExport
        csvOptions={{
          allColumns: true,
          fileName: `${
            props.fileName ? props.fileName?.toUpperCase() : "DISTRICT"
          }${
            props.locationName ? "_" + props.locationName?.toUpperCase() : ""
          }${props?.dateRange[0] !== null ? "_" + from + "—" : ""}${
            props?.dateRange[1] !== null ? to : ""
          }`,
        }}
      />
    </GridToolbarContainer>
  );
}

const AssignedList = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);

  const getColor = (v) => {
    if (v.includes("Pending")) return "blue";
    else if (v.includes("Failed")) return "red";
    else if (v.includes("Approved")) {
      return "green";
    } else {
      return "black";
    }
  };

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      props.schemes.map((page, i) => {
        if (props.showWardsIds && props.showWardsIds.length) {
          if (!props.showWardsIds.includes(page["id"])) {
            return;
          }
        }

        tableDataTemp.push({
          ...page,
          ...page.other,
          nicId: page.nicdata ? page.nicdata.NICDeviceId : "",
          oldBarcode: page?.oldBarcode,
          wname: page.name,
          assignedOn: page.installation_user_mapping
            ? page.installation_user_mapping.date
            : "",
          assignedTo: page.installation_user_mapping
            ? page.installation_user_mapping.name
            : "",
          installation_status: getStatusText(page),
          completed_date: page.verification ? page.verification.date : "-",
          gis_map_status: page.wardGisData?.id ? "Yes" : "No",
          action: page,
          remark:
            page.installation_data && page.installation_data.remark
              ? page.installation_data.remark
              : "-NA-",
          newBarcode: page?.regData?.barcode ? page?.regData?.barcode : "-NA-",
          anurakshakName: page?.survey_data?.anurakshak?.name,
          isSiteOpen: page?.survey_data?.isSiteOpen ? "Yes" : "No",
          anurakshakMobile: page?.survey_data?.anurakshak?.mobile_number,
          survey_date: formatDate(page?.survey_data?._ts),
          installation_date: formatDate(page?.installation_data?._ts),
          lastHearBeatDate: page?.regData?.lastHearBeatDate
            ? page?.regData?.lastHearBeatDate
            : "",
          slno: i + 1,
          surveyRemark: page?.survey_data?.surveyRemark,
        });
      });
    }

    setTableData(tableDataTemp);
  }, [props.schemes]);

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 50,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      headerName: "Ward Id",
      width: 100,
      sortable: false,
      headerClassName: "scheme-data-table",
      renderCell: (rowItem) => {
        return <CopyTextToClipBoard text={`${rowItem.value}`} />;
      },
    },
    {
      field: "districtName",
      headerName: "District",
      width: 120,
      sortable: false,
    },
    {
      field: "division",
      headerName: "Division",
      width: 130,
      sortable: false,
      disableExport: true,
      hide: true,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: false,
      renderCell: (rowItem) => {
        return <CopyTextToClipBoard2 text={rowItem.value} />;
      },
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: false,
      renderCell: (rowItem) => {
        return <CopyTextToClipBoard2 text={rowItem.value} />;
      },
    },
    {
      field: "wname",
      headerName: "Ward",
      width: 50,
      sortable: false,
    },
    {
      field: "subScheme",
      headerName: "Scheme Type",
      width: 80,
      sortable: false,
      disableExport: true,
      hide: true,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 170,
    },
    {
      field: "assignedOn",
      headerName: "Assigned On",
      width: 130,
      sortable: false,
      disableExport: true,
    },
    {
      field: "installation_status",
      headerName: "Installation Status",
      width: 180,
      sortable: false,
      valueFormatter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return (
          <span style={{ color: getStatusColor(params.value) }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "completed_date",
      headerName: "Completed On",
      width: 170,
      hide: true,
      sortable: true,
      disableExport: true,
    },
    {
      field: "survey_date",
      headerName: "Survey On",
      width: 170,
      hide: true,
      sortable: true,
    },
    {
      field: "surveyRemark",
      headerName: "Survey Remark",
      width: 170,
      hide: true,
      sortable: true,
    },
    {
      field: "installation_date",
      headerName: "Installation On",
      width: 170,
      hide: true,
      sortable: true,
    },
    {
      field: "remark",
      headerName: "Remark",
      width: 130,
      hide: true,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <span style={{ color: getColor(params["formattedValue"]) }}>
            {params["formattedValue"]}
          </span>
        );
      },
    },
    {
      field: "oldBarcode",
      headerName: "Old Barcode",
      width: 170,
      sortable: false,
      hide: true,
    },
    {
      field: "newBarcode",
      headerName: "New Barcode",
      width: 170,
      sortable: false,
      hide: false,
    },
    {
      field: "lastHearBeatDate",
      headerName: "Last Heart Beat",
      width: 180,
      sortable: false,
      hide: true,
    },
    {
      field: "isSiteOpen",
      headerName: "Site Open",
      width: 170,
      sortable: false,
      hide: true,
    },
    {
      field: "gis_map_status",
      headerName: "GIS Map Status",
      width: 170,
      sortable: false,
      disableExport: false,
    },
    {
      field: "anurakshakName",
      headerName: "Anurakshak Name",
      width: 170,
      sortable: false,
      hide: true,
    },
    {
      field: "anurakshakMobile",
      headerName: "Anurakshak Mobile",
      width: 170,
      sortable: false,
      hide: true,
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        const obj = getInstallationFieldObj(params["formattedValue"]);
        let disabled = true;
        if (
          obj.text === "Completed" ||
          obj.text === "Approval Pending" ||
          obj.text === "Rejected" ||
          obj.text === "Installation Pending"
        ) {
          disabled = false;
        }
        return (
          <Button
            disabled={disabled}
            onClick={() => handleClick(params["formattedValue"])}
          >
            View Details
          </Button>
        );
      },
    },
  ];

  const handleClick = (data) => {
    navigate("device-info", {
      state: {
        wardData: data,
        assignmentType: "New Device Installation",
      },
    });
  };

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          loading={props?.loading || false}
          disableSelectionOnClick
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              fileName: props.fileName,
              locationName: props.distName,
              title: props.title,
              dateRange: props?.dateRange,
              setDateRange: props?.setDateRange,
              isDateRange: props?.isDateRange,
              loading: props?.loading,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{ noRowsLabel: "No Records  Found :)" }}
        />
      </div>
    </Card>
  );
};

export default AssignedList;
