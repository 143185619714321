import { useTheme } from "@emotion/react";
import React, { useState } from "react"
import Chart from 'react-apexcharts';

const ApexChart = () => {
  const [series, setSeries] = useState([
    {
      data: [
        {
          x: 'Motor Not Operational',
          y: [
            new Date('2019-03-02').getTime(),
            new Date('2019-03-04').getTime()
          ]
        },
        {
          x: 'Electricty Un-Availability',
          y: [
            new Date('2019-03-04').getTime(),
            new Date('2019-03-08').getTime()
          ]
        },
        {
          x: 'High Consumption',
          y: [
            new Date('2019-03-08').getTime(),
            new Date('2019-03-12').getTime()
          ]
        },
        {
          x: 'Low Consumption',
          y: [
            new Date('2019-03-12').getTime(),
            new Date('2019-03-18').getTime()
          ]
        },
        // {
        //   x: 'IoT Operations',
        //   y: [
        //     new Date('2019-03-08').getTime(),
        //     new Date('2019-03-12').getTime()
        //   ]
        // },
        // {
        //   x: 'Network Issue',
        //   y: [
        //     new Date('2019-03-12').getTime(),
        //     new Date('2019-03-18').getTime()
        //   ]
        // }
      ]
    }
  ])
  const theme = useTheme()

  const options = {
    chart: {
      height: 350,
      type: 'rangeBar',
      background: 'parent'
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    grid: {
      show: false,
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      type: 'datetime'
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: true,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    }
  }

  return (
    <div id="chart">
      <Chart options={options} series={series} type="rangeBar" height={350} />
    </div>
  )
}

export default ApexChart