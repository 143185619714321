import { combineReducers } from '@reduxjs/toolkit';
import { reducer as loadMapData } from '../slices/loadMapData';
import { reducer as siteInfo } from '../slices/siteInfo';
import { reducer as target } from '../slices/target';
import { reducer as scheduledData } from '../slices/scheduledData';
import { reducer as uploadCSV } from '../slices/uploadCSV'
import { reducer as electricityAndMotor } from '../slices/electricityAndMotor'
import { reducer as lastPowerOn } from '../slices/lastPowerOn'
import { reducer as lastMotorOn } from '../slices/lastMotorOn'
import { reducer as operationalData } from '../slices/operationalData'
import { reducer as deviceStats } from '../slices/deviceStats'
import { reducer as uploadWardImage } from "../slices/uploadWardFiles"
import { reducer as downloadReport } from "../slices/downloadReport"
import { reducer as schemesDeviceOperational } from "../slices/schemesDeviceOperational"
import { reducer as maintenance } from "../slices/maintenance"
import { reducer as debugging } from "../slices/debugging"
import { reducer as deviceUpdate } from "../slices/deviceUpdate"
import { reducer as manageMasterData } from "../slices/manageMasterData"
import { reducer as userManagement } from "../slices/userManagement"
import { reducer as firmwareRegistration } from "../slices/firmwareRegistration"
import { reducer as installation } from "../slices/installation"

const rootReducer = combineReducers({
  map: loadMapData,
  siteinfo: siteInfo,
  target: target,
  uploadCSV: uploadCSV,
  scheduledData: scheduledData,
  electricityAndMotor: electricityAndMotor,
  lastPowerOn: lastPowerOn,
  lastMotorOn: lastMotorOn,
  operationalData: operationalData,
  deviceStats: deviceStats,
  uploadWardImage: uploadWardImage,
  downloadReport: downloadReport,
  schemesDeviceOperational: schemesDeviceOperational,
  maintenance: maintenance,
  debugging: debugging,
  deviceUpdate: deviceUpdate,
  manageMasterData: manageMasterData,
  userManagement: userManagement,
  firmwareRegistration: firmwareRegistration,
  installation
});

export default rootReducer;
