import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get, post } from './service';

const initialState = {
    reportDownloadData: {
        status: null,
        data: null
    },
    downloadDeleteLog: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'reportdownload',
    initialState,
    reducers: {
        setReportDownload(state, action) {
            state.reportDownloadData = action.payload
        },
        setDeleteLog(state, action) {
            state.downloadDeleteLog = action.payload
        }
    }
});

export const { reducer } = slice;

export const resetdownloadData = () => async (dispatch) => {
    dispatch(slice.actions.setReportDownload({ ...initialState.reportDownloadData }));
}
export const resetDeleteLogDownloadData = () => async (dispatch) => {
    dispatch(slice.actions.setDeleteLog({ ...initialState.downloadDeleteLog }));
}

export const downloadReportReducer = (did, dateRange, type, reportReturn ) => async (dispatch) => {
    dispatch(slice.actions.setReportDownload({ ...initialState.reportDownloadData, status: "loading" }));
    get(API.base_url + "phed/get_non_functional_excel", { did, from: dateRange[0], to: dateRange[1], type, reportReturn })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setReportDownload(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setReportDownload({ ...errResponse, status: "failed" }))
        });
};

export const getDeleteLog = () => async (dispatch) => {
    dispatch(slice.actions.setDeleteLog({ ...initialState.downloadDeleteLog, status: "loading" }));

    get(API.base_url + "phed/downloadDeleteLog")
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDeleteLog(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDeleteLog({ ...errResponse, status: "failed" }))
        });
};

export default slice;