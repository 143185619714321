import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import * as Yup from "yup";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { title } from "../../constants";
import AuthBanner from "../../components/authentication/AuthBanner";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { generateOTP, verifyOtpAndUpdate } from "../../slices/userManagement";
import { useRef } from "react";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router";

const VerifyUser = () => {
  const [showOTP, setShowOTP] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();
  const { logout } = useAuth();
  const navigate = useNavigate()

  const { verifyOtpResponse } = useSelector((state) => state.userManagement);
  const { generateOTPResponse } = useSelector((state) => state.userManagement);
  const [respMessage, setRespMessage] = useState({
    type: null,
    message: "",
    fields: [],
  });

  const [canLogin, setCanLogin] = useState(false);
  useEffect(() => {
    let message = "",
      type = null,
      fields = [];
    if (generateOTPResponse) {
      if (generateOTPResponse.statusCode == 200) {
        message = generateOTPResponse.message + ".";
        type = "success";
      } else if (generateOTPResponse.statusCode == 400) {
        message = generateOTPResponse.message;
        type = "error";
        if (generateOTPResponse.errors && generateOTPResponse.errors.length)
          fields = generateOTPResponse.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = generateOTPResponse.message;
        type = "error";
      }
    }

    if (verifyOtpResponse) {
      if (verifyOtpResponse.statusCode == 200) {
        message = verifyOtpResponse.message + ".";
        type = "success";
        setCanLogin(true);
        setTimeout(async () => {
          await logout();
          navigate("/")
        }, 2000);
      } else if (verifyOtpResponse.statusCode == 400) {
        message = verifyOtpResponse.message;
        type = "error";
        if (verifyOtpResponse.errors && verifyOtpResponse.errors.length)
          fields = verifyOtpResponse.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = verifyOtpResponse.message;
        type = "error";
      }
    }

    setRespMessage({ type, message, fields });
  }, [verifyOtpResponse, generateOTPResponse]);

  const createOTPClicked = () => {
    setShowOTP(true);
    dispatch(generateOTP(formRef.current.values.mobile_number));
  };

  return (
    <>
      <Helmet>
        <title>{title.label2}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AuthBanner />
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          marginTop={5}
        >
          <Card
            sx={{
              width: { xs: 1, sm: 1, md: 1 / 3, lg: 1 / 3, xl: 1 / 3 },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography variant="h6" component="h2">
                      {" "}
                      Verify User and Reset Password
                    </Typography>
                    {respMessage.type ? (
                      <div
                        style={{
                          color:
                            respMessage.type == "success" ? "green" : "red",
                        }}
                      >
                        <p>{respMessage.message}</p>
                        <ul>{respMessage.fields}</ul>
                      </div>
                    ) : (
                      ""
                    )}
                    {canLogin ? <>Redirecting to login page...</> : <></>}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Formik
                      innerRef={formRef}
                      initialValues={{
                        npassword: "",
                        cpassword: "",
                        mobile_number: "",
                      }}
                      validationSchema={Yup.object().shape({
                        npassword: Yup.string()
                          .required("Password is required")
                          .min(8, "Min 8 charecters required"),
                        cpassword: Yup.string()
                          .oneOf(
                            [Yup.ref("npassword"), null],
                            "Passwords must match"
                          )
                          .required("Confirm password is required"),
                        mobile_number: Yup.string()
                          .required("Mobile number is required")
                          .matches(/^[0-9]{10}$/, "Must be 10 digits"),
                        otp: Yup.string().required("OTP is required"),
                      })}
                      onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting }
                      ) => {
                        dispatch(verifyOtpAndUpdate(values));
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <TextField
                            error={Boolean(
                              touched.npassword && errors.npassword
                            )}
                            helperText={touched.npassword && errors.npassword}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="New Password:"
                            type="password"
                            name="npassword"
                            margin="normal"
                            fullWidth
                            value={values.npassword}
                          />
                          <TextField
                            error={Boolean(
                              touched.cpassword && errors.cpassword
                            )}
                            helperText={touched.cpassword && errors.cpassword}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Confirm Password:"
                            type="password"
                            name="cpassword"
                            margin="normal"
                            fullWidth
                            value={values.cpassword}
                          />
                          <TextField
                            error={Boolean(
                              touched.mobile_number && errors.mobile_number
                            )}
                            helperText={
                              touched.mobile_number && errors.mobile_number
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="mobile_number"
                            label="Enter Mobile Number:"
                            margin="normal"
                            fullWidth
                            value={values.mobile_number}
                          />
                          <Button
                            disabled={canLogin}
                            variant="contained"
                            onClick={createOTPClicked}
                          >
                            Generate OTP for verification
                          </Button>
                          {showOTP && (
                            <>
                              <Grid item>
                                <TextField
                                  margin="normal"
                                  fullWidth
                                  error={Boolean(touched.otp && errors.otp)}
                                  helperText={touched.otp && errors.otp}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  name="otp"
                                  label="Enter OTP reveived to your mobile"
                                  value={values.otp}
                                />
                              </Grid>

                              <Grid item>
                                <Button
                                  disabled={canLogin}
                                  type="submit"
                                  variant="contained"
                                  onClick={() => {
                                    // setShowOTP(true);
                                  }}
                                >
                                  Verify and Update Password
                                </Button>
                              </Grid>
                            </>
                          )}
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default VerifyUser;
