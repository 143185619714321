import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkIfRoleExist } from '../../utils/helper';
const InstallationAdminGuard = ({ children }) => {
    const userType = localStorage.getItem("USER_TYPE")
    if (userType && checkIfRoleExist(userType, ["APP_SUPER_ADMIN", "INSTALLATION_ADMIN"])) {
        return <>{children}</>;
    }
    else {
        return <Navigate to="/overview" />;
    }
};

InstallationAdminGuard.propTypes = {
    children: PropTypes.node
};

export default InstallationAdminGuard;
