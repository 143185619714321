import { Box, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { LocationFilter } from "../../../components/dashboard/shemes";
import { getSchemes, resetSchemes, setFilters } from "../../../slices/siteInfo";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components/Loader/Loader";
import { resetOpData } from "../../../slices/operationalData";
import DeviceList from "./DeviceList";
import { resetDeviceDeleteData } from "../../../slices/deviceUpdate";
import toast from "react-hot-toast";

const Schemes = (props) => {
    const [schemeLocation, setSchemeLocation] = useState({});
    const dispatch = useDispatch();
    const { district, schemes, lastSearchFilter } = useSelector((state) => state.siteinfo);
    const { deleteDevice: deletedWard } = useSelector((state) => state.deviceUpdate);
    const { deviceModificationData } = useSelector((state) => state.deviceUpdate);
    const [distName, setDistName] = useState("");
    const [allSchemes, setAllSchemes] = useState([]);


    const onChangeLocation = (data) => {
        const keys = Object.keys(data);
        let allReset = true;
        for (let i = 0; i < keys.length; i++) {
            allReset = allReset && !data[keys[i]];
        }

        if (allReset) {
            setAllSchemes([]);
            dispatch(resetSchemes());
            dispatch(resetOpData());
        }
        setSchemeLocation(data);
    };


    useEffect(() => {
        if (parseInt(schemeLocation.district)) {
            if (district["data"] && district["data"].length > 0) {
                const d = district["data"].filter((e) => parseInt(e.id) == schemeLocation.district);
                if (d.length) {
                    setDistName(d[0]["name"]);
                }
            }
        } else {
            setDistName("");
        }
    }, [schemeLocation.district, district]);

    useEffect(() => {
        if (
            schemeLocation.district ||
            schemeLocation.block ||
            schemeLocation.panchayat ||
            schemeLocation.ward
        ) {
            let d = {};
            if (schemeLocation.district) {
                d["district"] = schemeLocation.district;
            }
            if (schemeLocation.division) {
                d["division"] = schemeLocation.division;
            }
            if (schemeLocation.block) {
                d["block"] = schemeLocation.block;
            }
            if (schemeLocation.panchayat) {
                d["panchayat"] = schemeLocation.panchayat;
            }
            if (schemeLocation.ward) {
                d["ward"] = schemeLocation.ward;
            }
            dispatch(setFilters(schemeLocation));
            dispatch(getSchemes(d));
        }
    }, [schemeLocation, deletedWard, deviceModificationData, dispatch]);

    useEffect(() => {
        setAllSchemes(schemes["data"]);
    }, [schemes]);

    useEffect(() => {
        if(deletedWard?.statusCode === 200 || deletedWard?.statusCode === '200' ){
            toast.success( deletedWard?.message || "Deleted successfully")
        }else if(deletedWard?.statusCode === 404 || deletedWard?.statusCode === '404' ){
            toast.error( deletedWard?.message || "Device not found!")
        }else if(deletedWard?.statusCode === 500 || deletedWard?.statusCode === '500'){
            toast.error( deletedWard?.message || "Error occurred while updating the Device!")
        }
        dispatch(resetDeviceDeleteData())
    }, [deletedWard, dispatch]);

    return (
        <>
            <Box sx={{ py: 3 }}>
                <LocationFilter
                    onChange={onChangeLocation}
                    initialValues={lastSearchFilter}
                    schemes={allSchemes}
                    downloadExcel={true}
                />
            </Box>
            <Grid container spacing={3}>
                <Grid item xl={12} md={12} xs={12}>
                    {allSchemes && allSchemes.length > 0 ? (
                        <DeviceList
                            sx={{ height: "100%" }}
                            schemes={allSchemes}
                            fileName={"All_Schemes"}
                            distName={distName}
                        />
                    ) : null}
                </Grid>
            </Grid>
            {schemes["status"] === "loading" && <Loader />}
        </>
    );
};

export default Schemes;
