import { Button, Card } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import CustomToolbar from "./common/CustomToolbar";
import CustomNoRowsOverlay from "./common/CustomNoRowsOverlay";
import { formateDateTime, getCapitalizedFilename } from "../utils/helper";
import {
  deviceInstallationStatus,
  motorStatus,
  powerStatus,
} from "./CallingInfo";

const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});

export const callingStatus = [
  { value: true, label: "Completed" },
  { value: false, label: "Pending" },
];

const CallingList = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  const handleClick = (data) => {
    // if (data.row.calling_status.toLowerCase() === "completed") return;
    navigate("info", {
      state: {
        data: data.row,
        schemes: props?.schemes,
      },
    });
  };

  function getLabelFromStatus({ statusArray, statusValue }) {
    const matchedStatus = statusArray.find(
      (status) => status.value === statusValue
    );
    return matchedStatus ? matchedStatus.label : "";
  }

  useEffect(() => {
    if (!!props?.schemes && Array.isArray(props?.schemes)) {
      let tableDataTemp = [];
      props.schemes.map((page, i) =>
        tableDataTemp.push({
          ...page,
          anurakshak_name:
            page?.anurakshak?.name || page?.other?.op_name || "N/A",
          anurakshak_mobile:
            page?.anurakshak?.mobile_number || page?.other?.op_number || "N/A",
          ...page?.calling_details,
          power_status: getLabelFromStatus({
            statusArray: powerStatus,
            statusValue: page?.calling_details?.power_status,
          }),
          motor_status: getLabelFromStatus({
            statusArray: motorStatus,
            statusValue: page?.calling_details?.motor_status,
          }),
          device_installed: getLabelFromStatus({
            statusArray: deviceInstallationStatus,
            statusValue: page?.calling_details?.device_installed,
          }),
          faulty_site: getLabelFromStatus({
            statusArray: deviceInstallationStatus,
            statusValue: page?.calling_details?.faulty_site,
          }),
          calling_status: getLabelFromStatus({
            statusArray: callingStatus,
            statusValue: page?.calling_status,
          }),
          calling_date: formateDateTime(page?.calling_details?.created_ts, ""),
          row: i,
          is_non_functional: page?.is_non_functional
            ? "Non-functional"
            : "Others",
        })
      );
      setTableData(tableDataTemp);
    }
  }, [props?.schemes]);

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 80,
      sortable: false,
      hide: true,
      headerClassName: "scheme-data-table",
    },
    {
      field: "row",
      headerName: "SN.",
      width: 70,
      disableExport: true,
      sortable: false,
      headerClassName: "scheme-data-table",
      renderCell: (params) => {
        return <div>{params.formattedValue + 1}</div>;
      },
    },
    {
      field: "districtName",
      headerName: "District",
      width: 130,
      sortable: false,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: false,
    },
    {
      field: "panchayatName",
      headerName: `Panchayat`,
      width: 130,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Ward",
      width: 130,
      sortable: false,
    },
    {
      field: "calling_status",
      headerName: "Status",
      width: 130,
      sortable: false,
      renderCell: (params) => {
        const status = params.value || "";
        return (
          <span
            style={{
              color: `${status.toLowerCase() === "completed" ? "green" : "orange"
                }`,
            }}
          >
            {getCapitalizedFilename({ name: status })}
          </span>
        );
      },
    },
    {
      field: "is_non_functional",
      headerName: "Type",
      width: 130,
      sortable: false,
    },
    {
      field: "action",
      sortable: false,
      disableExport: true,
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        const status = params.row.calling_status.toLowerCase() === "completed";
        return (
          <Button variant="outlined" color={status ? "secondary" : "primary"}>
            {status ? "Update" : "Call"}
          </Button>
        );
      },
    },
    {
      field: "anurakshak_name",
      headerName: "Anurakshak Name",
      width: 140,
      sortable: false,
      hide: true,
    },
    {
      field: "anurakshak_mobile",
      headerName: "Anurakshak Mobile",
      width: 130,
      sortable: false,
      hide: true,
    },
    {
      field: "power_status",
      headerName: "Power Status",
      width: 120,
      sortable: false,
      hide: true,
    },
    {
      field: "motor_status",
      headerName: "Motor Status",
      width: 120,
      sortable: false,
      hide: true,
    },
    {
      field: "device_installed",
      headerName: "Device Installation",
      width: 160,
      sortable: false,
      hide: true,
    },
    {
      field: "faulty_site",
      headerName: "Faulty Site",
      width: 120,
      sortable: false,
      hide: true,
    },
    {
      field: "issue_desc",
      headerName: "Issue",
      width: 200,
      sortable: false,
      hide: true,
    },
    {
      field: "called_by",
      headerName: "Called By",
      width: 280,
      sortable: false,
      hide: true,
    },
    {
      field: "calling_date",
      headerName: "Calling Date",
      width: 190,
      sortable: false,
      hide: true,
    },
  ];

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          loading={props?.loading || false}
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              title:
                getCapitalizedFilename({ name: props?.fileName }) +
                " " +
                props.title,
              fileName: props?.fileName,
              locationName: props?.distName,
              disableExport: props?.disableExport,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{
            noRowsLabel: props.noRowsLabel || "No Records  Found :)",
          }}
          hideFooterRowCount={true}
          onRowClick={(e) => handleClick(e)}
          disableSelectionOnClick
        />
      </div>
    </Card>
  );
};

export default CallingList;
