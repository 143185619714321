import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { post } from './service';

const initialState = {
    addBlock: {
        status: null,
        data: null
    },
    addPanchayat: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'manageMasterData',
    initialState,
    reducers: {
        setAddBlockStatus(state, action) {
            state.addBlock = action.payload
        },
        setAddPanchayatStatus(state, action) {
            state.addPanchayat = action.payload
        }
    }
});

export const { reducer } = slice;

export const addBlock = (name, district, division) => async (dispatch) => {
    dispatch(slice.actions.setAddBlockStatus({ ...initialState.addBlock, status: "loading" }));

    post(API.base_url + "phed/addBlock", { name, district, division })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setAddBlockStatus(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setAddBlockStatus({ ...errResponse, status: "failed" }))
        });
};

export const addPanchayat = (name, district, division, block) => async (dispatch) => {
    dispatch(slice.actions.setAddPanchayatStatus({ ...initialState.addPanchayat, status: "loading" }));

    post(API.base_url + "phed/addPanchayat", { name, district, division, block })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setAddPanchayatStatus(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setAddPanchayatStatus({ ...errResponse, status: "failed" }))
        });
};

export default slice;