import { Card } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import CustomNoRowsOverlay from "../../../components/common/CustomNoRowsOverlay";
import CustomToolbar from "../../../components/common/CustomToolbar";
import { formateDateTime } from "../../../utils/helper";

const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});

const SingleGlandDevicesList = (props) => {
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      props.schemes.map((page, i) => {
        tableDataTemp.push({
          ...page,
          slno: i + 1,
          created_date: formateDateTime(page.created_ts),
        });
      });
    }

    setTableData(tableDataTemp);
  }, [props.schemes]);

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 100,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      headerName: "Id",
      width: 100,
      sortable: false,
      hide: true,
    },
    {
      field: "deviceId",
      headerName: "Device Id",
      width: 200,
      sortable: true,
    },
    {
      field: "barcode",
      headerName: "Barcode",
      width: 200,
      sortable: true,
    },
    {
      field: "created_date",
      headerName: "Created Date",
      width: 250,
      sortable: true,
    },
  ];

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          loading={props?.loading || false}
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              title: props.title,
              fileName: props?.fileName,
              locationName: props?.distName,
              disableExport: props?.disableExport,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{ noRowsLabel: "No Records  Found :)" }}
          hideFooterRowCount={true}
        />
      </div>
    </Card>
  );
};

export default SingleGlandDevicesList;
