import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { Item } from "../../pages/dashboard/installation/installation-report/InstallationReportDeviceInfo";
import ImagesWrapper from "./ImagesWrapper";
import { updateComparisonWard } from "../../actions/ward";
import { removeObjectKeys } from "../../utils/helper";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";

const ComparisonComponent = ({ current, archived, setLoading, loading }) => {
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState({});

  // RADIO BUTTON SELECT HANDLER 
  const handleSelection = (key, value) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  // KEY FOR VALIDATION WHICH HAVE DATA 
  const dataMapping = {
    latitude_longitude: ["survey_data.location.coords.latitude", "survey_data.location.coords.longitude"],
    anurakshak_name: ["survey_data.anurakshak.name"],
    anurakshak_mobile_number: ["survey_data.anurakshak.mobile_number"],
    anurakshak_photo: ["survey_data.anurakshak.photo"],
    site_photo: ["survey_data.siteDetails.photo"],
    isSiteOpen: ["survey_data.isSiteOpen"],
    electricity_meter_photo: ["survey_data.electricity_meter.photo"],
    electricity_meter_status: ["survey_data.electricity_meter.status"],
    starter_photo: ["survey_data.starter.photo"],
    starter_status: ["survey_data.starter.status"],
    surveyRemark: ["survey_data.surveyRemark"],
    deviceImage: ["deviceImage"],
    barcode: ["regData.deviceId", "regData.barcode"]
  };

  const validateSelections = () => {
    for (const key in dataMapping) {
      const paths = dataMapping[key];
      const currentVal = paths.some(path => getNestedValue(current, path));
      const archivedVal = paths.some(path => getNestedValue(archived, path));
      if ((currentVal || archivedVal) && typeof selectedValues[key] === "undefined") {
        return false;
      }
    }
    return true;
  };

  //GET NESTED VALUES FROM GIVEN OBJECT 
  const getNestedValue = (obj, path) => {
    const keys = path.split(".");
    return keys.reduce((acc, curr) => (String(acc) && typeof acc?.[curr] !== "undefined" ? acc?.[curr] : null), obj);
  };


  //FINAL SUBMIT HANDLER 
  const handleFinalUpdate = async () => {
    if (!validateSelections()) {
      toast.error("Please make sure all fields with data are selected!", { duration: 4000, })
      return;
    }

    try {
      setLoading(true);
      const cWRes = await updateComparisonWard({ payload: selectedValues, wardData: removeObjectKeys(["regData", "wardGisData", "lastHearBeat"], current) });
      if (cWRes && cWRes.statusCode === 200) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error while updating ward details! => ", error);
    } finally {
      setLoading(false);
    }
  };

  // RENDER LATITUDE AND LONGITUDE VALUES
  const renderLatLong = ({ key, label, lat, long, value }) => {
    const showValue = `${lat ?? "NA"} – ${long ?? "NA"}`;
    return (
      <label style={{ display: 'flex', alignItems: 'start', textWrap: 'nowrap', cursor: 'pointer' }}>
        <input type="radio" name={key} value={value ?? ""} onChange={() => handleSelection(key, value ?? "")} />
        <strong style={{ textTransform: 'capitalize', marginRight: '10px' }}>{label + ' : '}</strong>
        {showValue}
      </label>
    );
  };

  // RENDER VALUES 
  const renderValue = ({ value, showValue = "", label, key }) => {
    return (
      <Box key={key} sx={{ display: "flex", alignItems: "center" }}>
        <label style={{ display: "flex", alignItems: "start", textWrap: "nowrap", cursor: "pointer" }}>
          <input type="radio" name={key} value={value ?? ""} onChange={() => handleSelection(key, value ?? "")} />
          <strong style={{ textTransform: "capitalize", marginRight: "10px" }}>{label + " : "}</strong>
          {typeof value === 'string' && value.startsWith('http') && !value.endsWith(".pdf")
            ? <ImagesWrapper images={[{ url: value }]} />
            : `${showValue ? showValue : value ? value : '-NA-'}`
          }
        </label>
      </Box>
    );
  };

  //RENDER BOOLEAN VALUES 
  const renderBooleanValue = ({ value, showValue = "", label, key }) => {
    return (
      <Box key={key} sx={{ display: "flex", alignItems: "center" }}>
        <label style={{ display: "flex", alignItems: "start", textWrap: "nowrap", cursor: "pointer" }}>
          <input type="radio" name={key} value={value ?? ""} onChange={() => handleSelection(key, value ?? "")} />
          <strong style={{ textTransform: "capitalize", marginRight: "10px" }}>{label + " : "}</strong>
          {typeof value === 'string' && value.startsWith('http') && !value.endsWith(".pdf")
            ? <ImagesWrapper images={[{ url: value }]} />
            : `${showValue ? showValue : value ?? 'false'}`
          }
        </label>
      </Box>
    );
  };

  return (
    <Box sx={{ pb: 20 }}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Item>
            {/* CURRENT WARD DETAILS  */}
            <Typography variant="h4">Latest:</Typography>

            {/* LATITUDE AND LONGITUDE DETAILS  */}
            <Typography variant="h6" color={"#ed8181"} mt={1}>Latitude & longitude:</Typography>
            {renderLatLong({ value: current?.survey_data?.location, lat: current?.survey_data?.location?.coords?.latitude, long: current?.survey_data?.location?.coords?.longitude, key: "latitude_longitude", label: "Lat - Long" })}

            {/* ANURAKSHAK DETAILS  */}
            <Typography variant="h6" color={"#ed8181"} mt={1}>Anurakshak Details:</Typography>
            {renderValue({ value: current?.survey_data?.anurakshak?.name, key: "anurakshak_name", label: "name" })}
            {renderValue({ value: current?.survey_data?.anurakshak?.mobile_number, key: "anurakshak_mobile_number", label: "mobile number" })}
            {renderValue({ value: current?.survey_data?.anurakshak?.photo, key: "anurakshak_photo", label: "photo" })}

            {/* SITE DETAILS */}
            <Typography variant="h6" color={"#ed8181"} mt={1}>Site Info:</Typography>
            {renderValue({ value: current?.survey_data?.siteDetails?.photo, key: "site_photo", label: "site photo" })}
            {renderBooleanValue({ value: current?.survey_data?.isSiteOpen, key: "isSiteOpen", label: "site open" })}
            {renderValue({ value: current?.survey_data?.electricity_meter?.photo, key: "electricity_meter_photo", label: "Meter Photo" })}
            {renderBooleanValue({ value: current?.survey_data?.electricity_meter?.status, key: "electricity_meter_status", label: "Meter Status" })}
            {renderValue({ value: current?.survey_data?.starter?.photo, key: "starter_photo", label: "starter Photo" })}
            {renderBooleanValue({ value: current?.survey_data?.starter?.status, key: "starter_status", label: "starter Status" })}
            {renderValue({ value: current?.survey_data?.surveyRemark, key: "surveyRemark", label: "survey Remark" })}

            {/* DEVICE DETAILS */}
            <Typography variant="h6" color={"#ed8181"} mt={1}>Device Info:</Typography>
            {renderValue({ value: current?.deviceImage, key: "deviceImage", label: "device Image" })}
            {renderValue({ value: current?.regData?.deviceId, showValue: current?.regData?.barcode, key: "barcode", label: "device barcode" })}
          </Item>
        </Grid>
        <Grid item md={6}>
          <Item>
            {/* ARCHIVED WARD DETAILS */}
            <Typography variant="h4">Previous:</Typography>

            {/* LATITUDE AND LONGITUDE DETAILS  */}
            <Typography variant="h6" color={"#ed8181"} mt={1}>Latitude & longitude:</Typography>
            {renderLatLong({ value: archived?.survey_data?.location, lat: archived?.survey_data?.location?.coords?.latitude, long: archived?.survey_data?.location?.coords?.longitude, key: "latitude_longitude", label: "Lat - Long" })}

            {/* ANURAKSHAK DETAILS */}
            <Typography variant="h6" color={"#ed8181"} mt={1}>Anurakshak Details:</Typography>
            {renderValue({ value: archived?.survey_data?.anurakshak?.name, key: "anurakshak_name", label: "name" })}
            {renderValue({ value: archived?.survey_data?.anurakshak?.mobile_number, key: "anurakshak_mobile_number", label: "mobile number" })}
            {renderValue({ value: archived?.survey_data?.anurakshak?.photo, key: "anurakshak_photo", label: "photo" })}

            {/* SITE DETAILS */}
            <Typography variant="h6" color={"#ed8181"} mt={1}>Site Info:</Typography>
            {renderValue({ value: archived?.survey_data?.siteDetails?.photo, key: "site_photo", label: "site photo" })}
            {renderBooleanValue({ value: archived?.survey_data?.isSiteOpen, key: "isSiteOpen", label: "site open" })}
            {renderValue({ value: archived?.survey_data?.electricity_meter?.photo, key: "electricity_meter_photo", label: "Meter Photo" })}
            {renderBooleanValue({ value: archived?.survey_data?.electricity_meter?.status, key: "electricity_meter_status", label: "Meter Status" })}
            {renderValue({ value: archived?.survey_data?.starter?.photo, key: "starter_photo", label: "starter Photo" })}
            {renderBooleanValue({ value: archived?.survey_data?.starter?.status, key: "starter_status", label: "starter Status" })}
            {renderValue({ value: archived?.survey_data?.surveyRemark, key: "surveyRemark", label: "survey Remark" })}

            {/* DEVICE DETAILS */}
            <Typography variant="h6" color={"#ed8181"} mt={1}>Device Info:</Typography>
            {renderValue({ value: archived?.deviceImage, key: "deviceImage", label: "device Image" })}
            {renderValue({ value: archived?.regData?.deviceId, showValue: archived?.regData?.barcode, key: "barcode", label: "device barcode" })}
          </Item>
        </Grid>
      </Grid>
      <Container sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", mt: 2, alignItems: "center" }}>
        <Button sx={{ m: 2 }} variant="contained" size="large" onClick={handleFinalUpdate} disabled={loading}>
          Update
        </Button>
      </Container>
    </Box>
  );
};

export default ComparisonComponent;
