import { API } from "../config";
import { get, post } from "../slices/service";
import toast from "react-hot-toast";

export const getRegDevices = async ({
  filter = null,
  continuationToken = null,
}) => {
  try {
    const payload = {
      ...filter,
      continuationToken,
    };
    const regDevicesDaa = await get(
      API.base_url + "phed/debugging/registered-devices",
      payload
    );
    if (regDevicesDaa.statusCode === 200) {
      return regDevicesDaa;
    } else if (regDevicesDaa.statusCode === 404) {
      return;
    } else {
      throw new Error(
        regDevicesDaa.message ||
        "Error occurred while getting the registered devices!"
      );
    }
  } catch (error) {
    toast.error(
      error.message || "Error occurred while getting the registered devices!",
      {
        duration: 4000,
      }
    );
  }
};


export const ExportRegisteredFirmware = async ({ filter = null }) => {
  try {
    const payload = {
      ...filter,
    };

    const reportRes = await post(API.base_url + "phed/debugging/download-registered-devices", payload);
    if (reportRes.statusCode === 201 || reportRes.statusCode === 200) {
      toast.success(reportRes?.message || "Successfully exported!", {
        duration: 4000,
      });
      return reportRes;
    } else if (reportRes.statusCode === 400) {
      toast.error(reportRes?.message || "Error in exporting!", {
        duration: 4000,
      });
      return;
    } else {
      throw new Error(
        reportRes.message || "Error in exporting!"
      );
    }
  } catch (error) {
    toast.error(
      error.message || "Error occurred in exporting!",
      {
        duration: 4000,
      }
    );
  }
};
