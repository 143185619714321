import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./components/guard/AuthGuard";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import { SiteInfo } from "./components/dashboard/schemes/site_info";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import BlockDashboard from "./pages/dashboard/BlockDashboard";
import MapTrackerIssueMap from "./pages/dashboard/MapTrackerIssueMap";
import MapTrackerSchemesMap from "./pages/dashboard/MapTrackerSchemesMap";
import SchemeDashboard from "./pages/dashboard/SchemeDashboard";
import DivisionDashboard from "./pages/dashboard/DivisionDashboard";
import StateDashboard from "./pages/dashboard/StateDashboard";
import Admin from "./pages/dashboard/Admin";
import AdminGuard from "./components/guard/AdminGuard";
import Reports from "./pages/dashboard/Reports";
import IssueLogUC from "./components/dashboard/schemes/IssueLogUC";
import DebuggingGuard from "./components/guard/DebuggingGuard";
import Maintenance from "./pages/dashboard/Maintenance";
import DeviceData from "./components/dashboard/device_data";
import DebuggingLanding from "./pages/dashboard/DebuggingLanding";
import DataManagenentGuard from "./components/guard/DataManagement";
import FirmwareDashboard from "./pages/dashboard/FirmwareDashboard";
import FirmwareLoginFlow from "./pages/authentication/VerifyUser";
import SignUp from "./pages/authentication/SignUp";
import VerifyUser from "./pages/authentication/VerifyUser";
import RegisteredDeviceList from "./pages/dashboard/RegisteredDeviceList";
import StaffAssignment from "./pages/dashboard/installation/StaffAssignment";
import InstallationReport from "./pages/dashboard/installation/InstallationReport";
import InstallationAdminGuard from "./components/guard/InstallationAdminGuard";
import MonitorDevice from "./pages/dashboard/installation/MonitorDevice";
import InstallationTracker from "./pages/dashboard/installation/InstallationTracker";
import InstallationDeviceInfo from "./pages/dashboard/installation/instllation-tracker/InstallationDeviceInfo";
import InstallationReportDeviceInfo from "./pages/dashboard/installation/installation-report/InstallationReportDeviceInfo";
import ResetPassword from "./pages/authentication/ResetPassword";
import Profile from "./pages/profile/Profile";
import InstallationReportDeviceInfoManualEntry from "./pages/dashboard/installation/manual-installation/InstallationReportDeviceInfoManualEntry";
import InstallationManualEntry from "./pages/dashboard/installation/InstallationManualEntry";
import ManageManualInstallation from "./pages/dashboard/installation/ManageManualInstallation";
import Calling from "./pages/dashboard/Calling";
import CallingInfo from "./components/CallingInfo";
import CallingGuard from "./components/guard/CallingGuard";
import SingleGlandDevices from "./pages/dashboard/SingleGlandDevices";
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const Schemes = Loadable(lazy(() => import("./pages/dashboard/Schemes")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "maptracker",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/maptracker",
        element: <Navigate to="/maptracker/schemesmap" replace />,
      },
      {
        path: "schemesmap",
        element: <MapTrackerSchemesMap />,
      },
      {
        path: "issuesmap",
        element: <MapTrackerIssueMap />,
      },
    ],
  },
  {
    path: "verify-user",
    element: <VerifyUser />,
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "/dashboard",
        element: <Navigate to="/dashboard/schemedashboard" replace />,
      },
      {
        path: "schemedashboard",
        element: <SchemeDashboard />,
      },
      {
        path: "blockdashboard",
        element: <BlockDashboard />,
      },
      {
        path: "divisiondashboard",
        element: <DivisionDashboard />,
      },
      {
        path: "statedashboard",
        element: <StateDashboard />,
      },
    ],
  },
  {
    path: "overview",
    element: <DashboardLayout />,
    children: [
      {
        path: "/overview",
        element: <Overview />,
      },
      {
        path: "/overview/admin",
        element: (
          <AdminGuard>
            <Admin />
          </AdminGuard>
        ),
      },
      {
        path: "/overview/device_data_management",
        element: (
          <DataManagenentGuard>
            <DeviceData />
          </DataManagenentGuard>
        ),
      },
      {
        path: "/overview/debugging",
        element: (
          <DebuggingGuard>
            <DebuggingLanding />
          </DebuggingGuard>
        ),
      },
      {
        path: "/overview/maintenance",
        element: (
          <DebuggingGuard>
            <Maintenance />
          </DebuggingGuard>
        ),
      },
      {
        path: "/overview/firmware_dashboard",
        element: <FirmwareDashboard />,
      },
      {
        path: "/overview/device-list",
        element: <RegisteredDeviceList />,
      },
      {
        path: "/overview/single-gland-devices",
        element: <SingleGlandDevices />,
      },
      {
        path: "/overview/firmware_login_flow",
        element: <FirmwareLoginFlow />,
      },
      {
        path: "/overview/reports",
        element: <Reports />,
      },
      {
        path: "/overview/installation",
        children: [
          {
            path: "staff-assignment",
            element: (
              <InstallationAdminGuard>
                <StaffAssignment />
              </InstallationAdminGuard>
            ),
          },
          {
            path: "installation-report",
            element: (
              <InstallationAdminGuard>
                <InstallationReport />
              </InstallationAdminGuard>
            ),
          },
          {
            path: "monitor-device",
            element: (
              <InstallationAdminGuard>
                <MonitorDevice />
              </InstallationAdminGuard>
            ),
          },
          {
            path: "installation-tracker",
            element: (
              <InstallationAdminGuard>
                <InstallationTracker />
              </InstallationAdminGuard>
            ),
          },
          {
            path: "installation-tracker/device-info",
            element: (
              <InstallationAdminGuard>
                <InstallationDeviceInfo />
              </InstallationAdminGuard>
            ),
          },
          {
            path: "installation-report/device-info",
            element: (
              <InstallationAdminGuard>
                <InstallationReportDeviceInfo />
              </InstallationAdminGuard>
            ),
          },
          {
            path: "manual-installation",
            element: (
              <InstallationAdminGuard>
                <InstallationManualEntry />
              </InstallationAdminGuard>
            ),
          },
          {
            path: "manual-installation/device-info-manual",
            element: (
              <InstallationAdminGuard>
                <InstallationReportDeviceInfoManualEntry />
              </InstallationAdminGuard>
            ),
          },
          {
            path: "manage-manual-installation",
            element: (
              <InstallationAdminGuard>
                <ManageManualInstallation />
              </InstallationAdminGuard>
            ),
          },
        ],
      },
      {
        path: "/overview/schemes",
        children: [
          {
            path: "/overview/schemes",
            element: <Schemes />,
          },
          {
            path: "/overview/schemes/siteinfo",
            element: <SiteInfo />,
          },
          {
            path: "/overview/schemes/issuelog",
            element: <IssueLogUC />,
          },
        ],
      },
    ],
  },
  {
    path: "calling",
    element: (
      <CallingGuard>
        <DashboardLayout />
      </CallingGuard>
    ),
    children: [
      {
        path: "/calling",
        element: <Calling />,
      },
      {
        path: "/calling/info",
        element: <CallingInfo />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
];

export default routes;
