import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  Button,
  Divider,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import AuthBanner from "../../components/authentication/AuthBanner";
import { LoginJWT } from "../../components/authentication/login";
import Logo from "../../components/Logo";
import useAuth from "../../hooks/useAuth";
// import gtm from "../../lib/gtm";
import login_screen_image from "../../images/login_screen_image.png";
import logo from "../../images/logo.png";
import { title } from "../../constants";
import { useSelector } from "../../store";
import BrandBanner from "../../components/BrandBanner";

const Login = () => {
  const { platform } = useAuth();
  const data = useSelector((state) => state);

  // useEffect(() => {
  //   gtm.push({ event: "page_view" });
  // }, []);

  return (
    <>
      <Helmet>
        <title>{title.label2}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AuthBanner />
        <Container sx={{ py: "80px" }}>
          {/* <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              Note * : <font color="orange">Backend database structure upgrade in progress (till 24th Jan 2022). Some features may not work.</font>
            </Grid>
          </Grid> */}

          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid md="7" sm="12">
                  <img
                    style={{ borderRadius: 10 }}
                    width="100%"
                    src={login_screen_image}
                  />
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <BrandBanner />
                    {/* <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={logo} width={60} />
                      <div style={{ display: "flex", flexDirection: "column-reverse", marginLeft: 10 }}>
                        <Typography color="textSecondary" variant="h6" >Powered By <span style={{color:"#727cf5"}}>{title.label1}</span></Typography>
                        <Typography color="textPrimary" variant="h5">
                          {title.label2}
                        </Typography>
                      </div>
                    </div> */}
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      mt: 3,
                    }}
                  >
                    {platform === "JWT" && <LoginJWT />}
                    <Grid container marginTop={1} flexDirection="column">
                      <Grid item>
                        Do not have account? <Link href={"/authentication/signup"}>Sign Up</Link>
                      </Grid>
                      <Grid item>
                        Forgot Passowrd? <Link href={"/authentication/reset-password"}>Reset now</Link>
                      </Grid>
                    </Grid>
                    
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <span>
              Powered by <b>Geron Engineering Private Limited &#174;</b>
            </span>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default Login;
