import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkIfRoleExist } from '../../utils/helper';
const DebuggingGuard = ({ children }) => {
    const userRoles = localStorage.getItem("USER_TYPE")
    if (checkIfRoleExist(userRoles, ["APP_MAINTAINER", "APP_SUPER_ADMIN"])) {
        return <>{children}</>;
    }
    else {
        return <Navigate to="/overview" />;
    }
};

DebuggingGuard.propTypes = {
    children: PropTypes.node
};

export default DebuggingGuard;
