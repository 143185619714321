import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { filepost, get, post } from './service';

const initialState = {
    deviceModificationData: {
        status: null,
        data: null
    },
    deleteDevice: {
        status: null,
        data: null
    },
    duplicateDevices: {
        status: null,
        data: null
    },
    nicDataUpdate: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'deviceModification',
    initialState,
    reducers: {
        setDeviceModificationData(state, action) {
            state.deviceModificationData = action.payload
        },
        setDeviceDeleteData(state, action) {
            state.deleteDevice = action.payload
        },
        setDuplicateDeviceData(state, action) {
            state.duplicateDevices = action.payload
        },
        setNICData(state, action) {
            state.nicDataUpdate = action.payload
        }
    }
});

export const { reducer } = slice;

export const resetDeviceModificationData  = () => dispatch => {
    return dispatch(slice.actions.setDeviceModificationData(initialState.deviceModificationData));
}

export const updateDevice = (data) => async (dispatch) => {
    dispatch(slice.actions.setDeviceModificationData({ ...initialState.deviceModificationData, status: "loading" }));

    post(API.base_url + "phed/updateDevice", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDeviceModificationData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDeviceModificationData({ ...errResponse, status: "failed" }))
        });
};

export const resetDeviceDeleteData = () => dispatch => {
    dispatch(slice.actions.setDeviceDeleteData(initialState.deleteDevice));
}

export const deleteDevice = (id, metadata) => async (dispatch) => {
    dispatch(slice.actions.setDeviceDeleteData({ ...initialState.deleteDevice, status: "loading" }));

    post(API.base_url + "phed/deleteDevice", { id, metadata })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDeviceDeleteData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDeviceDeleteData({ ...errResponse, status: "failed" }))
        });
};

export const getDuplicateDeviceIds = () => async (dispatch) => {
    dispatch(slice.actions.setDuplicateDeviceData({ ...initialState.duplicateDevices, status: "loading" }));

    get(API.base_url + "phed/duplicateDevices")
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDuplicateDeviceData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDuplicateDeviceData({ ...errResponse, status: "failed" }))
        });
};

export const updateNICData = (file) => async (dispatch) => {
    dispatch(slice.actions.setNICData({ ...initialState.nicDataUpdate, status: "loading" }));

    filepost(API.base_url + "phed/updateNICData", { "nicfile": file })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setNICData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setNICData({ ...errResponse, status: "failed" }))
        });
};

export default slice;