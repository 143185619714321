import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import useSettings from "../../../hooks/useSettings";
import { useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import ShowWhen from "../../../components/common/ShowWhen";
import toast, { LoaderIcon } from "react-hot-toast";
import {
  ManualInstallationCSVUpload,
  resetManageManualInstallationCSVUpload,
} from "../../../slices/uploadCSV";
import { Loader } from "../../../components/Loader/Loader";

function ManageManualInstallation() {
  const { settings } = useSettings();
  const dispatch = useDispatch();

  const { manageManualInstallationCSVUpload } = useSelector(
    (state) => state.uploadCSV
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);

  const onChangeUploadFile = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file["type"] === "text/csv" ||
        file["type"] === "application/vnd.ms-excel")
    ) {
      setSelectedFile(file);
    } else {
      alert("Please select CSV file");
    }
  };

  useEffect(() => {
    setUploadResponse(null);
    dispatch(resetManageManualInstallationCSVUpload());
  }, []);

  useEffect(() => {
    if (
      manageManualInstallationCSVUpload &&
      manageManualInstallationCSVUpload?.status === "SUCCESS"
    ) {
      toast.success("Uploaded Successfully!");
      setUploadResponse(manageManualInstallationCSVUpload);
      setSelectedFile(null);
      dispatch(resetManageManualInstallationCSVUpload());
    } else if (
      manageManualInstallationCSVUpload &&
      manageManualInstallationCSVUpload?.status &&
      manageManualInstallationCSVUpload?.status?.toUpperCase() !== "SUCCESS" &&
      manageManualInstallationCSVUpload?.status?.toLowerCase() !== "loading"
    ) {
      toast.error("Failed to upload!");
    }
  }, [manageManualInstallationCSVUpload]);

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Manage Manual Installation"}
            prevPath={[{ name: "Manually Installation" }]}
          />
          <Typography variant="h5" paddingTop={2}>
            Upload Ward Data:
          </Typography>
          <Grid container style={{ marginTop: 10 }}>
            <Grid item md={3}>
              <Button component="label" variant="outlined">
                {selectedFile ? selectedFile?.name : "Select CSV File"}
                <input
                  type="file"
                  onChange={onChangeUploadFile}
                  hidden
                  accept="text/csv, application/vnd.ms-excel"
                />
              </Button>
            </Grid>
            <Grid item md={1}>
              <Button
                disabled={
                  !selectedFile ||
                  uploadResponse?.status?.toLowerCase() === "loading" ||
                  manageManualInstallationCSVUpload?.status === "loading"
                }
                variant="contained"
                onClick={() => {
                  if (!selectedFile) {
                    toast.error("Please select a file first!");
                    return;
                  }
                  dispatch(ManualInstallationCSVUpload(selectedFile));
                }}
              >
                <ShowWhen
                  when={
                    uploadResponse?.status?.toLowerCase() === "loading" ||
                    manageManualInstallationCSVUpload?.status === "loading"
                  }
                  component={
                    <div>
                      <LoaderIcon />
                    </div>
                  }
                  otherwise={"Upload"}
                />
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 10 }}>
            <a
              target="_blank"
              href={`https://rollzproddocuments.blob.core.windows.net/templates/NewAddBarcodeIsInstalledFormat.csv?id=${new Date().getTime()}`}
              rel="noreferrer"
            >
              Download template file
            </a>
          </Grid>
          <ShowWhen
            when={uploadResponse}
            component={
              <>
                Total number of wards Updated :{" "}
                {uploadResponse?.installationCount}
                <br />
                Total number of barcodes Updated: {uploadResponse?.barcodeCount}
                <br />
                Wards with already exists Barcode:
                <br />{" "}
                <ol>
                  {" "}
                  {uploadResponse?.alReadyExistBarcode?.map((item) => (
                    <li>
                      {item?.wardId} : {item?.barcode}
                    </li>
                  ))}
                </ol>
                Wards with Invalid Barcode:
                <br />{" "}
                <ol>
                  {" "}
                  {uploadResponse?.errorResponse?.map((item) => (
                    <li>
                      {item?.wardId} : {item?.barcode}
                    </li>
                  ))}
                </ol>
                <br />
              </>
            }
            otherwise={null}
          />
        </Container>
      </Box>
      <ShowWhen
        when={manageManualInstallationCSVUpload?.status === "loading"}
        component={<Loader />}
      />
    </>
  );
}

export default ManageManualInstallation;
