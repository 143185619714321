import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { saveReport } from "../../slices/debugging"
import { currentDateTime, getDateTime, getDiffInMin } from "../../utils/helper"
import IOSSwitch from "../IOSSwitch"

const DebuggingControlUI = function (props) {

    const [ts, setTS] = useState(null)
    const [power, setPower] = useState(null)
    const [motor, setMotor] = useState(null)
    const [network, setNetwork] = useState(null)
    const dispatch = useDispatch()

    const [getNewDataResReceived, setGetNewDataResReceived] = useState(false)
    const [deviceId, setDeviceId] = useState("")
    const [workingStatus, setWorkingStatus] = useState("")

    const onClickGo = () => {
        if (props.data["deviceId"]) {
            props.onDataRequest(props.data["deviceId"], "newTestReq")
        }
    }

    useEffect(() => {
        if (props.data["_ts"]) {
            setTS(getDateTime(props.data["_ts"]))
        }

        if (props.data["deviceId"]) {
            setDeviceId(props.data["deviceId"])
        }

        let powerOn = false
        let networkOn = false
        let motorOn = false
        let t1 = new Date()
        if (props.data.current >= 2) {
            let diff = getDiffInMin(props.data["_ts"], t1.getTime() / 1000)
            if (diff <= 4) {
                powerOn = true
                motorOn = true
                networkOn = true
            } else {
                setWorkingStatus("Not Working Fine")
                setGetNewDataResReceived(true)
            }
        } else {
            let diff = getDiffInMin(props.data["_ts"], t1.getTime() / 1000)
            if (diff <= 10) {
                powerOn = true
            } else {
                setWorkingStatus("Not Working Fine")
                setGetNewDataResReceived(true)
            }

            if (diff <= 1440) {
                networkOn = true
            } else {
                setWorkingStatus("Not Working Fine")
                setGetNewDataResReceived(true)
            }
        }
        setPower(powerOn)
        setMotor(motorOn)
        setNetwork(networkOn)

        if (props.data.reqType == "newTestReq") {
            setGetNewDataResReceived(true)
            if (workingStatus == "")
                if (props.prevData == null) {
                    setWorkingStatus("Something went wrong")
                    // return <font color="red">Something went wrong</font>
                } else if ((props.prevData.current < 2 && props.data.current >= 2) || (props.prevData.current >= 2 && props.data.current < 2)) {
                    setWorkingStatus("Working Fine")
                    // return <Typography color="textSecondary" variant="subtitle2">{str1} is <font color="green">Working Fine </font>{str} Date: <b>{currentDateTime("date")}</b> </Typography>
                } else if (props.prevData.current == props.data.current) {
                    setWorkingStatus("Not Working Fine")
                    // return <Typography color="textSecondary" variant="subtitle2">{str1} is <font color="red">Not Working Fine </font>{str} Date: <b>{currentDateTime("date")}</b> </Typography>
                }
        }

    }, [props.data])

    useEffect(() => {
        if (props.prevData == null) {
            setGetNewDataResReceived(false)
        }
    }, [props.prevData])

    const isWorkingFine = () => {
        const str1 = `IOT Device ID: ${props.deviceId}`
        const str = `as per installation testing carried on`
        if (workingStatus) {
            return <Typography color="textSecondary" variant="subtitle2">{str1} is <font color={workingStatus.includes("Not") ? "red" : "green"}>{workingStatus} </font>{str} Date: <b>{currentDateTime("date")}</b> </Typography>
        } else {
            return <font color="red">Something went wrong</font>
        }
    }

    const saveDataToDB = () => {
        const dateTime = currentDateTime()

        const data = {
            dateTime,
            workingStatus,
            deviceId,
            dateReceivedTS: ts
        }

        dispatch(saveReport(data))
    }

    return <Fragment>
        <Grid container padding={2}>
            <Grid item md={6} display={"flex"} justifyContent={"center"}>
                <Typography color="textPrimary"
                    variant="title"> Device Installation Status</Typography>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item md={6}>
                <Grid container display={"flex"} justifyContent={"space-between"}>
                    <Grid item md={4} display={"flex"} justifyContent={"left"}>
                        <Typography color="textSecondary"
                            variant="subtitle2"> Device Id : </Typography>&nbsp;
                        <Typography color="textSecondary"
                            variant="subtitle2">{props.data["deviceId"]}</Typography>
                    </Grid>
                    <Grid item md={6} display={"flex"} justifyContent={"right"}>
                        <Typography color="textSecondary"
                            variant="subtitle2">Testing Date Time: </Typography>&nbsp;
                        <Typography color="textSecondary"
                            variant="subtitle2">{currentDateTime()}</Typography>
                    </Grid>
                </Grid>
                <br />
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Electricity</TableCell>
                                <TableCell align="center">Motor</TableCell>
                                <TableCell align="center">Network</TableCell>
                                <TableCell align="center">Data Stamp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">
                                    <FormControlLabel
                                        control={<IOSSwitch checked={power} />}
                                        label={power ? "on" : "off"}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <FormControlLabel
                                        control={<IOSSwitch checked={motor} />}
                                        label={motor ? "on" : "off"}
                                    /></TableCell>
                                <TableCell align="center">
                                    <FormControlLabel
                                        control={<IOSSwitch checked={network} />}
                                        label={network ? "on" : "off"}
                                    />

                                </TableCell>
                                <TableCell align="center">{ts ? <Fragment>{ts["date"]} {ts["time"]}</Fragment> : ""}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Grid item md={12}>
                    <Typography color="textSecondary"
                        variant="subtitle2">Current sensed by the device when motor <b style={{ color: "blue" }}>{props.data.current < 2 ? "off" : "on"}</b>: <b>{props.data.current}</b>
                    </Typography>
                </Grid>
                {workingStatus == "" &&
                    <Grid item md={12}>
                        <Typography color="textSecondary"
                            variant="subtitle2">Switch <b style={{ color: "blue" }}>{props.data.current < 2 ? "on" : "off"}</b> the motor and press <Button size="small" variant="contained" onClick={onClickGo}>Go</Button></Typography>
                    </Grid>
                }
                <br />
                {getNewDataResReceived && <Fragment>
                    <Grid item md={12}>{isWorkingFine()}</Grid>
                </Fragment>}
            </Grid>
            <br />
            {getNewDataResReceived &&
                <Grid container>
                    <Grid item md={6} display={"flex"} justifyContent={"center"}>
                        <Button variant="contained" onClick={() => saveDataToDB()} >Save & Exit</Button> &nbsp;&nbsp;
                        <Button variant="contained">Save & Proceed</Button>
                    </Grid>
                </Grid>
            }
        </Grid>
    </Fragment>
}

export default DebuggingControlUI