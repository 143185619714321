import { Box, Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useLocation } from "react-router";
import PageHeading from "../../../../components/PageHeading";
import { title } from "../../../../constants";
import useSettings from "../../../../hooks/useSettings";
import { useState } from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import {
  genearteDeviceInstallationReport,
  getDeviceLastData,
} from "../../FirmwareTestSteps/_service";
import {
  capitalizeFirstLetter,
  getDateTime,
  getDeviceDataFlag,
  getInstallationDate2,
  getInstallationStatus,
  getSGMStrength,
} from "../../../../utils/helper";
import { getAllDeviceTelemetry } from "../../../../slices/debugging";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/Loader/Loader";
import { has } from "lodash";

function getInstallationTestStatus(wardData) {
  if (wardData && wardData?.installation_data && wardData?.regData) {
    if (wardData.regData?.installation_testing_status?.overall)
      return capitalizeFirstLetter(
        wardData.regData.installation_testing_status.overall
      );
    else return "Fail";
  } else {
    return "Pending";
  }
}


function InstallationDeviceInfo() {
  const location = useLocation();
  const settings = useSettings();
  const [assignmentType, setAssignmentType] = useState("");
  const [wardData, setWardData] = useState({});
  const [loading, setLoading] = useState(false);
  const [gsmStrength, setGSMStrength] = useState("");
  const { allDeviceTelemetry: allDeviceData } = useSelector(
    (state) => state.debugging
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const assignmentType = location.state.assignmentType
      ? location.state.assignmentType
      : "-";
    setWardData(location.state.wardData);
    setAssignmentType(assignmentType);

    if (has(location.state, "wardData.fw_device_id", null)) {
      getDeviceLastData(location.state.wardData.fw_device_id).then((e) => {
        if (e.data && e.data.g) setGSMStrength(getSGMStrength(e.data.g));
      });

      dispatch(
        getAllDeviceTelemetry(location.state.wardData.fw_device_id, "", 4320)
      );
    }
  }, [location.state]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    height: "100%",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Helmet>
        <title>{title.label2} | Installation Device Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Installation Device Details"}
            prevPath={[{ name: "Installation Device Details" }]}
          />
          <Box sx={{ py: 3 }}>
            <Grid container spacing={1}>
              <Grid item md={1}>
                <Typography color="textPrimary" variant="subtitle2">
                  Assignment Type:
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  style={{ fontWeight: "500", color: "#777" }}
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {assignmentType}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ py: 2 }}>
            <Grid container spacing={1}>
              <Grid item md={4}>
                <Item>
                  <Typography color="textPrimary" variant="subtitle2">
                    Site Details
                  </Typography>

                  <Box sx={{ py: 2 }}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          {" "}
                          District:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}>
                          {wardData.districtName}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Division:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}>
                          {wardData.division}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Block:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}>
                          {" "}
                          {wardData.blockName}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Panchayat:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}>
                          {wardData.panchayatName}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Ward:{" "}
                        </Typography>{" "}
                        <span style={{ marginLeft: 8 }}> {wardData.name}</span>
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
              </Grid>
              <Grid item md={4}>
                <Item>
                  <Typography color="textPrimary" variant="subtitle2">
                    TE Details
                  </Typography>
                  <Box sx={{ py: 2 }}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          TE Name:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {wardData.installation_user_mapping
                            ? wardData.installation_user_mapping.name
                            : ""}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          TE Mobile Number:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {wardData.installation_user_mapping
                            ? wardData.installation_user_mapping.mobile_number
                            : "Not Mapped"}
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
              </Grid>
              <Grid item md={4}>
                <Item>
                  <Typography color="textPrimary" variant="subtitle2">
                    Device Details
                  </Typography>
                  <Box sx={{ py: 2 }}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Device Barcode:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {wardData.regData ? wardData.regData.barcode : ""}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Date of Installation:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {getInstallationDate2(wardData)}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Installation status:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {getInstallationStatus(wardData)}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Installation test status:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {getInstallationTestStatus(wardData)}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Network Status:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {gsmStrength} (last received)
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          SIM Number:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {wardData.regData ? wardData.regData.simNumber : ""}
                        </span>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography color="textSecondary" variant="subtitle2">
                          Carrier Name:
                        </Typography>
                        <span style={{ marginLeft: 8 }}>
                          {wardData.regData ? wardData.regData.carrierName : ""}
                        </span>
                      </Grid>
                    </Grid>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ py: 2 }}>
            <Grid container direction={"row"} justifyContent={"space-between"}>
              <Grid item md={3}>
                <Typography style={{ color: "#5664d2", fontWeight: "bold" }}>
                  Three Days Device Data
                </Typography>
              </Grid>

              <Grid item md={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setLoading(true);
                    genearteDeviceInstallationReport({
                      ...wardData,
                      assignmentType,
                    }).then((e) => {
                      setLoading(false);
                      if (e.statusCode == 200) {
                        if (e.data && e.data.filePath) {
                          var link = document.createElement("a");
                          link.href = e.data.filePath;
                          link.target = "_blank";
                          link.download = e.data.filename;
                          link.dispatchEvent(new MouseEvent("click"));
                        }
                      }
                    });
                  }}
                >
                  Download Report
                </Button>
              </Grid>
            </Grid>
          </Box>
          {allDeviceData &&
            allDeviceData.data &&
            allDeviceData.data.length > 0 ? (
            <Box sx={{ py: 2, maxHeight: 300, overflow: "scroll" }}>
              <table width={"50%"}>
                <tr>
                  <th>Sl No</th>
                  <th>Time Stamp (DD/MM/YYYY HH:MM:SS) </th>
                  <th>Event</th>
                </tr>
                {allDeviceData.data.map((e, i) => {
                  const datetime = getDateTime(e._ts);
                  return (
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <Typography color="textSecondary" variant="subtitle2">
                          {i + 1}
                        </Typography>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >{`${datetime.date} ${datetime.time}`}</Typography>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Typography color="textSecondary" variant="subtitle2">
                          {getDeviceDataFlag(e)}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </Box>
          ) : (
            allDeviceData.status != "loading" && (
              <span style={{ color: "red" }}>
                Device data not found for last Three Days
              </span>
            )
          )}
          {((allDeviceData.status && allDeviceData.status == "loading") ||
            loading) && <Loader />}
        </Container>
      </Box>
    </>
  );
}

export default InstallationDeviceInfo;
