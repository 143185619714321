import { Button, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getRegData } from "./_service";

const ImeiConfirm = (props) => {
  const [imei, setImei] = useState("");
  const [cimei, setCImei] = useState("");

  const verifyDetails = () => {
    if (imei.length == 15 && imei == cimei) {
      getRegData(imei).then((data) => {
        if (data && data["statusCode"] == 200) {
            props.enableNext(true)
            props.updateRegData(data["data"])
        } else {
          alert("IMEI not found. Please Verfiy IMEI.");
        }
      });
    } else {
      alert("IMEI should of length 15 and both fields should match");
    }
  };

  useEffect(() => {
    
    props.enableNext(false)
  }, [])

  return (
    <>
      <p>
        Complete <b>Step 16</b> to <b>Step 20</b>
      </p>
      <div>
        <TextField
          type={"password"}
          value={imei}
          label="Enter IMEI"
          onChange={(e) => {
            setImei(e.target.value);
          }}
        />
        <br />
        <br />
        <TextField
          type={"number"}
          value={cimei}
          label="Confirm IMEI"
          onChange={(e) => {
            setCImei(e.target.value);
          }}
        />
        <Button onClick={verifyDetails}>Verify</Button>
      </div>
      <br />
      <br />
    </>
  );
};

export default ImeiConfirm;
