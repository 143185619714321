import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { checkIfRoleExist } from "../../utils/helper";
const CallingGuard = ({ children }) => {
  const userType = localStorage.getItem("USER_TYPE");
  if (
    userType &&
    checkIfRoleExist(userType, ["APP_SUPER_ADMIN", "GERON_CALLING"])
  ) {
    return <>{children}</>;
  } else {
    return <Navigate to="/overview" />;
  }
};

CallingGuard.propTypes = {
  children: PropTypes.node,
};

export default CallingGuard;
