import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { post } from './service';
import toast from 'react-hot-toast';

const initialState = {
    approve: {
        status: null,
        data: null
    },
    reject: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'installation',
    initialState,
    reducers: {
        setApprove(state, action) {
            state.approve = action.payload
        },
        setReject(state, action) {
            state.reject = action.payload
        },
        resetAll(state, action){
            state.reject = action.payload
            state.approve = action.payload
        }
    }
});

export const { reducer } = slice;

export const approveWard = (ward) => async (dispatch) => {
    dispatch(slice.actions.setApprove({ ...initialState.approve, status: "loading" }));

    post(API.base_url + "phed/approve-ward", { ward })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            if(response.statusCode === 404){
                toast.error(response?.message ? response?.message : "Ward not found or device not linked!")
                dispatch(slice.actions.setApprove({ ...initialState.approve }));
                return
            }
            toast.success(response?.message ? response?.message : "Ward approved successfully!")
            dispatch(slice.actions.setApprove(response));
        })
        .catch(function (errResponse) {
            toast.error(errResponse?.message ? errResponse?.message : "Something went wrong!")
            dispatch(slice.actions.setApprove({ ...errResponse, status: "failed" }))
        });
};

export const rejectWardWithRemark = (data) => async (dispatch) => {
    dispatch(slice.actions.setReject({ ...initialState.reject, status: "loading" }));

    post(API.base_url + "phed/reject-ward-with-remark", { ...data })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            if(response.statusCode === 404){
                toast.error(response?.message ? response?.message : "Ward not found or device not linked!")
                dispatch(slice.actions.setReject({ ...initialState.reject }));
                return
            }
            toast.success(response?.message ? response?.message : "Ward rejected successfully!")
            dispatch(slice.actions.setReject(response));
        })
        .catch(function (errResponse) {
            toast.error(errResponse?.message ? errResponse?.message : "Something went wrong!")
            dispatch(slice.actions.setReject({ ...errResponse, status: "failed" }))
        });
};


export const resetInstallationState = () => async (dispatch) => {
    dispatch(slice.actions.resetAll({ ...initialState.reject }));
}

export default slice;