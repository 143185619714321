import { Box, Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import PageHeading from "../../../../components/PageHeading";
import {
  priColor,
  surveyDataKeys,
  title,
} from "../../../../constants";
import useSettings from "../../../../hooks/useSettings";
import { useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { getDeviceLastData } from "../../FirmwareTestSteps/_service";
import {
  InstallationStatusColor,
  filterObjectByKeys,
  getDateTime,
  getInstallationDate2,
  getInstallationStatus,
  getSGMStrength,
  hasProperties,
  setSiteSurveyAndDevicePhotos,
} from "../../../../utils/helper";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/Loader/Loader";
import SubmitControl from "./SubmitControl";
import { API } from "../../../../config";
import ShowWhen from "../../../../components/common/ShowWhen";
import ImagesWrapper from "../../../../components/common/ImagesWrapper";
import ComparisonComponent from "../../../../components/common/ComparisonComponent";
import { getArchivedWard } from "../../../../actions/ward";

function TestItem({ data }) {
  const status = data.toLowerCase().includes("pass");
  return (
    <span
      style={{
        marginLeft: 8,
        fontWeight: "bold",
        color: status ? "green" : "red",
      }}
    >
      {data}
    </span>
  );
}

function OtherDetailsItem(props) {
  const status = _.has(props.wardData, `survey_data.${props.item}.status`)
    ? props.wardData.survey_data[props.item]["status"]
      ? props.item == "distribution_line"
        ? "Ok"
        : "Working"
      : props.item == "distribution_line"
        ? "Not Ok"
        : "Not Working"
    : "";
  return (
    <Grid item md={12} style={{ display: "flex", flexDirection: "row" }}>
      <Typography color="textSecondary" variant="subtitle2">
        {props.title}
      </Typography>
      <span
        style={{
          marginLeft: 8,
          fontWeight: "bold",
          color: status == "Working" || status == "Ok" ? "green" : "red",
        }}
      >
        {status}
      </span>
    </Grid>
  );
}

const getLastTelemetryData = (data) => {
  const token = window.localStorage.getItem("accessToken");
  const options = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  };
  return new Promise((resolve, reject) => {
    fetch(
      API.base_url + `phed/debugging/get-one-telementry-prev-to-time`,
      options
    )
      .then((rawData) => rawData.json())
      .then(function (response) {
        response = response || {};
        resolve(response);
      })
      .catch(function (errResponse) {
        reject(new Error("Internal server error"));
      });
  });
};


export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  height: "100%",
  color: theme.palette.text.secondary,
}));

function InstallationReportDeviceInfo() {
  const { approve: approveApiResponse, reject: rejectApiResponse } = useSelector((state) => state.installation);
  const location = useLocation();
  const [surveyImages, setSurveyImages] = useState([]);
  const [deviceImages, setDeviceImages] = useState([]);
  const [archivedWardData, setArchivedWardData] = useState([]);
  const settings = useSettings();
  const [assignmentType, setAssignmentType] = useState("");
  const [wardData, setWardData] = useState({});
  const [gsmStrength, setGSMStrength] = useState("");
  const [heartBeatTime, setHeartBeatTime] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const fetchArchivedWard = async ({ wardId = null }) => {
    try {
      setLoading(true);
      setArchivedWardData([]);
      const archivedWardRes = await getArchivedWard({ wardId });
      if (archivedWardRes?.data?.length > 0) {
        setArchivedWardData(archivedWardRes.data[0])
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const assignmentType = location.state.assignmentType
      ? location.state.assignmentType
      : "-";
    setWardData(location.state.wardData);
    setAssignmentType(assignmentType);

    if (location?.state?.wardData && location?.state?.wardData?.fw_device_id) {
      getDeviceLastData(location?.state?.wardData?.fw_device_id).then((e) => {
        if (e.data && e.data.g) setGSMStrength(getSGMStrength(e.data.g));
      });
    }
    const wdt = location.state.wardData;
    const { siteSurveyImages, device_images } =
      setSiteSurveyAndDevicePhotos(wdt);
    setSurveyImages(siteSurveyImages);
    setDeviceImages(device_images);
  }, [location.state]);

  useEffect(() => {
    if (wardData?.id) {
      fetchArchivedWard({ wardId: wardData.id })
    }
  }, [wardData]);

  useEffect(() => {
    if (approveApiResponse && approveApiResponse.status == "SUCCESS") {
      if (approveApiResponse.data && approveApiResponse.data.filePath) {
        var link = document.createElement("a");
        link.href = approveApiResponse.data.filePath;
        link.target = "_blank";
        link.download = approveApiResponse.data.filename;
        link.dispatchEvent(new MouseEvent("click"));
      }
      navigate(-1);
    }
  }, [approveApiResponse]);

  useEffect(() => {
    if (rejectApiResponse && rejectApiResponse.status == "SUCCESS") {
      navigate(-1);
    }
  }, [rejectApiResponse]);


  useEffect(() => {
    if (wardData.regData?.deviceId) {
      const idate = getInstallationDate2(wardData);
      const dt = new Date(idate).toLocaleString("en-GB");

      getLastTelemetryData({
        deviceid: wardData.regData?.deviceId,
        timestamp: new Date(dt).getTime() / 1000,
      }).then((e) => {
        if (e && e._ts) {
          setGSMStrength(getSGMStrength(e.g));
          let datetime = getDateTime(e._ts);
          setHeartBeatTime(`${datetime.date} ${datetime.time}`);
        }
      });
    }
  }, [wardData]);

  let datTime = { date: "", time: "" };
  if (wardData && wardData.regData) datTime = getDateTime(wardData.regData._ts);


  return (
    <>
      <Helmet>
        <title>{title.label2} | Installation Device Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Installation"}
            prevPath={[
              { name: " Installation Report" },
              { name: "Scheme Installation Approval" },
            ]}
          />
          <Box sx={{ py: 1 }}>
            <Grid container spacing={1}>
              <Grid item md={1}>
                <Typography color="textPrimary" variant="subtitle2">
                  Assignment Type:
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Typography
                  style={{ fontWeight: "500", color: "#777" }}
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {assignmentType}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ py: 1 }}>
            <Grid container spacing={1}>
              <Grid item md={5}>
                <Item>
                  <Grid
                    item
                    md={12}
                    display="flex"
                    flexDirection={"row"}
                    justifyContent="center"
                    marginBottom={5}
                  >
                    <Typography
                      color="textPrimary"
                      variant="subtitle2"
                      style={{ color: priColor }}
                    >
                      Site Survey
                    </Typography>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item md={6}>
                      <Typography color={priColor} variant="subtitle2">
                        Site Details
                      </Typography>

                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              {" "}
                              District:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {wardData.districtName}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Division:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {wardData.division}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Block:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {" "}
                              {wardData.blockName}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Panchayat:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {wardData.panchayatName}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Ward:{" "}
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {" "}
                              {wardData.name}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={6} borderLeft={"1px solid #CCC"}>
                      <Typography color={priColor} variant="subtitle2">
                        Device Details
                      </Typography>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Device Barcode:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.regData ? wardData.regData.barcode : ""}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Date of Installation:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {getInstallationDate2(wardData)}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              SIM Number:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.regData
                                ? wardData.regData.simNumber
                                : ""}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Carrier Name:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.regData
                                ? wardData.regData.carrierName
                                : ""}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={6} borderTop={"1px solid #CCC"}>
                      <Typography color={priColor} variant="subtitle2">
                        TE Details
                      </Typography>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Name:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.installation_user_mapping
                                ? wardData.installation_user_mapping.name
                                : ""}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Mobile Number:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {wardData.installation_user_mapping
                                ? wardData.installation_user_mapping
                                  .mobile_number
                                : "Not Mapped"}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      borderLeft={"1px solid #CCC"}
                      borderTop="1px solid #CCC"
                    >
                      <Typography color={priColor} variant="subtitle2">
                        Anurakshak Details
                      </Typography>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Name:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {_.has(wardData, "survey_data.anurakshak.name")
                                ? wardData.survey_data.anurakshak.name
                                : ""}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Mobile Number:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {_.has(
                                wardData,
                                "survey_data.anurakshak.mobile_number"
                              )
                                ? wardData.survey_data.anurakshak.mobile_number
                                : ""}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item md={12} style={{ borderTop: "1px solid #CCC" }}>
                      <Typography color={priColor} variant="subtitle2">
                        Other Details
                      </Typography>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Site Open :
                            </Typography>
                            <span
                              style={{
                                marginLeft: 8,
                                fontWeight: "bold",
                                color: wardData?.survey_data?.isSiteOpen
                                  ? "green"
                                  : "red",
                              }}
                            >
                              {wardData?.survey_data?.isSiteOpen ? "YES" : "NO"}
                            </span>
                          </Grid>
                          <OtherDetailsItem
                            title="Electricity Meter:"
                            wardData={wardData}
                            item={"electricity_meter"}
                          />
                          <OtherDetailsItem
                            title="Starter Status:"
                            wardData={wardData}
                            item={"starter"}
                          />
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Survey Remark By TE :
                            </Typography>
                            <span
                              style={{
                                marginLeft: 8,
                              }}
                            >
                              {wardData?.survey_data?.surveyRemark
                                ? wardData?.survey_data?.surveyRemark
                                : "-NA-"}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    display="flex"
                    flexDirection={"row"}
                    justifyContent="center"
                    style={{ borderTop: "1px solid #CCC" }}
                  >
                    <Typography color={priColor} variant="h6">
                      Testing Details
                    </Typography>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <Box sx={{ py: 2 }}>
                        <Grid container>
                          {/* <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Device Barcode:
                            </Typography>
                            <span style={{ marginLeft: 8 }}>
                              {_.has(wardData, "regData.barcode")
                                ? wardData.regData.barcode
                                : ""}
                            </span>
                          </Grid> */}
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Power On Test:
                            </Typography>
                            <TestItem
                              data={
                                _.has(
                                  wardData,
                                  "regData.installation_testing_status.power_status"
                                )
                                  ? wardData.regData.installation_testing_status
                                    .power_status
                                  : "Not Found"
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Network Status:
                            </Typography>{" "}
                            <span style={{ marginLeft: 8 }}>
                              {" "}
                              {gsmStrength} (last received)
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Device Heartbeat Details:
                            </Typography>{" "}
                            <span style={{ marginLeft: 10 }}>
                              {heartBeatTime ? heartBeatTime : " -NA-"}
                            </span>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Motor On Test:
                            </Typography>{" "}
                            <TestItem
                              data={
                                _.has(
                                  wardData,
                                  "regData.installation_testing_status.motor_on_status"
                                )
                                  ? wardData.regData.installation_testing_status
                                    .motor_on_status
                                  : "Not Found"
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            md={12}
                            marginTop={3}
                            marginBottom={3}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Typography color={priColor} variant="h5">
                              Test result:
                            </Typography>
                            <Typography
                              color={
                                _.has(
                                  wardData,
                                  "regData.installation_testing_status.overall"
                                )
                                  ? wardData.regData.installation_testing_status
                                    .overall == "pass"
                                    ? "green"
                                    : "red"
                                  : ""
                              }
                              variant="h5"
                              marginLeft={3}
                            >
                              {_.has(
                                wardData,
                                "regData.installation_testing_status.overall"
                              )
                                ? wardData.regData.installation_testing_status.overall.toUpperCase()
                                : "Not Found"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              color="textSecondary"
                              variant="subtitle2"
                            >
                              Status:
                            </Typography>{" "}
                            <span
                              style={{
                                marginLeft: 8,
                                fontWeight: "bold",
                                color: InstallationStatusColor(
                                  getInstallationStatus(wardData)
                                ),
                              }}
                            >
                              {getInstallationStatus(wardData)}
                            </span>
                            {getInstallationStatus(wardData) === "Approved" && (
                              <>
                                {wardData.verification &&
                                  wardData.verification.report_url ? (
                                  <a
                                    href={wardData.verification.report_url}
                                    target="_blank"
                                    style={{ marginLeft: 20 }}
                                    rel="noreferrer"
                                  >
                                    View Report
                                  </a>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>

              <Grid item md={7}>
                <Item>
                  <ShowWhen
                    when={surveyImages?.length > 0}
                    component={
                      <ImagesWrapper
                        images={surveyImages}
                        title={"Site Survey Photos"}
                      />
                    }
                  />
                  <ShowWhen
                    when={deviceImages?.length > 0}
                    component={
                      <ImagesWrapper
                        images={deviceImages}
                        title={"Device Installation & Testing"}
                        mt={3}
                      />
                    }
                  />
                  {/* <ShowWhen when={archivedSurveyImages?.length > 0 } component={<ImagesWrapper images={archivedSurveyImages} title={"Previous Site Survey Photos"} />} />
                  <ShowWhen when={archivedDeviceImages?.length > 0 } component={<ImagesWrapper images={archivedDeviceImages} title={"Previous Device Installation & Testing"} />} /> */}

                  {getInstallationStatus(wardData) == "Pending For Approval" &&
                    approveApiResponse?.statusCode !== 200 &&
                    rejectApiResponse?.statusCode !== 200 && (
                      <SubmitControl wardData={wardData} />
                    )}
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <ShowWhen
        when={loading}
        component={<Loader />}
      />

      {/* /////////////////////////////////////////////////////////////////////////////////////////////// */}

      <Container>
        <ShowWhen
          when={archivedWardData && hasProperties(archivedWardData)}
          component={
            <ComparisonComponent
              archived={archivedWardData}
              current={wardData}
              setLoading={setLoading}
              loading={loading}
            />
          }
        />
      </Container>

      {/* /////////////////////////////////////////////////////////////////////////////////////////////// */}
    </>
  );
}

export default InstallationReportDeviceInfo;
