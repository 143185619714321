import React from 'react';

const ShowWhen = ({ when = false, key = 1, component = null, otherwise = null }) => {
    return (
        <div key={key}>
            {when ? component : otherwise}
        </div>
    );
};

export default ShowWhen;
