import { Button, Card } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import UpdateNewDeviceUI from "./UpdateNewDeviceUI";
import {
  deleteDevice,
  resetDeviceModificationData,
  updateDevice,
} from "../../../slices/deviceUpdate";
import { useSelector } from "react-redux";
import ShowWhen from "../../common/ShowWhen";
import { Loader } from "../../Loader/Loader";
import toast from "react-hot-toast";
import CustomToolbar from "../../common/CustomToolbar";
import { getStatusColor, getStatusText } from "../../../utils/helper";

const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});

const DeviceList = (props) => {
  const dispatch = useDispatch();
  const { deviceModificationData } = useSelector((state) => state.deviceUpdate);
  const classes = useStyles();

  const [showUpdateAction, setShowUpdateAction] = useState(false);
  const [currentUpdateDevice, setCurrentUpdateDevice] = useState(null);
  const [tableData, setTableData] = useState([]);

  const handleClick = (d) => {
    setCurrentUpdateDevice(d);
    setShowUpdateAction(true);
  };

  const handleDeleteClick = (d) => {
    const confirmRes = window.confirm("Are you sure to delete this?");
    if (confirmRes && d["id"]) {
      dispatch(deleteDevice(d["id"], { tag: "delete" }));
    }
  };

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      props.schemes.map((page, i) => {
        tableDataTemp.push({
          ...page,
          action: page,
          sn: i + 1,
          ...page?.survey_data,
          anurakshak_name:
            page?.survey_data?.anurakshak?.name ||
            page?.other?.op_name ||
            "N/A",
          anurakshak_mobile:
            page?.survey_data?.anurakshak?.mobile_number ||
            page?.other?.op_number ||
            "N/A",
          barcode: page?.regData?.barcode ? page?.regData?.barcode : "-NA-",
          installation_status: getStatusText(page),
        });
      });
    }

    setTableData(tableDataTemp);
  }, [props.schemes]);

  const columns = [
    {
      field: "sn",
      headerName: "Sl No",
      width: 130,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      headerName: "Ward Id",
      width: 130,
      sortable: false,
      hide: true,
    },
    {
      field: "districtName",
      headerName: "District",
      width: 130,
      sortable: false,
      hide: true,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: false,
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Ward",
      width: 130,
      sortable: false,
    },
    {
      field: "barcode",
      headerName: "Device Barcode",
      width: 130,
      sortable: false,
      hide: true,
    },
    {
      field: "installation_status",
      headerName: "Installation Status",
      width: 180,
      sortable: false,
      hide: true,
      valueFormatter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return (
          <span style={{ color: getStatusColor(params.value) }}>
            {params.value}
          </span>
        );
      },
    },
    {
      field: "subScheme",
      headerName: "Scheme Type",
      width: 130,
      sortable: false,
    },
    {
      field: "schemeId",
      headerName: "Scheme Id",
      width: 130,
      sortable: false,
    },
    {
      field: "action",
      sortable: false,
      disableExport: true,
      width: 130,
      headerName: "Action",
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleClick(params["formattedValue"])}>
              Edit
            </Button>
            <Button onClick={() => handleDeleteClick(params["formattedValue"])}>
              Delete
            </Button>
          </>
        );
      },
    },
    {
      headerName: "SMS ID",
      field: "smsid",
      hide: true,
    },
    {
      field: "anurakshak_name",
      headerName: "Anurakshak Name",
      width: 140,
      hide: true,
    },
    {
      field: "anurakshak_mobile",
      headerName: "Anurakshak Mobile",
      width: 130,
      hide: true,
    },
    // {
    //     headerName: 'Device Id',
    //     field: "did",
    //     hide: true
    // },
    // {
    //     headerName: 'NICID',
    //     field: "nicId",
    //     hide: true
    // }
  ];

  const onUpdateDevice = (data) => {
    dispatch(updateDevice(data));
    dispatch(resetDeviceModificationData());
  };

  useEffect(() => {
    if (
      deviceModificationData?.statusCode === 200 ||
      deviceModificationData?.statusCode === "200"
    ) {
      setShowUpdateAction(false);
      toast.success(
        deviceModificationData?.message || "Device updated successfully"
      );
    } else if (
      deviceModificationData?.statusCode === 404 ||
      deviceModificationData?.statusCode === "404"
    ) {
      toast.error(deviceModificationData?.message || "Device not found!");
    } else if (
      deviceModificationData?.statusCode === 500 ||
      deviceModificationData?.statusCode === "500"
    ) {
      toast.error(
        deviceModificationData?.message ||
          "Error occurred while updating the Device!"
      );
    }
    dispatch(resetDeviceModificationData());
  }, [deviceModificationData, dispatch]);

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              title: props.title,
              fileName: props.fileName,
              locationName: props.distName,
            },
          }}
        />
      </div>

      <UpdateNewDeviceUI
        update={onUpdateDevice}
        setOpenStatus={(stat) => setShowUpdateAction(stat)}
        openStatus={showUpdateAction}
        deviceDatails={currentUpdateDevice}
      />

      <ShowWhen
        when={deviceModificationData?.status === "loading"}
        component={<Loader />}
      />
    </Card>
  );
};

export default DeviceList;
