import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import MenuIcon from "../../icons/Menu";
import AccountPopover from "./AccountPopover";
import BrandBanner from "../BrandBanner";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none",
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar
        sx={{
          minHeight: 64,
          backgroundColor: "#FFF",
          borderBottom: "1px solid #CCC",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          color="invert"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
            p: 0,
            m: 0,
            mr: 2,
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <RouterLink to="/" style={{ textDecoration: "none", color: "#CCC" }}>
            <Box
              display="flex"
              flexDirection="row"
              sx={{ mt: 0.5, alignItems: "center" }}
            >
              <BrandBanner />
            </Box>
          </RouterLink>

          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
