import { Button, Card, Typography } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDateTime } from "../../../utils/helper";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { makeStyles } from "@material-ui/styles";
import { priColor } from "../../../constants";
import { useNavigate } from "react-router-dom";
import UpdateActionModal from "./UpdateActionModal";
import { getMaintenanceHistory, updateActionForIssue } from "../../../slices/maintenance";
import { Loader } from "../../Loader/Loader";
import MaintenanceHistory from "./MaintenanceHistory";

const useStyles = makeStyles({
    root: {
        "& .scheme-data-table": {
            fontWeight: "bold",
        },
    },
});

function CustomToolbar(props) {
    return (
        <GridToolbarContainer
            style={{
                padding: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <Typography color="textPrimary" variant="h6">
                Total No. of Non-Functional IoT devices in the past{" "}
                <span style={{ color: priColor }}>{props.dateRange}</span> days is{" "}
                <span style={{ color: priColor }}>{props.count}</span>
            </Typography>
            <GridToolbarExport csvOptions={{ fileName:  `NON_FUNCTIONAL_REPORT_LAST_${props.dateRange}_DAYS`}} />
        </GridToolbarContainer>
    );
}

const MaintenanceSchemeList = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    const [showUpdateAction, setShowUpdateAction] = useState(false);
    const [currentUpdateDevice, setCurrentUdpateDevice] = useState(null);
    const { updateActionForIssue: updateAPIAction, maintenanceHistory: mHistory } = useSelector((state) => state.maintenance);

    const [currentHistoryDevice, setCurrentHistoryDevice] = useState(null);
    const [showHistory, setShowHistory] = useState(false);
    const [mHistoryState, setMHistoryState] = useState(false);

    const handleClick = (d) => {
        setCurrentUdpateDevice(d);
        setShowUpdateAction(true);
    };

    const handleHistory = (d) => {
        setCurrentHistoryDevice(d);
        setShowHistory(true);
        if(d["smsid"])
            dispatch(getMaintenanceHistory(d["smsid"]));
        else 
            alert("Device id is not valid!!")
    };

    useEffect(() => {
        let tableDataTemp = [];
        if (props.schemes && props.schemes.length) {
            tableDataTemp = props.schemes.map((page, i) => {
                return {
                    ...page,
                    did: page.other.deviceid,
                    wname: page.name,
                    datastamp: getDateTime(page.manualGeneratedTS),
                    action: page,
                    id: i + 1,
                };
            });
        }

        setTableData(tableDataTemp);
    }, [props.schemes]);

    const columns = [
        {
            field: "id",
            headerName: "Sl No",
            width: 130,
            sortable: false,
            headerClassName: "scheme-data-table",
        },
        {
            field: "districtName",
            headerName: "District",
            width: 130,
            sortable: false,
        },
        {
            field: "blockName",
            headerName: "Block",
            width: 130,
            sortable: false,
        },
        {
            field: "panchayatName",
            headerName: "Panchayat",
            width: 130,
            sortable: false,
        },
        {
            field: "wname",
            headerName: "Ward",
            width: 130,
            sortable: false,
        },
        {
            field: "datastamp",
            sortable: false,
            headerName: "Data Stamp",
            width: 180,
            valueFormatter: ({ value }) => value["date"] + " " + value["time"],
            renderCell: (params) => {
                const data = params["formattedValue"];
                const dataToDisplay = data.split(/(?<=^\S+)\s/);

                return (
                    <div style={{ lineHeight: 1 }}>
                        <span style={{ fontSize: 10 }}>{dataToDisplay[0]}</span> <br />
                        <span>{dataToDisplay[1]}</span>
                    </div>
                );
            },
        },
        {
            field: "action",
            sortable: false,
            disableExport: true,
            width: 230,
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Button onClick={() => handleClick(params["formattedValue"])}>
                            Update action
                        </Button>
                        <Button onClick={() => handleHistory(params["formattedValue"])}>
                            History
                        </Button>
                    </Fragment>
                );
            },
        },
        // {
        //     field: "issue",
        //     headerName: "Issue",
        //     width: 130,
        //     sortable: false,
        // },
        // {
        //     field: "remarks",
        //     headerName: "Remarks (Corrective Action Taken)",
        //     width: 210,
        //     sortable: false,
        // },
    ];

    const onUpdateActionForIssue = (data) => {
        dispatch(updateActionForIssue(data));
    };

    useEffect(() => {
        if (updateAPIAction["status"] == "SUCCESS") {
            setShowUpdateAction(false);
        } else if (updateAPIAction["status"] == "failed") {
            alert("Something went wrong!!");
        }
    }, [updateAPIAction]);
    
    useEffect(() => {
        if (mHistory["status"] == "SUCCESS") {
            setMHistoryState(mHistory["data"]);
        } else if (mHistory["status"] == "failed") {
            alert("Something went wrong!!");
        }
    }, [mHistory]);

    return (
        <Card>
            <div style={{ height: 800, width: "100%" }} className={classes.root}>
                <DataGrid
                    rows={tableData}
                    columns={columns}
                    pageSize={40}
                    rowsPerPageOptions={[5]}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        toolbar: { dateRange: props.range, count: tableData.length },
                    }}
                />
            </div>
            <UpdateActionModal
                updateActionForIssue={onUpdateActionForIssue}
                setOpenStatus={(stat) => setShowUpdateAction(stat)}
                openStatus={showUpdateAction}
                deviceDatails={currentUpdateDevice}
            />
            <MaintenanceHistory
                setOpenStatus={(stat) => setShowHistory(stat)}
                openStatus={showHistory}
                historyData={mHistoryState}
                deviceDatails={currentHistoryDevice}
            />
            {updateAPIAction["status"] == "loading" ? <Loader /> : null}
        </Card>
    );
};

export default MaintenanceSchemeList;
