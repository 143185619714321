import { Box, Container, CardHeader } from "@material-ui/core"
import React from "react"
import { Helmet } from "react-helmet-async"
import useSettings from "../../hooks/useSettings";

const MapTrackerIssueMap = (props) => {

    const { settings } = useSettings();
    return (
        <>
            <Helmet>
                <title></title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <CardHeader title="* Work in progress" />
                    IssueMap
                </Container>
            </Box>
        </>
    )
}

export default MapTrackerIssueMap