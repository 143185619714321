import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Grid,
  Typography,
  Container,
  Breadcrumbs,
  Link,
  Button
} from '@material-ui/core';
import ChevronRightIcon from '../../../icons/ChevronRight';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const IssueLogUC = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <>
      <Helmet>
        <title>Issue Log | Har Ghar Nal Jal Yojna</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 2
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Field Issue log
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/overview"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Link
                  color="textPrimary"
                  to="/overview/schemes"
                  component={RouterLink}
                  variant="subtitle2"
                >
                  Scheme Details
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Field Issue Log
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Typography
            align="center"
            color="textPrimary"
            variant={'h4'}
          >
            Field Issue log page changes in progress
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Box
              alt="Under development"
              component="img"
              src={`/static/error/error404_${theme.palette.mode}.svg`}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 300
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default IssueLogUC;
