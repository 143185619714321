import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get, post } from './service';

const initialState = {
    lastMotorOn: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'lastMotorOn',
    initialState,
    reducers: {
        setlastMotorOn(state, action) {
            state.lastMotorOn = action.payload
        }
    }
});

export const { reducer } = slice;

export const getLastMotorOn = (did, dateRange, type) => async (dispatch) => {
    if(type && type != "refresh")
        dispatch(slice.actions.setlastMotorOn({ ...initialState.scheduled, status: "loading" }));

    post(API.base_url + "phed/device_last_motor_on", { did, dateRange })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setlastMotorOn(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setlastMotorOn({ ...errResponse, status: "failed" }))
        });
};

export default slice;