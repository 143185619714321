import PropTypes from "prop-types"
import React, { Fragment } from "react"
import "./loader.css"
export const Loader = ({ text = null }) => {
    return (
        <Fragment>
            <div className="newLoading">
                {text ? (
                    <span className="loader_msg">
                        {text}
                    </span>
                ) : null}
            </div>
        </Fragment>
    )
}

Loader.propTypes = {
    text: PropTypes.string,
}
