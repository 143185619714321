import {
    Box, Card, CardHeader, CardContent, Breadcrumbs, Button, Container, Grid, Link, Typography,
    TextField
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';
import { ElectricityAndMonitoring, SchemeHealth, SchemeIssues } from '../../components/dashboard/schemedashboard';
import { useSelector, useDispatch } from 'react-redux';
import { resetUploadStatus, uploadCSV } from '../../slices/uploadCSV';
import { useEffect, useState } from 'react';
import { getBlock, getDistrict, getDivision, getPanchayat, getWard } from '../../slices/siteInfo';
import { Loader } from '../../components/Loader/Loader';
import UploadSchemeImages from '../../components/dashboard/admin/UploadSchemeImages';
import UploadGIS from '../../components/dashboard/admin/UploadGIS';
import { checkIfRoleExist } from '../../utils/helper';
// import TriggerDeviceEvent from '../../components/trigger_device_event/TriggerDeviceEvent';

const Admin = () => {
    const { settings } = useSettings();
    const dispatch = useDispatch();

    const [allDist, setAllDist] = useState([])
    const [allBlock, setAllBlock] = useState([])
    const [allPanchayat, setAllPanchayat] = useState([])
    const [allWard, setAllWard] = useState([])
    const [allDivision, setAllDivision] = useState([])
    const [calledUpload, setCalledUpload] = useState(false)

    const { district, block, panchayat, ward, division} = useSelector((state) => state.siteinfo);
    const { uploadCSV: uploadCSVStatus } = useSelector((state) => state.uploadCSV);
    const [currentDistrict, setCurrentDistrict] = useState(0)
    const [currentDivision, setCurrentDivision] = useState(0)

    useEffect(() => {
        if (uploadCSVStatus.status == "success") {
            alert("Success. It takes some time to reflect in UI. As it is processsing CSV.")
            dispatch(resetUploadStatus())
        }
    }, [uploadCSVStatus.status])

    const onChange = function (e) {
        if(!calledUpload){
        if (currentDistrict > 0) {
            const file = e.target.files[0]
            if (file && ( file['type'] == "text/csv" || file["type"] == "application/vnd.ms-excel")) {
                const cDistObj = allDist.filter((e) => e.id == currentDistrict)
                const cDivObj = allDivision.filter((e) => e.id == currentDivision)
                if (cDistObj.length) {
                    dispatch(uploadCSV(e.target.files[0], currentDistrict, cDistObj[0]["name"], currentDivision, cDivObj[0]["name"]))
                } else
                    alert("cDistObj length 0")
            } else {
                alert("Please select CSV file")
            }
        } else {
            alert("Please select district")
        }
    }else{
        setCalledUpload(true)
    }
    };

    const handleDistrictChange = function (e) {
        setCurrentDistrict(e.target.value)
        dispatch(getDivision(e.target.value))
    }

    const handleDivisionChange = function (e) {
        setCurrentDivision(e.target.value)
    }

    useEffect(() => {
        dispatch(getDistrict())
    }, [])

    useEffect(() => {
        if (district.data)
            setAllDist(district.data)
    }, [district])

    useEffect(() => {
        if (division.data)
            setAllDivision(division.data)
    }, [division])

    useEffect(() => {
        if (block.data)
            setAllBlock(block.data)
    }, [block])

    // useEffect(() => {
    //     if (block.data)
    //         setAllBlock(block.data)
    // }, [block])

    useEffect(() => {
        if (panchayat.data)
            setAllPanchayat(panchayat.data)
    }, [panchayat])

    useEffect(() => {
        if (ward.data)
            setAllWard(ward.data)
    }, [ward])

    return (
        <>
            <Helmet>
                <title>Admin Dashboard | Har Ghar Nal Jal Yojna</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Admin Dashboard
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Admin Dashboard
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <Card>
                                    <CardHeader title="Upload file (CSV)" />
                                    <CardContent>
                                        <span style={{ color: "red" }}>{!checkIfRoleExist(localStorage.getItem("USER_TYPE"), ["APP_SUPER_ADMIN"]) ? "Only Admin user can upload ward details" : ""}</span>
                                        <br /> <br />
                                        <Grid container spacing={3}>
                                            <Grid item md={2}>
                                                <Grid container spacing={3}>
                                                    <Grid item >
                                                        <TextField
                                                            fullWidth
                                                            label="District"
                                                            name="district"
                                                            onChange={handleDistrictChange}
                                                            select
                                                            disabled={!checkIfRoleExist(localStorage.getItem("USER_TYPE"), ["APP_SUPER_ADMIN"])}
                                                            SelectProps={{ native: true }}
                                                            value={currentDistrict.id}
                                                            variant="outlined"
                                                        >
                                                            {[{ id: 0, name: "" }, ...allDist].map((category) => (
                                                                <option
                                                                    key={category.id}
                                                                    value={category.id}
                                                                >
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            fullWidth
                                                            label="Division"
                                                            name="division"
                                                            onChange={handleDivisionChange}
                                                            select
                                                            disabled={!checkIfRoleExist(localStorage.getItem("USER_TYPE"), ["APP_SUPER_ADMIN"])}
                                                            SelectProps={{ native: true }}
                                                            value={currentDivision.id}
                                                            variant="outlined"
                                                        >
                                                            {[{ id: 0, name: "" }, ...allDivision].map((category) => (
                                                                <option
                                                                    key={category.id}
                                                                    value={category.id}
                                                                >
                                                                    {category.name}
                                                                </option>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            disabled={!checkIfRoleExist(localStorage.getItem("USER_TYPE"), ["APP_SUPER_ADMIN"])}>
                                                            Choose CSV File
                                                            <input
                                                                type="file"
                                                                onChange={onChange}
                                                                hidden
                                                            />
                                                        </Button>
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        {uploadCSVStatus.status && uploadCSVStatus.status != "failed" ? uploadCSVStatus.status : ""}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={12} md={8} lg={10}>
                                                <table width="100%" border={1} cellSpacing={0} style={{ fontSize: 12 }}>
                                                    <tr>
                                                        <th>Dist name</th>
                                                        <th>Last imported on</th>
                                                        <th>Import status</th>
                                                    </tr>
                                                    {allDist.map((ad) => {
                                                        return <tr>
                                                            <td>{ad.name}</td>
                                                            <td>2 days ago</td>
                                                            <td>Completed</td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={12}>
                                <Card>
                                    <CardHeader title="Upload Scheme Images" />
                                    <CardContent>
                                        <UploadSchemeImages allDist={allDist} allDivision={allDivision} allBlock={allBlock} allPanchayat={allPanchayat} allWard={allWard} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            {/* <Grid item md={12}>
                                <Card>
                                    <CardHeader title="Upload Scheme Completion Drawing  (WIP)" />
                                    <CardContent>
                                         <UploadGIS /> 
                                    </CardContent>
                                </Card>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Container>
            </Box>
            {uploadCSVStatus.status == "loading" && <Loader />}
        </>
    )
}

export default Admin