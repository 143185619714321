import { Button, Grid, ImageList, ImageListItem, ImageListItemBar, TextField, Typography } from "@material-ui/core"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getBlock, getDistrict, getDivision, getPanchayat, getWard } from '../../../slices/siteInfo';
import { getWardImages, resetUploadImageStatus, uploadWardGIS, uploadWardImages, uploadWardPDF } from "../../../slices/uploadWardFiles";
import { Loader } from '../../Loader/Loader';

const UploadSchemeImages = function (props) {
    const dispatch = useDispatch();
    const [schemeDist, setSchemeDist] = useState(0)
    const [schemeBlock, setSchemeBlock] = useState(0)
    const [schemePanchayat, setSchemePanchayat] = useState(0)
    const [schemeWard, setSchemeWard] = useState(0)
    const [imageTitle, setImageTitle] = useState("")
    const [selectedDeviceId, setSelectedDeviceId] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedGISFile, setSelectedGISFile] = useState(null)
    const [selectedPDFFile, setSelectedPDFFile] = useState(null)
    // const [allDivision, setAllDivision] = useState([])
    const { uploadWardImage: upload_ward_image, wardImages: ward_images } = useSelector((state) => state.uploadWardImage);
    const { district, block, panchayat, ward, division } = useSelector((state) => state.siteinfo);

    const [allDist, setAllDist] = useState([])
    const [allBlock, setAllBlock] = useState([])
    const [allPanchayat, setAllPanchayat] = useState([])
    const [allWard, setAllWard] = useState([])
    const [allDivision, setAllDivision] = useState([])
    const [currentDivision, setCurrentDivision] = useState(0)

    useEffect(() => {
        dispatch(getDistrict())
    }, [])

    useEffect(() => {
        if (district.data)
            setAllDist(district.data)
    }, [district])

    useEffect(() => {
        if (division.data)
            setAllDivision(division.data)
    }, [division])

    useEffect(() => {
        if (block.data)
            setAllBlock(block.data)
    }, [block])

    // useEffect(() => {
    //     if (block.data)
    //         setAllBlock(block.data)
    // }, [block])

    useEffect(() => {
        if (panchayat.data)
            setAllPanchayat(panchayat.data)
    }, [panchayat])

    useEffect(() => {
        if (ward.data)
            setAllWard(ward.data)
    }, [ward])

    const handleSchemeDistChange = function (e) {
        setSchemeDist(e.target.value)
        dispatch(getDivision(e.target.value))
        dispatch(resetUploadImageStatus())
    }

    const handleDivisionChange = function (e) {
        setCurrentDivision(e.target.value)
        dispatch(getBlock(e.target.value))
    }

    useEffect(() => {
        if (upload_ward_image["status"] == "SUCCESS")
            setSelectedFile(null)
    }, [upload_ward_image])

    useEffect(() => {
        // if (ward_images["status"] == "SUCCESS")
        //     setSelectedFile(null)
    }, [ward_images])

    const handleSchemeBlockChange = function (e) {
        setSchemeBlock(e.target.value)
        dispatch(getPanchayat(e.target.value))
        dispatch(resetUploadImageStatus())
    }

    const handleSchemePanchayatChange = function (e) {
        setSchemePanchayat(e.target.value)
        dispatch(getWard(e.target.value))
        dispatch(resetUploadImageStatus())
    }

    const handleSchemeWardChange = function (e) {
        const wardSelected = allWard.filter(e1 => e1.id == e.target.value)
        if (wardSelected.length) {
            if (wardSelected[0]["smsid"]) {
                setSelectedDeviceId(wardSelected[0]["smsid"])
                dispatch(getWardImages(wardSelected[0]["smsid"]))
            }
        } else {
            setSelectedDeviceId(null)
        }
        setSchemeWard(e.target.value)
        dispatch(resetUploadImageStatus())
    }

    const onChange = async function (e) {
        if (schemeWard != 0 && imageTitle != "") {
            const file = e.target.files[0]
            if (file && file['type'].includes("image")) {
                setSelectedFile(file)
            } else {
                alert("Please select image file")
            }
        } else {
            alert("Please select all fields")
        }
    };

    const onChangeGIS = async function (e) {
        if (schemeWard != 0) {
            const file = e.target.files[0]
            if (file && (file['name'].includes("kml") || file['type'].includes("kml"))) {
                setSelectedGISFile(file)
            } else {
                alert("Please select KML file")
            }
        } else {
            alert("Please select all fields")
        }
    }

    const onChangePDF = async function (e) {
        if (schemeWard != 0) {
            const file = e.target.files[0]
            if (file && file['type'].includes("pdf")) {
                setSelectedPDFFile(file)
            } else {
                alert("Please select PDF file")
            }
        } else {
            alert("Please select all fields")
        }
    }

    const uploadSelectedFile = () => {
        if (selectedDeviceId && imageTitle != "" && selectedFile)
            dispatch(uploadWardImages(selectedFile, selectedDeviceId, imageTitle))
        else {
            alert("Please fill required fields")
        }
    }

    const uploadSelectedGISFile = () => {
        if (selectedDeviceId && selectedGISFile) {
            dispatch(uploadWardGIS(selectedGISFile, selectedDeviceId))
            setSelectedGISFile(false)
        } else {
            alert("Please fill required fields")
        }
    }

    const uploadSelectedPDFFile = () => {
        if (selectedDeviceId && selectedPDFFile) {
            dispatch(uploadWardPDF(selectedPDFFile, selectedDeviceId))
            setSelectedPDFFile(false)
        } else {
            alert("Please fill required fields")
        }
    }

    const handleImageTitleChange = (e) => {
        setImageTitle(e.target.value)
        dispatch(resetUploadImageStatus())
    }

    return <Fragment>
        <Grid container spacing={3}>
            <Grid item md={2}>
                <TextField
                    fullWidth
                    label="District"
                    name="district"
                    onChange={handleSchemeDistChange}
                    select
                    SelectProps={{ native: true }}
                    value={schemeDist}
                    variant="outlined"
                >
                    {[{ id: 0, name: "" }, ...allDist].map((category) => (
                        <option
                            key={category.id}
                            value={category.id}
                        >
                            {category.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item>
                <TextField
                    fullWidth
                    label="Division"
                    name="division"
                    onChange={handleDivisionChange}
                    select
                    SelectProps={{ native: true }}
                    value={currentDivision.id}
                    variant="outlined"
                >
                    {[{ id: 0, name: "" }, ...allDivision].map((category) => (
                        <option
                            key={category.id}
                            value={category.id}
                        >
                            {category.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item md={2}>
                <TextField
                    fullWidth
                    label="Block"
                    name="block"
                    onChange={handleSchemeBlockChange}
                    select
                    SelectProps={{ native: true }}
                    value={schemeBlock}
                    variant="outlined"
                >
                    {[{ id: 0, name: "" }, ...allBlock].map((category) => (
                        <option
                            key={category.id}
                            value={category.id}
                        >
                            {category.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item md={2}>
                <TextField
                    label="Panchayat"
                    name="panchayat"
                    fullWidth
                    onChange={handleSchemePanchayatChange}
                    select
                    SelectProps={{ native: true }}
                    value={schemePanchayat}
                    variant="outlined"
                >
                    {[{ id: 0, name: "" }, ...allPanchayat].map((category) => (
                        <option
                            key={category.id}
                            value={category.id}
                        >
                            {category.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item md={2}>
                <TextField
                    label="Ward"
                    name="ward"
                    fullWidth
                    onChange={handleSchemeWardChange}
                    select
                    SelectProps={{ native: true }}
                    value={schemeWard}
                    variant="outlined"
                >
                    {[{ id: 0, name: "" }, ...allWard].map((category) => (
                        <option
                            key={category.id}
                            value={category.id}
                        >
                            {category.name}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item md={2}>
                <TextField
                    label="Image Title"
                    name="imageTitle"
                    fullWidth
                    onChange={handleImageTitleChange}
                    select
                    SelectProps={{ native: true }}
                    value={imageTitle}
                    variant="outlined"
                >
                    {["", "Starter", "Meter", "Borewell", "Elect. Meter", "Site", "Dist. Line", "IoT Device", "Barcode", "Anurakshak"].map((category) => (
                        <option
                            key={category}
                            value={category}
                        >
                            {category}
                        </option>
                    ))}
                </TextField>
            </Grid>
            <Grid item md={12}>
                <Button
                    variant="contained"
                    component="label"
                    disabled={!selectedDeviceId || imageTitle == "" || selectedFile}
                >
                    {selectedFile ? "You have Selected one file" : "Select Images"}
                    <input
                        type="file"
                        onChange={onChange}
                        hidden
                    />
                </Button>
                {selectedFile &&
                    <Button
                        variant="contained" style={{ marginLeft: 20 }} onClick={() => uploadSelectedFile()}>Upload</Button>}
            </Grid>

            <Fragment>
                <Grid item md={12}>
                    <Button
                        variant="contained"
                        component="label"
                        disabled={!selectedDeviceId || selectedGISFile}
                    >
                        {selectedGISFile ? "You have Selected one file" : "Select GIS File"}
                        <input
                            type="file"
                            onChange={onChangeGIS}
                            hidden
                        />
                    </Button>
                    {selectedGISFile &&
                        <Button
                            variant="contained" style={{ marginLeft: 20 }} onClick={() => uploadSelectedGISFile()}>Upload</Button>}
                </Grid>
                <Grid item md={12}>
                    <Button
                        variant="contained"
                        component="label"
                        disabled={!selectedDeviceId || selectedPDFFile}
                    >
                        {selectedPDFFile ? "You have Selected PDF file" : "Select Consent PDF File"}
                        <input
                            type="file"
                            onChange={onChangePDF}
                            hidden
                        />
                    </Button>
                    {selectedPDFFile &&
                        <Button variant="contained" style={{ marginLeft: 20 }} onClick={() => uploadSelectedPDFFile()}>Upload</Button>}
                </Grid>

            </Fragment>
            <Grid item md={12}>
                {upload_ward_image["status"] == "SUCCESS" && <Typography color="textSecondary">{upload_ward_image["message"]}</Typography>}
                {upload_ward_image["status"] == "failed" && <Typography color="textSecondary">{"Something Went Wrong!!"}</Typography>}
            </Grid>
            {upload_ward_image["status"] == "loading" && <Loader />}
            {ward_images["status"] == "loading" && <Loader />}

            {ward_images["data"] && ward_images["data"].length ?
                <Grid item md={12}>
                    <ImageList rowHeight={160} cols={6}>
                        {ward_images["data"].map((item, i) => (
                            <ImageListItem width={"100%"} height={"100%"} key={item.title} cols={i || 1}>
                                <img src={item.imgURL} width={"100%"} height={"100%"} alt={item.title} />
                                <ImageListItemBar
                                    title={item.title}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>

                </Grid> : null}
        </Grid>
    </Fragment>
}

export default UploadSchemeImages