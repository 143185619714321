import { Typography } from "@material-ui/core";
import { priColor, title } from "../constants";
import logo from "../images/logo.png";

const BrandBanner = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <img src={logo} width={45} height={55} alt="RollZ Logo" />
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          marginLeft: 10,
        }}
      >
        <Typography variant="caption">
          Powered By <span style={{ color: priColor }}>{title.label1}</span>
        </Typography>
        <Typography color="textPrimary" variant="h6">
          {title.label2}
        </Typography>
      </div>
    </div>
  );
};

export default BrandBanner;
