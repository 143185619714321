import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { getDateTime } from "../../../utils/helper";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { ISSUE_TYPES, priColor } from "../../../constants";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const issueTarget = Object.keys(ISSUE_TYPES);
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 2 * 4.5 + 2,
            width: "100%",
        },
    },
};

Array.prototype.move = function (x, y) {
    this.splice(y, 0, this.splice(x, 1)[0]);
    return this;
};

export default function MaintenanceHistory(props) {
    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"lg"}
                onClose={(e, r) => {
                    if (r != "backdropClick") props.setOpenStatus(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={props.openStatus}
                disableEscapeKeyDown={true}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => props.setOpenStatus(false)}
                >
                    Maintenance History
                </DialogTitle>
                <DialogContent dividers>
                    {props.deviceDatails && (
                        <Box style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                            <Typography gutterBottom>
                                District Name :{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["districtName"]}{" "}
                                </span>
                            </Typography>
                            <Typography gutterBottom>
                                Block Name:{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["blockName"]}{" "}
                                </span>{" "}
                            </Typography>

                            <Typography gutterBottom>
                                Panchayat Name:{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["panchayatName"]}
                                </span>
                            </Typography>

                            <Typography gutterBottom>
                                Ward :{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["name"]}
                                </span>{" "}
                            </Typography>

                            <Typography gutterBottom>
                                Device Id :{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["other"]["deviceid"]}{" "}
                                </span>
                                <br />
                            </Typography>
                        </Box>
                    )}
                    <table border="1" width="100%" cellSpacing={0}>
                        <tr>
                            <th rowspan="2">Added on</th>
                            <th colspan="2">Issues</th>
                            <th colspan="2">Other Description</th>
                            <th rowspan="2">Action Taken</th>
                        </tr>
                        <tr>
                            <th>Device Non-Functional</th>
                            <th>Scheme Non-Functional</th>
                            <th>Device Non-Functional</th>
                            <th>Scheme Non-Functional</th>
                        </tr>
                        {props.historyData &&
                            props.historyData.map((tr) => {
                                const dt = getDateTime(tr.cdatetime);
                                return (
                                    <tr>
                                        <td>
                                            {dt["date"]} {dt["time"]}
                                        </td>
                                        <td>
                                            {tr.issueList["DEVICE NON-FUNCTIONAL"]
                                                ? tr.issueList["DEVICE NON-FUNCTIONAL"].join(", ")
                                                : ""}
                                        </td>
                                        <td>
                                            {tr.issueList["SCHEME NON-FUNCTIONAL"]
                                                ? tr.issueList["SCHEME NON-FUNCTIONAL"].join(", ")
                                                : ""}
                                        </td>
                                        <td>
                                            {tr.otherIssue["DEVICE NON-FUNCTIONAL"]
                                                ? tr.otherIssue["DEVICE NON-FUNCTIONAL"]
                                                : ""}
                                        </td>
                                        <td>
                                            {tr.otherIssue["SCHEME NON-FUNCTIONAL"]
                                                ? tr.otherIssue["SCHEME NON-FUNCTIONAL"]
                                                : ""}
                                        </td>
                                        <td>{tr.action}</td>
                                    </tr>
                                );
                            })}
                    </table>
                </DialogContent>
                <DialogActions>
                    {/* <Tooltip open={isInValid()} title="Please enter issue details">
                        <Button
                            autoFocus
                            onClick={() =>
                                props.updateActionForIssue({
                                    deviceId: props.deviceDatails["other"]["deviceid"],
                                    issueList: selectedIssueList,
                                    otherIssue,
                                    action,
                                    cdatetime,
                                })
                            }
                            color="primary"
                            disabled={isInValid()}
                        >
                            Submit
                        </Button>
                    </Tooltip> */}
                </DialogActions>
            </Dialog>
        </div>
    );
}
