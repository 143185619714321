import { Box, Container, Card, CardHeader } from "@material-ui/core"
import React from "react"
import { Helmet } from "react-helmet-async"
import useSettings from "../../hooks/useSettings";
import GoogleMapReact from 'google-map-react';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import phed_data from "../../gisfiles/phed.json"
import { loadDistMapData, loadStateMapData } from '../../slices/loadMapData';

const dists = phed_data["district"].map((e) => e.name.toLocaleLowerCase())
function distFilter(biharjson) {
    if (biharjson["features"]) {
        let biharjson2 = { ...biharjson }
        biharjson2["features"] = biharjson["features"].filter((e) => dists[0].includes(e.properties["NAME_2"].toLocaleLowerCase()))

        return biharjson2
    } else {
        return biharjson
    }
}
const defaultPropsStatic = {
    center: {
        lat: 25.798702414,
        lng: 85.5214789847141
    },
    zoom: 8
};

const MapTrackerSchemesMap = (props) => {
    const dispatch = useDispatch();

    const [map, setMap] = useState(null)
    const [defaultProps, setDefaultProps] = useState(defaultPropsStatic)
    const { stateMapData, distMapData } = useSelector((state) => state.map);
    const { settings } = useSettings();
    const [stateGeojson, setStateGeojson] = useState({
        "type": "FeatureCollection",
        "name": "India_Districts",
        "features": []
    })
    const [distGeojson, setDistGeojson] = useState({
        "type": "FeatureCollection",
        "name": "India_Districts",
        "features": []
    })
    useEffect(() => {
        dispatch(loadStateMapData("state", "bihar"));
        dispatch(loadDistMapData("district", "bihar"));
    }, []);

    // useEffect(() => {
    //     if (stateMapData && stateMapData.data && stateMapData.data.type == "state" && stateMapData.data.geojson) {
    //         setStateGeojson(stateMapData.data.geojson)
    //     }
    // }, [])

    useEffect(() => {
        if (distMapData && distMapData.data && distMapData.data.type == "district" && distMapData.data.geojson) {
            setDistGeojson(distMapData.data.geojson)
        }
    }, [])

    useEffect(() => {
        if (map) {
            map.data.forEach((feature) => {
                if (feature.getProperty("type") == "state")
                    map.data.remove(feature);
            });
            map.data.addGeoJson(stateGeojson)
        }
    }, [stateGeojson])

    useEffect(() => {
        if (map) {
            map.data.forEach((feature) => {
                if (feature.getProperty("type") != "state")
                    map.data.remove(feature);
            });
            map.data.addGeoJson(distFilter(distGeojson))
        }
    }, [distGeojson])

    const onGoogleApiLoaded = ({ map, maps }) => {
        setMap(map)

        // map.data.forEach((feature) => {
        //     if (feature.getProperty("type") == "state")
        //         map.data.remove(feature);
        // });
        // map.data.addGeoJson(stateGeojson)

        map.data.forEach((feature) => {
            if (feature.getProperty("type") != "state")
                map.data.remove(feature);
        });
        map.data.addGeoJson(distFilter(distGeojson))

        map.data.setStyle((feature) => {
            let color = "#688eff";
            let fill = "#688eff";
            if (feature.getProperty("type") != "state") {
              if (!dists.includes(feature.getProperty("NAME_2").toLocaleLowerCase())) {
                color = "#CCC";
                fill = "#000"
              }
      
              if (feature.getProperty("isColorful")) {
                color = feature.getProperty("color");
              }
              return /** @type {!google.maps.Data.StyleOptions} */ {
                fillColor: fill,
                strokeColor: color,
                strokeWeight: 1,
              };
            }
          });
      

    }
    return (
        <>
            <Helmet>
                <title></title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Card {...props}>
                        <CardHeader title="* Work in progress" />
                        <div style={{ height: '80vh', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyC_-u8-cI5xWvUj49k9Y2-bor3F_k_-__A' }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                yesIWantToUseGoogleMapApiInternals={true}
                                onGoogleApiLoaded={onGoogleApiLoaded}
                                options={{ mapId: "8467585508bc0de4" }}
                            >
                                {/* {showPopup &&
                                    <Marker
                                        lat={defaultProps.center.lat}
                                        lng={defaultProps.center.lng}
                                        showPopup={showPopup}
                                        data={currentDist}
                                    />} */}
                            </GoogleMapReact>
                        </div>
                    </Card>
                </Container>
            </Box>
        </>
    )
}

export default MapTrackerSchemesMap