import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  Link,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "../../icons/ChevronRight";
import { Helmet } from "react-helmet-async";
import Step1 from "./FirmwareTestSteps/step1";
import LoadFormware from "./FirmwareTestSteps/step0";
import CertPage from "./FirmwareTestSteps/certPage";

function getSteps() {
  return ["Load Firmware", "Run Test", "Certificate Generation"];
}

const FirmwareDashboard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [deviceId, setDeviceId] = useState(null);
  const [deviceData, updateData ] = useState({});
  const steps = getSteps();

  const handleNext = (did) => {
    if(did){
      setDeviceId(did)
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <LoadFormware next={handleNext} updateData={updateData}/>;
      case 1:
        return <Step1 next={handleNext} deviceRegData={deviceData} updateData={updateData}/>;
      case 2:
        return (
          <CertPage
            deviceData={deviceData}
            onReset={() => {
              setDeviceId(null);
              setActiveStep(0);
              updateData({});
            }}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Helmet>
        <title>Maintenance | Har Ghar Nal Jal Yojna</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Firmware Loading
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/overview"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Firmware Loading
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ width: "50%", py: 3 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>{getStepContent(activeStep)}</div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FirmwareDashboard;
