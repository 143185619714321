import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import PageHeading from "../../../components/PageHeading";
import { title } from "../../../constants";
import useSettings from "../../../hooks/useSettings";
import NewDeviceInstallation from "./instllation-tracker/NewDeviceInstallation";

const menus = ["New Device Installation", "Device Replacement", "SIM Replacement"];
function InstallationTracker() {
  const settings = useSettings();
  const [menuId, setMenuId] = useState(0);
  return (
    <>
      <Helmet>
        <title>{title.label2} | Installation Tracker</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Installation Tracker"}
            prevPath={[{ name: "Installation Tracker" }]}
          />
          <Box sx={{ py: 3 }}>
            <ButtonGroup aria-label="small outlined button group">
              {menus.map((m, i) => {
                return (
                  <Button
                    key={i}
                    color="primary"
                    variant={menuId == i ? "contained" : ""}
                    onClick={() => (i > menus.length ? null : setMenuId(i))}
                  >
                    {m}
                  </Button>
                );
              })}
            </ButtonGroup>
            {menuId == 0 &&
                <NewDeviceInstallation />   }

            {menuId == 1 &&
            <Grid container>
                Coming soon
            </Grid>}

            {menuId == 2 &&
            <Grid container>
                Coming soon
            </Grid>}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default InstallationTracker;
