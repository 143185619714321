import React, { useEffect, useState } from "react";
import { Box, Button, Container, Paper, Typography } from "@material-ui/core";
import toast from "react-hot-toast";
import useSettings from "../../hooks/useSettings";
import PageHeading from "../../components/PageHeading";
import ShowWhen from "../../components/common/ShowWhen";
import { Loader } from "../../components/Loader/Loader";
import FileSelector from "../../components/common/FileSelector";
import SingleGlandDevicesList from "./installation/SingleGlandDevicesList";
import {
  getSingleGlandDevices,
  uploadAndCreateSingleGlandDevices,
} from "../../actions/singleGlandDevices";
import { isArray } from "lodash";

function SingleGlandDevices() {
  const { settings } = useSettings();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSingleGlandDevicesRes, setUploadResponse] = useState(null);
  const [singleGlandDevicesData, setSingleGlandDevices] = useState([]);

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file first!");
      return;
    }
    try {
      setLoading(true);
      const uploadRes = await uploadAndCreateSingleGlandDevices({
        file: selectedFile,
      });
      if (uploadRes.statusCode === 201) {
        fetchSingleGlandDevices();
      }
      delete uploadRes.error.statusCode;
      delete uploadRes.error.errorResponse;
      setUploadResponse(uploadRes);
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading csv!", error);
    } finally {
      setLoading(false);
    }
  };

  const acceptedTypes = ["text/csv", "application/vnd.ms-excel"];

  const fetchSingleGlandDevices = async () => {
    try {
      setLoading(true);
      const singleGlandDevicesRes = await getSingleGlandDevices();
      setSingleGlandDevices(singleGlandDevicesRes.data);
    } catch (error) {
      console.error("Error fetching single gland devices!", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleGlandDevices();
  }, []);

  const showErrors = (errors = {}) => {
    return Object?.keys(errors)?.map((key) => {
      return (
        <Typography
          color={
            key === "SuccessfullyAddedSingleGlandDevice" ? "green" : "error"
          }
          key={key}
        >
          <Box sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
            {key}:
          </Box>
          {isArray(errors[key]) ? errors[key].join(", ") || "—" : errors[key] || "—"}
        </Typography>
      );
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Single Gland Devices"}
            prevPath={[{ name: "Manually Installation Single Gland Devices" }]}
          />
          <Paper
            sx={{
              p: 2,
              mt: 3,
              mb: 3,
            }}
            variant="outlined"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 3,
                flexWrap: "wrap",
                mb: 2,
              }}
            >
              <FileSelector
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                acceptedTypes={acceptedTypes}
                title="Select Single Gland Devices Data CSV file!"
              />
              <Button
                disabled={!selectedFile || loading}
                variant="contained"
                onClick={handleUpload}
              >
                Upload
              </Button>
              <a
                target="_blank"
                href={`https://rollzproddocuments.blob.core.windows.net/templates/SingleGlandDevicesTemplate.csv?id=${new Date().getTime()}`}
                rel="noreferrer"
              >
                Download template file
              </a>
            </Box>
            {showErrors(uploadSingleGlandDevicesRes?.error)}
          </Paper>
          <SingleGlandDevicesList
            title="Single Gland Devices:"
            schemes={singleGlandDevicesData}
            fileName={"single_gland_devices"}
            loading={loading}
          />
        </Container>
      </Box>
      <ShowWhen when={loading} component={<Loader />} />
    </>
  );
}

export default SingleGlandDevices;
