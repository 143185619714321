import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkIfRoleExist } from '../../utils/helper';
const AdminGuard = ({ children }) => {
    if (checkIfRoleExist(localStorage.getItem("USER_TYPE"), ["APP_SUPER_ADMIN", "APP_DATA_ENTRY"])) {
    // if (localStorage.getItem("USER_TYPE").includes("APP_SUPER_ADMIN") || localStorage.getItem("USER_TYPE") == "APP_DATA_ENTRY") {
        return <>{children}</>;
    }
    else {
        return <Navigate to="/overview" />;
    }
};

AdminGuard.propTypes = {
    children: PropTypes.node
};

export default AdminGuard;
