import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box,
    Grid,
    Typography,
    Container,
    Breadcrumbs,
    Link,
    Button,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    ButtonGroup
} from '@material-ui/core';
import ChevronRightIcon from '../../icons/ChevronRight';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DebuggingForm from '../../components/debugging/DebuggingForm';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceTelemetry } from '../../slices/debugging';
import { Loader } from '../../components/Loader/Loader';
import { currentDateTime } from '../../utils/helper';
import DebuggingControlUI from '../../components/debugging/DebuggingControlUI';
import Debugging from './Debugging';
import IoTTestingForm from './IoTTesting';

const menus = ["New IOT Installation", "IOT Replacement", "New Pressure Sensor Installation", "Pressure Sensor Testing", "IoT Testing"]
const DebuggingLanding = () => {
    const [menuId, setMenuId] = useState(0)
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <title>Installation & Debugging | Har Ghar Nal Jal Yojna</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2
                }}
            >
                <Container maxWidth={"xl"}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item width="100%">
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Installation & Debugging
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/overview"
                                    variant="subtitle2"
                                >
                                    Home
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Installation & Debugging
                                </Typography>
                            </Breadcrumbs>
                            <Box
                                sx={{
                                    mt: 3
                                }}
                                width="100%"
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <ButtonGroup aria-label="small outlined button group">
                                        {menus.map((m, i) => {
                                            return <Button
                                                color="primary"
                                                variant={menuId == i ? "contained" : ""}
                                                onClick={() => i > menus.length ? null : setMenuId(i)}
                                            >
                                                {m}
                                            </Button>
                                        })}
                                    </ButtonGroup>
                                    <Button onClick={() => { navigate(-1) }}>Back</Button>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        {menuId == 0 && <Debugging />}
                        {menuId == 4 && <IoTTestingForm />}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default DebuggingLanding;
