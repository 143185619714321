import {
  Box,
  Grid,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";

const Step0_Validate = (props) => {
  const [deviceid, setDeviceId] = useState("");
  const [ip, setIp] = useState("");
  const [port, setPort] = useState("");

  useEffect(() => {
    props.onChangeDeviceId(deviceid)
  }, [deviceid])

  useEffect(() => {
    props.onChangeIp(ip)
  }, [ip])

  useEffect(() => {
    props.onChangePort(port)
  }, [port])


  return (
    <>
      <Box sx={{ width: "50%", py: 3 }}>
        <Grid container direction={"column"} spacing={1}>
          <Grid item>
            <TextField
              value={deviceid}
              label="Enter Device Barcode:"
              onChange={(e) => {
                setDeviceId(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              value={ip}
              label="Enter IP Address:"
              onChange={(e) => {
                setIp(e.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              value={port}
              label="Enter port:"
              onChange={(e) => {
                setPort(e.target.value);
              }}
            />
          </Grid>
          {/* <Grid item>
            <Button variant="contained" onClick={verifyDetails}>
              Next
            </Button>
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default Step0_Validate;
