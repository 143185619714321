import React, { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  Paper,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  Stack,
  Chip,
  IconButton,
} from "@material-ui/core";
import ChevronRightIcon from "../icons/ChevronRight";
import useSettings from "../hooks/useSettings";
import { priColor } from "../constants";
import { useFormik } from "formik";
import { Loader } from "./Loader/Loader";
import { Tooltip } from "@mui/material";
import { addCountryCode, getDeptName, getLabelByValue } from "../utils/helper";
import useAuth from "../hooks/useAuth";
import { createCallingWard } from "../actions/calling";
import { Cancel } from "@material-ui/icons";

export const issueType = [
  { label: "IoT Device Not Working/Tempered", value: 2 },
  { label: "Pipe Leakage Major", value: 3 },
  { label: "Pipe Leakage Minor", value: 4 },
  { label: "Meter Not Recharge", value: 5 },
  { label: "Local dispute", value: 6 },
  { label: "Payment dispute", value: 7 },
  { label: "Operator not present due to personal reason", value: 8 },
  { label: "Incorrect Operator Details", value: 9 },
  { label: "Not Responding", value: 10 },
  { label: "Faulty Gate Wall", value: 11 },
  { label: "Main Transformer is Faulty", value: 12 },
  { label: "Faulty/No Connection B/w Scheme & Transformer", value: 13 },
  { label: "Structure not ready/completed", value: 14 },
  { label: "Faulty Boring", value: 15 },
  { label: "Low/Fluctuating Voltage", value: 16 },
  { label: "Motor Faulty", value: 17 },
  { label: "Starter Faulty", value: 18 },
  { label: "Others", value: 1 },
];

export const powerStatus = [
  { value: true, label: "Okay" },
  { value: false, label: "Not Okay" },
];
export const motorStatus = [
  { value: true, label: "Working" },
  { value: false, label: "Not Working" },
];
export const deviceInstallationStatus = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const callButton = (phoneNumber, tag = "Latest") => {
  if (!phoneNumber) return
  return (
    <Typography gutterBottom>
      <Tooltip
        title={
          phoneNumber
        }
      >
        <Button
          variant="outlined"
          style={{ color: priColor }}
        >
          <a
            style={{
              textDecoration: "none",
            }}
            href={`tel:${addCountryCode(
              phoneNumber
            )}`}
          >
            {tag}:{" "}
            {
              phoneNumber
            }
          </a>
        </Button>
      </Tooltip>
    </Typography>
  )
}

const CallingInfo = (props) => {
  const { settings } = useSettings();
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [wardData, setWardData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const dept = getDeptName();

  let wardDataTemp = {};
  let allSchemeData = [];
  if (location.state.data) {
    wardDataTemp = location.state.data;
    allSchemeData = location?.state?.schemes || [];
  }

  const formik = useFormik({
    initialValues: {
      powerStatus: wardDataTemp?.calling_details?.power_status ?? null,
      motorStatus: wardDataTemp?.calling_details?.motor_status ?? null,
      deviceInstalled: wardDataTemp?.calling_details?.device_installed ?? null,
      issues: wardDataTemp?.calling_details?.faulty_site ?? true,
      issueType: wardDataTemp?.calling_details?.issue_type ?? [],
      otherIssue: wardDataTemp?.calling_details?.issue_type?.includes(1)
        ? wardDataTemp?.calling_details?.issue_desc?.split(", ").pop() || ""
        : "",
    },
    validate: (values) => {
      const errors = {};
      if (values.issues) {
        if (
          !values.issueType.filter(
            (value) => value !== null && value !== undefined && value !== ""
          )?.length
        ) {
          errors.issueType = "Issue Type is required";
        }
        if (values.issueType.includes(1) && !values.otherIssue) {
          errors.otherIssue = "Other Issue is required";
        }
        // If issueType includes "Not Responding" or "Incorrect Operator Details", skip other validations
        if (
          values.issueType.includes(1) ||
          values.issueType.includes(2) ||
          values.issueType.includes(3) ||
          values.issueType.includes(4) ||
          values.issueType.includes(5) ||
          values.issueType.includes(6) ||
          values.issueType.includes(7) ||
          values.issueType.includes(8) ||
          values.issueType.includes(11) ||
          values.issueType.includes(12) ||
          values.issueType.includes(13) ||
          values.issueType.includes(14) ||
          values.issueType.includes(15) ||
          values.issueType.includes(16) ||
          values.issueType.includes(17) ||
          values.issueType.includes(18)
        ) {
          if (values.powerStatus === null) {
            errors.powerStatus = "Power Status is required";
          }
          if (values.motorStatus === null) {
            errors.motorStatus = "Motor Status is required";
          }
          if (values.deviceInstalled === null) {
            errors.deviceInstalled = "Device Installed is required";
          }
        }
      } else {
        if (values.powerStatus === null) {
          errors.powerStatus = "Power Status is required";
        }
        if (values.motorStatus === null) {
          errors.motorStatus = "Motor Status is required";
        }
        if (values.deviceInstalled === null) {
          errors.deviceInstalled = "Device Installed is required";
        }
      }
      return errors;
    },

    onSubmit: (values, { resetForm }) => handleSave(values, resetForm),
  });

  async function handleSave(values, resetForm) {
    let issueDescription = values.issueType
      .filter((type) => type !== 1)
      .map((type) => getLabelByValue({ arrayList: issueType, value: type })) //
      .join(", ");

    if (values.issueType.includes(1)) {
      issueDescription += `, ${values.otherIssue}`;
    }

    const newCallingWard = {
      smsid: wardData?.smsid,
      wardId: wardData.id,
      calling_ward_id: wardDataTemp?.calling_details?.id ?? null,
      district: wardData?.district,
      power_status: values.powerStatus,
      motor_status: values.motorStatus,
      device_installed: values.deviceInstalled,
      faulty_site: values.issues,
      issue_type: values.issueType,
      issue_desc: issueDescription,
      anurakshak_name:
        wardData?.anurakshak?.name || wardData?.other?.op_name || "",
      anurakshak_number:
        wardData?.anurakshak?.mobile_number || wardData?.other?.op_number,
      called_by: user?.email?.toLowerCase(),
      dept: dept.toLowerCase(),
      incorrect_operator_details: wardData?.incorrect_operator_details ?? null
    };

    try {
      setLoading(true);
      const cWRes = await createCallingWard(newCallingWard);
      if (cWRes.statusCode === 201 || cWRes.statusCode === 200) {
        resetForm();
        moveToNextRecord();
      }
    } catch (error) {
      console.error("Error creating calling ward:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleIssuesChange = (event) => {
    const isChecked = event.target.checked;
    formik.setValues((prevValues) => ({
      ...prevValues,
      issues: isChecked,
      issueType: isChecked ? [] : [],
      otherIssue: isChecked ? "" : "",
    }));
  };

  const moveToNextRecord = () => {
    if (wardDataTemp?.calling_status.toLowerCase() === "completed") {
      navigate("/calling");
    }
    let nextIndex = currentIndex + 1;
    while (
      nextIndex < allSchemeData.length &&
      allSchemeData[nextIndex].calling_status
    ) {
      nextIndex++;
    }

    if (nextIndex < allSchemeData.length) {
      setWardData(allSchemeData[nextIndex]);
      setCurrentIndex(nextIndex);
    } else {
      navigate("/calling");
    }
  };

  // const handleDelete = (option) => () => {};

  useEffect(() => {
    setCurrentIndex(wardDataTemp.row);
  }, [wardDataTemp]);

  useEffect(() => {
    setWardData(allSchemeData[currentIndex]);
  }, [allSchemeData, currentIndex]);

  return (
    <>
      <Helmet>
        <title>Calling Information | Har Ghar Nal Jal Yojna</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item width="100%">
              <Typography color="textPrimary" variant="h5">
                Calling Information
              </Typography>
              <Typography color="textSecondary" variant="h6"></Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/overview"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/calling"
                  variant="subtitle2"
                >
                  Calling
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Calling Information
                </Typography>
              </Breadcrumbs>
              <Box
                sx={{
                  mt: 3,
                }}
                width="100%"
              >
                <Grid container spacing={2}>
                  {/* Display ward information here */}
                  <Grid item xs={12}>
                    <Paper
                      elevation={0}
                      variant="outlined"
                      style={{ padding: "10px", marginBottom: "5px" }}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography gutterBottom>
                        District :{" "}
                        <span style={{ color: priColor }}>
                          {wardData?.districtName}
                        </span>
                      </Typography>
                      <Typography gutterBottom>
                        Block :{" "}
                        <span style={{ color: priColor }}>
                          {wardData?.blockName}
                        </span>
                      </Typography>
                      <Typography gutterBottom>
                        Panchayat :{" "}
                        <span style={{ color: priColor }}>
                          {wardData?.panchayatName}
                        </span>
                      </Typography>
                      <Typography gutterBottom>
                        Ward :{" "}
                        <span style={{ color: priColor }}>
                          {wardData?.name}
                        </span>
                      </Typography>
                      <Typography gutterBottom>
                        Anurakshak :{" "}
                        <span style={{ color: priColor }}>
                          {wardData?.anurakshak?.name ||
                            wardData?.other?.op_name ||
                            "N/A"}
                        </span>
                      </Typography>

                      {
                        wardData?.anurakshak?.mobile_number?.length &&
                          String(wardData?.anurakshak?.mobile_number) === String(wardData?.other?.op_number) ?
                          callButton(wardData?.anurakshak?.mobile_number)
                          : (
                            <>
                              {
                                wardData?.anurakshak?.mobile_number?.length ? (
                                  callButton(wardData?.anurakshak?.mobile_number)
                                ) : null
                              }
                              {
                                wardData?.other?.op_number?.length ? (
                                  callButton(wardData?.other?.op_number, "Old")
                                ) : null
                              }
                            </>
                          )
                      }
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel
                              sx={{ background: "#f4f5f7", padding: "0 12px" }}
                            >
                              Power Status
                            </InputLabel>
                            <Select
                              name="powerStatus"
                              value={
                                formik.values.powerStatus === null
                                  ? ""
                                  : formik.values.powerStatus
                              }
                              onChange={formik.handleChange}
                              error={
                                formik.touched.powerStatus &&
                                Boolean(formik.errors.powerStatus)
                              }
                            >
                              {powerStatus.map((d, i) => (
                                <MenuItem key={d.label + i} value={d.value}>
                                  {d.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {formik.touched.powerStatus &&
                              formik.errors.powerStatus && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.powerStatus}
                                </div>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel
                              sx={{ background: "#f4f5f7", padding: "0 12px" }}
                            >
                              Motor Status
                            </InputLabel>
                            <Select
                              name="motorStatus"
                              value={
                                formik.values.motorStatus === null
                                  ? ""
                                  : formik.values.motorStatus
                              }
                              onChange={formik.handleChange}
                              error={
                                formik.touched.motorStatus &&
                                Boolean(formik.errors.motorStatus)
                              }
                            >
                              {motorStatus.map((d, i) => (
                                <MenuItem key={d.label + i} value={d.value}>
                                  {d.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {formik.touched.motorStatus &&
                              formik.errors.motorStatus && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.motorStatus}
                                </div>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel
                              sx={{ background: "#f4f5f7", padding: "0 12px" }}
                            >
                              Device Installed
                            </InputLabel>
                            <Select
                              name="deviceInstalled"
                              value={
                                formik.values.deviceInstalled === null
                                  ? ""
                                  : formik.values.deviceInstalled
                              }
                              onChange={formik.handleChange}
                              error={
                                formik.touched.deviceInstalled &&
                                Boolean(formik.errors.deviceInstalled)
                              }
                            >
                              {deviceInstallationStatus.map((d, i) => (
                                <MenuItem key={d.label + i} value={d.value}>
                                  {d.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {formik.touched.deviceInstalled &&
                              formik.errors.deviceInstalled && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.deviceInstalled}
                                </div>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Typography>No</Typography>
                                <Switch
                                  checked={formik.values.issues}
                                  onChange={handleIssuesChange}
                                  sx={{}}
                                />
                                <Typography>Yes</Typography>
                              </Stack>
                            }
                            label={
                              formik.values.issues
                                ? "Issues (Yes)"
                                : "Issues (No)"
                            }
                            labelPlacement="top"
                            sx={{ margin: 0, padding: 0 }}
                          />
                        </Grid>
                        {formik.values.issues && (
                          <>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <InputLabel
                                  sx={{
                                    background: "#f4f5f7",
                                    padding: "0 12px",
                                  }}
                                >
                                  Issue Type
                                </InputLabel>
                                <Select
                                  open={open}
                                  onOpen={() => setOpen(true)}
                                  onClose={() => setOpen(false)}
                                  multiple
                                  name="issueType"
                                  value={formik.values.issueType}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.issueType &&
                                    Boolean(formik.errors.issueType)
                                  }
                                  renderValue={(selected) => (
                                    <div
                                      style={{
                                        gap: 8,
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {selected
                                        .filter(
                                          (x) => x !== null && x !== undefined
                                        )
                                        .map((value, i) => (
                                          <Chip
                                            sx={{
                                              height: "auto",
                                              "& .MuiChip-label": {
                                                display: "block",
                                                whiteSpace: "normal",
                                              },
                                            }}
                                            key={value + 1}
                                            label={getLabelByValue({
                                              arrayList: issueType,
                                              value,
                                            })}
                                          // onDelete={(e) => handleDelete(e)}
                                          />
                                        ))}
                                    </div>
                                  )}
                                >
                                  <IconButton
                                    sx={{
                                      position: "sticky",
                                      top: "15px",
                                      right: "15px",
                                      float: "right",
                                      zIndex: 9999,
                                    }}
                                    onClick={() => setOpen(false)}
                                    size="medium"
                                  >
                                    <Cancel />
                                  </IconButton>
                                  {issueType.map((t) => (
                                    <MenuItem
                                      key={t.value}
                                      value={t.value}
                                      sx={{
                                        width: "100%",
                                        textWrap: "wrap",
                                      }}
                                    >
                                      {t.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {formik.touched.issueType &&
                                  formik.errors.issueType && (
                                    <div style={{ color: "red" }}>
                                      {formik.errors.issueType}
                                    </div>
                                  )}
                              </FormControl>
                            </Grid>
                            {formik.values.issueType.includes(1) && (
                              <Grid item xs={12}>
                                <TextField
                                  name="otherIssue"
                                  label="Other Issue Desc"
                                  value={formik.values.otherIssue}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.otherIssue &&
                                    Boolean(formik.errors.otherIssue)
                                  }
                                  placeholder="Please provide the issue description here..."
                                  helperText={
                                    formik.touched.otherIssue &&
                                    formik.errors.otherIssue
                                  }
                                  fullWidth
                                />
                              </Grid>
                            )}
                          </>
                        )}
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={!formik.isValid || formik.isSubmitting}
                            style={{ marginTop: "1rem" }}
                          >
                            {formik.isSubmitting ? (
                              <Loader />
                            ) : wardDataTemp?.calling_status.toLowerCase() ===
                              "completed" ? (
                              "Update"
                            ) : (
                              "Save & Next"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {loading && <Loader />}
    </>
  );
};

export default CallingInfo;
