import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Step0_Validate from "./step0_validateLog";
import ImeiConfirm from "./_imeiConfirmStep";
import { getRegDataUsingBardCodeId, updateRegData } from "./_service";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ["Unregister Device", "Load New Firmware", "Validate Log"];
}

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const { user } = useAuth();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [disableNext, setDisableNext] = useState(false);
  const [deviceData, updateData] = useState({});
  const [deviceId, setDeviceId] = useState("");
  const [ip, setIp] = useState("");
  const [port, setPort] = useState("");

  useEffect(() => {
    props.updateDeviceData({ ...deviceData, user_name: user.name, mobile_number: user.mobile_number, barcode:deviceId  });
  }, [deviceData, deviceId]);

  useEffect(() => {
    
    let validation = { ip: "invalid", deviceid: "invalid", port: "invalid" };
    if (deviceId == "") {
      validation["deviceid"] = "invalid";
    } else {
      validation["deviceid"] = "valid";
    }

    if (ip == "20.235.102.127" || ip == "20.192.1.202" || ip == '20.197.12.169') {
      validation["ip"] = "valid";
    }

    if (port == "1883") {
      validation["port"] = "valid";
    }

    if (
      validation["ip"] == "valid" &&
      validation["deviceid"] == "valid" &&
      validation["port"] == "valid"
    ) {
      getRegDataUsingBardCodeId(deviceId).then((data) => {
        if (data["statusCode"] == 200) {
          setDisableNext(false);
        } else {
          alert("Barcode Already in Use.");
          setDisableNext(true);
        }
      });
    } else {
      setDisableNext(true);
    }
  }, [deviceId, ip, port]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        // setDisableNext(false)
        return (
          <div>
            <p>
              Complete <b>Step 1</b> to <b>Step 15</b>
            </p>
            <p>
              Confirm you verfied the message "File Download Complete" as per
              Step 9.
            </p>
          </div>
        );
      case 1:
        return (
          <ImeiConfirm enableNext={enableNext} updateRegData={updateData} />
        );
      case 2:
        return (
          <Step0_Validate
            onChangeDeviceId={setDeviceId}
            onChangeIp={setIp}
            onChangePort={setPort}
          />
        );
    }
  };

  const enableNext = (disable) => {
    setDisableNext(!disable);
  };

  const handleNext = () => {
    if (steps.length == activeStep + 1) {
      if (deviceId) {
        updateRegData({ ...deviceData, barcode: deviceId, user_name: user.name, mobile_number: user.mobile_number}).then((data) => {
          if (data["statusCode"] == 200) {
            props.next();
          } else {
            alert("Something went wrong");
          }
        });
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent(index)}
              <div className={classes.actionsContainer}>
                <div>
                  {/* <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button> */}
                  <Button
                    disabled={activeStep > 0 ? disableNext : false}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>Please try after device registration complete.</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  );
}
