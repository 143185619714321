import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

const CustomAccordion = ({ expanded, expandedItem, onChange, title = "Title", id, children }) => {
  return (
    <Accordion expanded={expanded === expandedItem} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon />}
        aria-controls={`${id}-content`}
        id={id}
      >
        <Typography
          sx={{
            width: "33%",
            fontWeight: "bold",
            flexShrink: 0,
            fontSize: "16px",
            textTransform: "capitalize",
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          margin: "0px",
          padding: "0px",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
