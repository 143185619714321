import { Button, Card } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import CustomNoRowsOverlay from "../../../../components/common/CustomNoRowsOverlay";
import CustomToolbar from "../../../../components/common/CustomToolbar";
import { getInstallationFieldObj } from "../../../../utils/helper";

const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});

const ManualEntrySchemeList = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);

  const getColor = (v) => {
    if (v.includes("Pending")) return "blue";
    else if (v.includes("Failed")) return "red";
    else if (v.includes("Approved")) {
      return "green";
    } else {
      return "black";
    }
  };

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      // const dr = historyDate(0, "currentDay");
      props.schemes.map((page, i) => {
        if (props.showWardsIds && props.showWardsIds.length) {
          if (!props.showWardsIds.includes(page["id"])) {
            return;
          }
        }

        tableDataTemp.push({
          ...page,
          ...page.other,
          nicId: page.nicdata ? page.nicdata.NICDeviceId : "",
          did: page.other.deviceid,
          wname: page.name,
          assignedOn: page.installation_user_mapping
            ? page.installation_user_mapping.date
            : "",
          assignedTo: page.installation_user_mapping
            ? page.installation_user_mapping.name
            : "",
          completed_status: page,
          completed_date: page.verification ? page.verification.date : "-",
          action: page,
          id: page.id,
          remark:
            page.installation_data && page.installation_data.remark
              ? page.installation_data.remark
              : "-NA-",
          slno: i + 1,
        });
      });
    }

    setTableData(tableDataTemp);
  }, [props.schemes]);

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 50,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      hide: true,
      width: 100,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "districtName",
      headerName: "District",
      width: 120,
      sortable: false,
    },
    {
      field: "division",
      headerName: "Division",
      width: 130,
      sortable: false,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: false,
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: false,
    },
    {
      field: "wname",
      headerName: "Ward",
      width: 50,
      sortable: false,
    },
    {
      field: "subScheme",
      headerName: "Scheme Type",
      width: 80,
      sortable: false,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 80,
    },
    {
      field: "assignedOn",
      headerName: "Assigned On",
      width: 130,
      sortable: false,
    },
    {
      field: "completed_status",
      headerName: "Installation Status",
      width: 140,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        const obj = getInstallationFieldObj(params["formattedValue"]);
        return (
          <span
            style={{
              color: obj["color"],
              backgroundColor: obj["bgColor"] ? obj["bgColor"] : "",
            }}
          >
            {obj["text"]}
          </span>
        );
      },
    },
    {
      field: "completed_date",
      headerName: "Completed On",
      width: 170,
      sortable: false,
    },
    {
      field: "remark",
      headerName: "Remark",
      width: 130,
      sortable: false,
      renderCell: (params) => {
        return (
          <span style={{ color: getColor(params["formattedValue"]) }}>
            {params["formattedValue"]}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        const obj = getInstallationFieldObj(params["formattedValue"]);
        let disabled = true;
        if (obj.text == "Completed" || obj.text == "Rejected") {
          disabled = false;
        }
        if (disabled) {
          return (
            <Button
              style={{ color: "orange" }}
              onClick={() => uploadDataManually(params["formattedValue"])}
            >
              Upload Data
            </Button>
          );
        }
        // return <Button disabled={disabled} onClick={() => handleClick(params["formattedValue"])}>View Details</Button>
        return <></>;
      },
    },
  ];

  const uploadDataManually = (data) => {
    navigate("device-info-manual", {
      state: {
        wardData: data,
        assignmentType: "New Device Installation Manual entry",
      },
    });
  };
  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          loading={!tableData.length}
          // checkboxSelection
          disableSelectionOnClick
          // onSelectionModelChange={(ids) => {
          //   const selectedIDs = new Set(ids);
          //   const selectedRowData = tableData.filter((row) =>
          //     selectedIDs.has(row.id.toString())
          //   );
          //   props.onSelect(selectedRowData);
          // }}
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          // isRowSelectable={(params) => !params.row.installation_user_mapping}
          componentsProps={{
            toolbar: {
              title: props.title,
              fileName: props.fileName,
              locationName: props.distName,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{ noRowsLabel: "No Records  Found :)" }}
        />
      </div>
    </Card>
  );
};

export default ManualEntrySchemeList;
