import {
  Box,
  Grid,
  Typography,
  Container,
  Breadcrumbs,
  Link,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "../../../icons/ChevronRight";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import useSettings from "../../../hooks/useSettings";
import {
  LocationFilter
} from "../../../components/dashboard/shemes";
import { getSchemes, resetSchemes, setFilters } from "../../../slices/siteInfo";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components/Loader/Loader";
import { resetOpData } from "../../../slices/operationalData";
import WardList from "./WardList";
import { assignedUsersToWard, getUserByRole } from "../../../slices/userManagement";
import _ from "lodash";
import moment from "moment-timezone";
import ShowWhen from "../../../components/common/ShowWhen";
import toast from "react-hot-toast";

function StaffAssignment() {
  const { settings } = useSettings();
  const { district, schemes, lastSearchFilter } = useSelector(
    (state) => state.siteinfo
  );
  const { userByRole, assignUserToWards, removeAssignee } = useSelector((state) => state.userManagement);
  const [allSchemes, setAllSchemes] = useState([]);
  const dispatch = useDispatch();
  const [schemeLocation, setSchemeLocation] = useState({});
  const [distName, setDistName] = useState("VAISHALI");
  const [appUserList, setAppUserList] = useState([]);
  const [selectedWards, setSelectedWards] = useState([]);
  const [selectedAppUser, setSelectedAppUser] = useState(null);

  const onChangeLocation = (data) => {
    const keys = Object.keys(data);
    let allReset = true;
    for (let i = 0; i < keys.length; i++) {
      allReset = allReset && !data[keys[i]];
    }

    if (allReset) {
      setAllSchemes([]);
      dispatch(resetSchemes());
      dispatch(resetOpData());
    }
    setSchemeLocation(data);
  };

  const sendGetSchemeReq = () => {
    let d = {};
    if (schemeLocation.district) {
      d["district"] = schemeLocation.district;
    }
    if (schemeLocation.division) {
      d["division"] = schemeLocation.division;
    }
    if (schemeLocation.block) {
      d["block"] = schemeLocation.block;
    }
    if (schemeLocation.panchayat) {
      d["panchayat"] = schemeLocation.panchayat;
    }
    if (schemeLocation.ward) {
      d["ward"] = schemeLocation.ward;
    }
    dispatch(setFilters(schemeLocation));
    if ( d.district || d.block || d.panchayat || d.ward ) {
      dispatch(getSchemes(d));
    }
  }

  useEffect(() => {
    if (
      schemeLocation.district ||
      schemeLocation.block ||
      schemeLocation.panchayat ||
      schemeLocation.ward
    ) {
      sendGetSchemeReq()
    }
  }, [schemeLocation]);

  useEffect(() => {
    setAllSchemes(schemes["data"]);
  }, [schemes]);

  useEffect(() => {
    if (parseInt(schemeLocation.district)) {
      if (district["data"] && district["data"].length > 0) {
        const d = district["data"].filter(
          (e) => parseInt(e.id) == schemeLocation.district
        );
        if (d.length) {
          setDistName(d[0]["name"]);
        }
      }
    } else {
      setDistName("");
    }
  }, [schemeLocation.district, district]);

  useEffect(() => {
    dispatch(getUserByRole({ role: "MOBILE_APP" }));
  }, []);

  useEffect(() => {
    if (userByRole && userByRole.status == "SUCCESS") {
      if (userByRole.data && userByRole.data.length) {
        setAppUserList(userByRole.data);
      }
    }
  }, [userByRole]);

  useEffect(() => {
    if (assignUserToWards.status == "SUCCESS") {
      sendGetSchemeReq()
      setSelectedAppUser(null)
    }
  }, [assignUserToWards])


  useEffect(() => {
    if (removeAssignee.status == "SUCCESS") {
      sendGetSchemeReq()
      setSelectedAppUser(null)
    }
  }, [removeAssignee])

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Schemes
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/overview"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Scheme Details
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ py: 3 }}>
            <LocationFilter
              onChange={onChangeLocation}
              initialValues={lastSearchFilter}
              schemes={allSchemes}
              downloadExcel={true}
            />
          </Box>
          <Box sx={{ py: 3 }}>
            {allSchemes && allSchemes.length ? (
              <Grid container>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Assign To"
                    name="assignUser"
                    onChange={(d) => {
                      setSelectedAppUser(d.target.value);
                    }}
                    select
                    disabled={!selectedWards.length}
                    value={selectedAppUser}
                    variant="outlined"
                    onClick={() => {
                      if(!selectedWards.length){
                        toast.error("Please Select Ward first!");
                      }
                    }}
                  >
                    {_.sortBy(appUserList, (e) => e.name.trim().toLowerCase()).map((e) => (
                      <MenuItem key={e.id} value={e}>
                        {e.name?.toUpperCase()} {`(${e.mobile_number})`}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 10,
                  }}
                >
                  <Button variant="contained" size="large" sx={{ mr: 3 }} onClick={() => {
                    if (selectedAppUser) {
                      const userInfo = {
                        ...selectedAppUser,
                        date: moment().format("DD/MM/YYYY")
                      }
                      dispatch(assignedUsersToWard({ wards: selectedWards.map(e => e.id), userInfo }))
                    }else if(!selectedWards.length){
                      toast.error("Please Select Ward first!");
                    }else{
                      toast.error('Please Select TE to assign ward!');
                    }
                  }}>
                    Assign
                  </Button>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Box>
          <Grid container spacing={3}>
            <Grid item xl={12} md={12} xs={12}>
              {allSchemes && allSchemes.length > 0 ? (
                <WardList
                  onSelect={setSelectedWards}
                  sx={{ height: "100%" }}
                  schemes={allSchemes}
                  fileName={"staff_assignment"}
                  distName={distName}
                />
              ) : null}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <ShowWhen when={schemes?.status === 'loading' || assignUserToWards.status === "loading" } component={<Loader/>} />
    </>
  );
}

export default StaffAssignment;
