import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { filepost } from './service';

const initialState = {
    uploadCSV: {
        status: null
    },
    uploadTrackerCSVState: {
        status: null,
        data: {}
    },
    manageManualInstallationCSVUpload: {
        status: null,
        data: {}
    }
};

const slice = createSlice({
    name: 'uploadCSV',
    initialState,
    reducers: {
        setUploadStatus(state, action) {
            state.uploadCSV = action.payload
        },
        resetStatus: (state, action) => {
            state.uploadCSV = { status: null }
        },
        setUploadTrackerCSV(state, action){
            state.uploadTrackerCSVState = action.payload
        },
        setManageManualInstallationCSVUpload(state, action){
            state.manageManualInstallationCSVUpload = action.payload
        }
    }
});

export const { reducer } = slice;

export const uploadCSV = (file, dist, name, divid, divname) => async (dispatch) => {
    dispatch(slice.actions.setUploadStatus({ ...initialState.uploadCSV, status: "loading" }));

    filepost(API.base_url + "phed/uploadFile", { uifile: file, districtId: dist, name, divid, divname })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUploadStatus({ ...initialState.uploadCSV, status: "success" }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUploadStatus({ ...errResponse, status: "failed" }))
        });
};

export const resetUploadStatus = (file, dist) => async (dispatch) => {
    dispatch(slice.actions.resetStatus());
}

export const resetManageManualInstallationCSVUpload = () =>  async (dispatch) => {
    return dispatch(slice.actions.setManageManualInstallationCSVUpload());
}

export const uploadTrackerCSV = (file, dist, name, divid, divname) => async (dispatch) => {
    dispatch(slice.actions.setUploadTrackerCSV({ ...initialState.uploadTrackerCSVState, status: "loading" }));

    filepost(API.base_url + "phed/uploadTrackerDeviceIdFile", { uifile: file  })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUploadTrackerCSV(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUploadTrackerCSV({ ...errResponse, status: "failed" }))
        });
};

export const ManualInstallationCSVUpload = (file) => async (dispatch) => {
    dispatch(slice.actions.setManageManualInstallationCSVUpload({ ...initialState.manageManualInstallationCSVUpload, status: "loading" }));
    filepost(API.base_url + "phed/manual-installation-upload-csv", { uifile: file  })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setManageManualInstallationCSVUpload(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setManageManualInstallationCSVUpload({ ...errResponse, status: "failed" }))
        })
};

export default slice;