import { Button, Grid, TextField, Typography } from "@material-ui/core"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../components/Loader/Loader"
import { getDeviceTelemetryHistory } from "../../slices/debugging"
import { getDateTime } from "../../utils/helper"

const IoTTestingForm = function (props) {
    const [deviceId, setDeviceId] = useState("")
    const [powerDataHistory, setPowerDataHistory] = useState([])
    const [motorDataHistory, setMotorDataHistory] = useState([])
    const dispatch = useDispatch()
    const { deviceTelemetryHistory: deviceData } = useSelector((state) => state.debugging);

    const onClickGo = () => {
      setPowerDataHistory([]);
      setMotorDataHistory([]);
      if (deviceId) {
        dispatch(getDeviceTelemetryHistory(deviceId, "power"));
        dispatch(getDeviceTelemetryHistory(deviceId, "motor"));
      }
    };

    useEffect(() => {
        
        if (deviceData["status"] == "SUCCESS") {
            if (deviceData["type"] == "power") {
                setPowerDataHistory(deviceData["data"])
            }

            if (deviceData["type"] == "motor") {
                setMotorDataHistory(deviceData["data"])
            }

            if(deviceData["type"] == "alert"){
                alert(deviceData["message"])
            }
        }

        // return function cleanup(){

        // }
        
    }, [deviceData])

    useEffect( () => {
        setMotorDataHistory([])
        setPowerDataHistory([])
    }, [])

    const isFormIsValid = () => {
        const didInt = deviceId !== ""
        if (didInt) {
            return true
        } else {
            return false
        }
    }

    const getLastDatTime = (telemetryData) => {
        if(telemetryData && telemetryData.length){            
            const dt = getDateTime(telemetryData[0]._ts)
            return `${dt["date"]} ${dt["time"]}`
        } else {
            return '--'
        }
    }

    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item md={12} display={"flex"}>
            <TextField
              label="Barcode"
              value={deviceId}
              name="deviceId"
              onChange={(e) => setDeviceId(e.target.value.replaceAll(" ", ""))}
            />
            &nbsp;
            <Button
              variant="contained"
              disabled={!isFormIsValid()}
              onClick={onClickGo}
            >
              Go
            </Button>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h6">Last Received Heartbeat</Typography>
            {powerDataHistory.map((pe) => {
              const dt = getDateTime(pe._ts);
              return (
                <Typography>
                  {dt["date"]} {dt["time"]}
                </Typography>
              );
            })}
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item md={2} lg={2}>
                <Typography variant="h6">Last Power On :</Typography>
              </Grid>
              <Grid item md={3}>
                <Typography>{getLastDatTime(powerDataHistory)}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2} lg={2}>
                <Typography variant="h6">Last Motor On :</Typography>
              </Grid>
              <Grid item md={3} textAlign={"left"}>
                <Typography>{getLastDatTime(motorDataHistory)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {deviceData["status"] === "loading" && <Loader />}
      </Fragment>
    );
}

export default IoTTestingForm