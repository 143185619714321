import { Box, Container, Card, CardHeader, Grid, Alert, Button } from "@material-ui/core"
import React from "react"
import { Helmet } from "react-helmet-async"
import useSettings from "../../../../hooks/useSettings";
import GoogleMapReact from 'google-map-react';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getWardGIS } from "../../../../slices/uploadWardFiles";
import { Document, Page, pdfjs } from 'react-pdf';
import ShowWhen from "../../../common/ShowWhen";
import { Loader } from "../../../Loader/Loader";
// import { MobileStepper } from "@mui/material";
// import { ArrowLeft, ArrowRight } from "@material-ui/icons";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const defaultProps = {
  center: {
    lat: 25.798702414,
    lng: 85.5214789847141
  },
  zoom: 8
};

const GISMap = (props) => {

  // const [map, setMap] = useState(null)
  const { settings } = useSettings();
  const dispatch = useDispatch()
  const [kmlFile, setKmlFile] = useState(null)
  const [pdfFile, setPdfFile] = useState(null)
  const { wardGIS: { data: ward_gis, status: wardGistStatus } } = useSelector((state) => state.uploadWardImage);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onGoogleApiLoaded = ({ map, maps }) => {
    if (kmlFile) {
      new maps.KmlLayer(encodeURI(kmlFile), {
        suppressInfoWindows: true,
        preserveViewport: false,
        map: map
      });

      map.data.forEach((feature) => {
        if (feature.getProperty("type") != "state")
          map.datba.remove(feature);
      });
    }
  }

  useEffect(() => {
    setKmlFile(null)
    dispatch(getWardGIS(props.smsid))
  }, [props.smsid])


  useEffect(() => {
    if (wardGistStatus === "SUCCESS" && ward_gis.length && ward_gis.length > 0) {
      setKmlFile(ward_gis[0]["gisURL"])
      setPdfFile(ward_gis[0]["pdfURL"])
    }

  }, [wardGistStatus, ward_gis])

  return (
    <ShowWhen when={wardGistStatus === "loading"} component={<Loader />} otherwise={
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={!kmlFile && !pdfFile ? 6 : !kmlFile ? 0 : !pdfFile ? 12 : settings.compact ? 6 : 4}
          md={6}
          xl={settings.compact ? 6 : 3}
          xs={12}
        >
          <Card {...props}>
            {/* <CardHeader title="GIS Map" /> */}
            <ShowWhen when={kmlFile && wardGistStatus === "SUCCESS"} component={
              <div style={{ height: '82vh', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyC_-u8-cI5xWvUj49k9Y2-bor3F_k_-__A' }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={onGoogleApiLoaded}
                  options={{ mapId: "8467585508bc0de4" }}
                >
                  {/* {showPopup &&
                  <Marker
                    lat={defaultProps.center.lat}
                    lng={defaultProps.center.lng}
                    showPopup={showPopup}
                    data={currentDist}
                  />} */}
                </GoogleMapReact>
              </div>
            } />
          </Card>
        </Grid>
        <Grid
          item
          lg={!kmlFile && !pdfFile ? 6 : !kmlFile ? 12 : !pdfFile ? 0 : settings.compact ? 6 : 4}
          md={6}
          xl={settings.compact ? 6 : 3}
          xs={12} 
          >
          <Card>
            {/* <CardHeader title="GIS PDF" /> */}
            <ShowWhen when={pdfFile && wardGistStatus === "SUCCESS"} component={
              <div>
                <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
                <p>
                  Page {pageNumber} of {numPages}
                  &nbsp;&nbsp;
                  <button style={{ marginRight: '10px' }} onClick={() => {
                    if (pageNumber - 1 > 0)
                      setPageNumber(pageNumber - 1)
                  }}>prev</button>
                  {/* <Button startIcon={<ArrowLeft />} >
                    Prev
                  </Button>
                  <Button endIcon={<ArrowRight />} >
                    Next
                  </Button> */}
                  <button onClick={() => {
                    if (pageNumber + 1 < numPages + 1)
                      setPageNumber(pageNumber + 1)
                  }}>next</button>
                </p>
              </div>
            } />
            {/* <img src={pdf} width={"100%"} height={"50%"} /> */}
          </Card>
        </Grid>
      </Grid>
    } />

  )
}

export default GISMap