import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { post } from './service';

const initialState = {
    operationalData: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'operationalData',
    initialState,
    reducers: {
        setOperationalData(state, action) {
            state.operationalData = action.payload
        }
    }
});

export const { reducer } = slice;

export const resetOpData = () => async (dispatch) => {
    dispatch(slice.actions.setOperationalData({ ...initialState.operationalData }));
}

export const getOperationalData = (location, dateRange, isOnlyTempData = true) => async (dispatch) => {
    dispatch(slice.actions.setOperationalData({ ...initialState.operationalData, status: "loading" }));
    post(API.base_url + "phed/device_operational_status", { ...location, dateRange, isOnlyTempData })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setOperationalData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setOperationalData({ ...errResponse, status: "failed" }))
        });
};

export default slice;