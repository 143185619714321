import { Box, Button, Container, Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
// import { LocationFilter } from "../../../components/dashboard/shemes";
import { Loader } from "../../../components/Loader/Loader";
import PageHeading from "../../../components/PageHeading";
import { title } from "../../../constants";
import useSettings from "../../../hooks/useSettings";
// import { getBatchMonitorDeviceDetails } from "../../../slices/debugging";
import { uploadTrackerCSV } from "../../../slices/uploadCSV";
import IoTTestingForm from "../IoTTesting";
import ShowWhen from "../../../components/common/ShowWhen";
import { LoaderIcon } from "react-hot-toast";

function MonitorDevice() {
  const settings = useSettings();
  const dispatch = useDispatch();
  // const [allSchemes, setAllSchemes] = useState([]);
  const { uploadTrackerCSVState: uploadCSVState } = useSelector(
    (state) => state.uploadCSV
  );
  // const { schemes } = useSelector(
  //   (state) => state.siteinfo
  // );
  // const { batchMonitorManual: batchDownloadData } = useSelector((state) => state.debugging);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedLocation, setSelectedLocation] = useState(null);
  const [fileToDownload, setFileToDownload] = useState(null);

  // useEffect(() => {
  //   setAllSchemes(schemes["data"]);
  // }, [schemes]);
  const onChangeUploadFile = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file["type"] === "text/csv" ||
        file["type"] === "application/vnd.ms-excel")
    ) {
      setSelectedFile(file);
    } else {
      alert("Please select CSV file");
    }
  };

  useEffect(() => {
    if (uploadCSVState && uploadCSVState.status === "SUCCESS") {
      if (uploadCSVState.data && uploadCSVState.data.filePath) {
        setFileToDownload(uploadCSVState.data);
      }
    }
  }, [uploadCSVState]);

  // const onDownloadClicked = () => {
  //   dispatch(getBatchMonitorDeviceDetails(selectedLocation))
  // }

  return (
    <>
      <Helmet>
        <title>{title.label2} | Monitor Device</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <PageHeading
            title={"Monitor Device"}
            prevPath={[{ name: "Monitor Device" }]}
          />
          <Box sx={{ py: 3 }}>
            <Grid container>
              <IoTTestingForm />
            </Grid>
          </Box>
        </Container>
        <hr />
        <Container>
          <Typography variant="h5">Bulk post for device monitoring:</Typography>
          <Box
            sx={{
              display: "flex",
              width: "50%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid container style={{ marginTop: 10 }}>
              <Grid item md={2} maxWidth={"200px"} minWidth={"200px"}>
                <Button component="label" variant="outlined">
                  {selectedFile ? selectedFile?.name : "Select CSV File"}
                  <input
                    type="file"
                    accept="text/csv"
                    onChange={onChangeUploadFile}
                    hidden
                  />
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button
                  disabled={
                    !selectedFile ||
                    uploadCSVState?.status?.toLowerCase() === "loading"
                  }
                  variant="contained"
                  onClick={() => {
                    dispatch(uploadTrackerCSV(selectedFile));
                  }}
                >
                  <ShowWhen
                    when={uploadCSVState?.status?.toLowerCase() === "loading"}
                    component={
                      <div>
                        <LoaderIcon />
                      </div>
                    }
                    otherwise={"Upload"}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid item md={3}>
              {uploadCSVState.status === "SUCCESS" && fileToDownload && (
                <a href={fileToDownload.filePath}>
                  Download File {fileToDownload.finename}
                </a>
              )}
            </Grid>
          </Box>
          <Grid container style={{ marginTop: 10 }}>
            <a
              target="_blank"
              href={`https://rollzproddocuments.blob.core.windows.net/templates/NewBulkHeartbeatFormat.csv?id=${new Date().getTime()}`}
              rel="noreferrer"
            >
              Download template file
            </a>
          </Grid>
        </Container>
        <br />
        <hr />
        {/* <Container maxWidth={settings.compact ? "xl" : false}>
          <Typography variant="h5">District wise device monitoring:</Typography>
          <Box sx={{ py: 3 }}>
            <LocationFilter
              onChange={setSelectedLocation}
              initialValues={lastSearchFilter}
              schemes={allSchemes}
              downloadExcel={true}
            />
          </Box>
          {selectedLocation && <Button onClick={onDownloadClicked} variant="contained">Download Device Details</Button>}
          <Box sx={{ py: 3 }}>
            {batchDownloadData && batchDownloadData.status === 'SUCCESS' && batchDownloadData.data ? <a target="_blank" rel="noreferrer" href={batchDownloadData.data.filePath}>Download generated file</a> : ""}
          </Box>
        </Container> */}
        {uploadCSVState?.status?.toLowerCase() === "loading" ? <Loader /> : ""}
      </Box>
    </>
  );
}

export default MonitorDevice;
