import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { genearteCert, updateRegData } from "./_service";
import * as Yup from "yup";
let submitType = "quit";
const CertPage = function (props) {
  const [pdfUrl, setPdfUrl] = useState("");
  const [regData, setRegData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (props.deviceData) {
      genearteCert(props.deviceData).then((e) => {
        if (e.statusCode == 200) {
          if (e.data && e.data.filePath) {
            setPdfUrl(e.data.filePath);
          }

          if (e.data && e.data.regData) {
            setRegData(e.data.regData);
          }
        }
      });
    }
  }, []);

  return (
    <div style={{ margin: 20, display: "flex", flexDirection: "column" }}>
      {pdfUrl ? (
        <div>
          <embed src={pdfUrl} width="100%" height="600px" /> <br />
          <Formik
            initialValues={{
              simNumber: "",
              carrierName: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              simNumber: Yup.string()
                .min(19)
                .max(20)
                .required("SIM Number is required"),
              carrierName: Yup.string().required("Select Carrier Name"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                updateRegData({
                  ...regData,
                  simNumber: values.simNumber,
                  carrierName: values.carrierName,
                });
                if (submitType == "quit") {
                  navigate("/");
                } else {
                  props.onReset();
                }
              } catch (E) {}
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...props}>
                <TextField
                  value={values.simNumber}
                  label="Scan SIM Number"
                  autoFocus
                  error={Boolean(touched.simNumber && errors.simNumber)}
                  helperText={touched.simNumber && errors.simNumber}
                  margin="normal"
                  name="simNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                /> 
                <FormControl style={{marginTop:15, marginLeft:20}}
                  variant="outlined"
                  error={Boolean(touched.carrierName && errors.carrierName)}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Carrier Name
                  </InputLabel>
                  <Select
                    native
                    name="carrierName"
                    value={values.carrierName}
                    onChange={handleChange}
                    label="Carrier Name"
                    inputProps={{
                      name: "carrierName",
                      id: "outlined-carrier_name",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {/* <option value={"BSNL"}>BSNL</option> */}
                    <option value={"AIRTEL"}>AIRTEL</option>
                    {/* <option value={"VODAPHONE"}>VODAPHONE</option> */}
                  </Select>
                  {Boolean(touched.carrierName && errors.carrierName) ? (
                    <FormHelperText>{errors.carrierName}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
                <div>
                  <Button
                    type="submit"
                    style={{ marginTop: 10 }}
                    variant="contained"
                    onClick={() => {
                      submitType = "quit";
                    }}
                  >
                    Save and Quit
                  </Button>
                  <Button
                    type="submit"
                    style={{ marginTop: 10, marginLeft: 10 }}
                    variant="contained"
                    onClick={() => {
                      submitType = "continue";
                    }}
                  >
                    Save and Continue
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      ) : (
        <span>Loading...</span>
      )}
    </div>
  );
};

export default CertPage;
