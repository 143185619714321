import {
    Grid,
    Typography,
    Breadcrumbs,
    Link
  } from "@material-ui/core";
import ChevronRight from "../icons/ChevronRight";
import { NavLink as RouterLink } from "react-router-dom";

function PageHeading({ title, prevPath }) {
  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item>
        <Typography color="textPrimary" variant="h5">
          {title}
        </Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight fontSize="small" />}
          sx={{ mt: 1 }}
        >
          <Link
            color="textPrimary"
            component={RouterLink}
            to="/overview"
            variant="subtitle2"
          >
            Home
          </Link>
          {prevPath.map((pp, k) => {
            return (
              <Typography key={k} color="textSecondary" variant="subtitle2">
                {pp.name}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}

export default PageHeading;
