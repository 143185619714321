import React from "react";
import { Button } from "@material-ui/core";
import toast from "react-hot-toast";

function FileSelector({
  selectedFile,
  setSelectedFile,
  acceptedTypes = [],
  variant = "outlined",
  title = "Select File",
}) {
  const onChangeUploadFile = (e) => {
    const file = e.target.files[0];
    const accepted = acceptedTypes.includes(file.type);
    if (file && accepted) {
      setSelectedFile(file);
    } else {
      toast.error("Please select a valid file type!", { duration: 2500 });
    }
  };

  return (
    <Button component="label" variant={variant}>
      {selectedFile ? selectedFile.name : title}
      <input
        type="file"
        onChange={onChangeUploadFile}
        hidden
        accept={acceptedTypes.join(",")}
      />
    </Button>
  );
}

export default FileSelector;
