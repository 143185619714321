import { Box, Button, Paper, Typography } from "@material-ui/core"
import { downloadFile, formateDateTime } from "../utils/helper"
import { ExportRegisteredFirmware } from "../actions/registeredDevices"
import { useEffect, useState } from "react"
import ShowWhen from "./common/ShowWhen"
import { Loader } from "./Loader/Loader"
import { FileDownloadOutlined } from "@material-ui/icons"


const FirmwareExports = ({ filter }) => {
    const [loading, setLoading] = useState(false)
    const [regData, setRegData] = useState([])

    const handleExport = async () => {
        try {
            setLoading(true)
            const exportRes = await ExportRegisteredFirmware({ filter: filter })
            if (exportRes?.data?.reportUrl) {
                downloadFile(exportRes?.data?.reportUrl, "REGISTERED_FIRMWARE_DEVICES")
            } else if (exportRes?.data?.regData?.length) {
                setRegData(exportRes?.data?.regData)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (regData.length) {
            const rws = regData.map((rx, i) => {
                const dt = formateDateTime(rx._ts);
                return {
                    slno: i + 1,
                    barcode: rx?.barcode ? rx?.barcode : "-NA-",
                    imei: rx?.imei ? rx?.imei : "-NA-",
                    deviceId: rx?.deviceId ? rx?.deviceId : "-NA-",
                    simNumber: rx?.simNumber ? rx?.simNumber : "-NA-",
                    carrierName: rx?.carrierName ? rx?.carrierName : "-NA-",
                    user_name: rx?.user_name ? rx?.user_name : "-NA-",
                    mobile_number: rx?.mobile_number ? rx?.mobile_number : "-NA-",
                    created_datetime: dt ? `"${dt}"` : "-NA-",
                    lastHearBeatDate: rx?.lastHearBeatDate ? `"${rx?.lastHearBeatDate}"` : "-NA-",
                    certificate_path: rx?.certificate_path ? rx?.certificate_path : "-NA-",
                };
            });

            let keys = Object.keys(rws[0]).map(k => k.toUpperCase());
            // Create the CSV header row
            let csvContent = "data:text/csv;charset=utf-8,"
                + keys.join(",") + "\n"

            // Add the data rows to the CSV content
            csvContent += rws.map(e => Object.values(e).join(",")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `REGISTERED_DEVICE_LIST_${new Date()}.csv`);
            document.body.appendChild(link);
            link.click();
        }

    }, [regData]);

    return (
        <Paper sx={{
            pl: 2,
            pr: 2,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }}>
            <Box sx={{
                width: "100%",
                minHeight: 50,
                maxHeight: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }} >
                <Typography variant="h6" >
                    Firmware Details:
                </Typography>
                <Button variant='outlined' startIcon={<FileDownloadOutlined />} onClick={handleExport}>Export</Button>
            </Box>
            <ShowWhen when={loading} component={<Loader text={"Generating report..."} />} />
        </Paper>
    )
}

export default FirmwareExports