import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, TextField } from "@material-ui/core";
import ShowWhen from "./ShowWhen";
import { useState } from "react";
import { SearchOutlined } from "@material-ui/icons";

export default function CustomFilter({ filterProperties, setFilter, filter, handleSearch, disable = false }) {
  const [filterProperty, setFilterProperty] = useState("");
  const [filterValue, setFilterValue] = useState("")
  const handleFilterByChange = (event) => {
    const { value } = event.target;
    setFilter({ [value]: "" });
    setFilterProperty(String(value) || "");
  };

  const handleFilterValueChange = (event) => {
    const { name, value } = event.target;
    setFilterValue(String(value))
    setFilter({ ...filter, [name]: value });
  };

  return (
    <div>
      <Box component="form" sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <FormControl sx={{ marginRight: 2, marginBottom: 2, minWidth: 150 }}>
          <InputLabel id="demo-dialog-select-label">Filter By</InputLabel>
          <Select
            size="medium"
            name="filterProperty"
            labelId="demo-dialog-select-label"
            id="demo-dialog-select"
            value={filterProperty}
            onChange={handleFilterByChange}
            input={<OutlinedInput label="Filter By" />}
          >
            <MenuItem value="">
              <em>-none-</em>
            </MenuItem>
            {filterProperties.map((p) => (
              <MenuItem key={p} value={p}>
                {p.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ShowWhen
          when={filterProperty?.length > 0}
          component={
            <>
              <FormControl
                sx={{ marginRight: 2, marginBottom: 2, minWidth: 250 }}
              >
                <TextField
                  required
                  size="medium"
                  id="outlined-required"
                  label="Required"
                  placeholder={`Enter your ${filterProperty} here`}
                  name={String(filterProperty)}
                  onChange={handleFilterValueChange}
                  value={filter?.[filterProperty]}
                />
              </FormControl>
              <FormControl
                sx={{ marginRight: 2, marginBottom: 2, }}
              >
                <Button disabled={filterValue.length < 3} size="large" variant="contained" startIcon={<SearchOutlined />} onClick={handleSearch}>
                  Search
                </Button>
              </FormControl>
            </>
          }
        />
      </Box>
    </div>
  );
}
