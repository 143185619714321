import Chart from 'react-apexcharts';
import { Box, Grid, Button, Card, CardContent, CardHeader } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSchemeHealth } from '../../../slices/siteInfo';
import { convertToFilterDate, historyDate } from '../../../utils/helper';
import { Loader } from '../../Loader/Loader';

const SchemeHealth = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch()
  const { schemeHealth: scheme_health } = useSelector((state) => state.siteinfo);
  const [schemeElecData, setSchemeElecData] = useState({})
  const [chartSeries, setChartSeries] = useState([])

  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [fromDateOrginal, setFromDateOrginal] = useState(null)
  const [toDateOrginal, setToDateOrginal] = useState(null)
  const [filterType, setFilterType] = useState(null)

  const handleFromDateChange = (e) => {
    setFromDateOrginal(e.target.value)
    setFromDate(convertToFilterDate(e.target.value))
  }
  
  const handleToDateChange = (e) => {
    setToDateOrginal(e.target.value)
    setToDate(convertToFilterDate(e.target.value, true))
  }

  const submitDateFilter = () => {
    if(fromDate && toDate){
      setFilterType("daterange")
      dispatch(getSchemeHealth(props.schemeId, [fromDate, toDate]))
    }
  }

  const submitReset = () => {
    setFilterType(null)
    dispatch(getSchemeHealth(props.schemeId, historyDate(4)))
  }

  useEffect(() => {
    if (props.schemeId)
      dispatch(getSchemeHealth(props.schemeId, historyDate(4)))
    // else
    // alert("DeviceId is empty")
  }, [props.schemeId])

  const chartOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: true
      }
    },
    colors: ['#13affe', '#fbab49', '#5249fb'],
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    legend: {
      labels: {
        colors: theme.palette.text.secondary
      },
      show: true
    },
    plotOptions: {
      bar: {
        columnWidth: '40%'
      }
    },
    stroke: {
      colors: ['transparent'],
      show: true,
      width: 2
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: 'dd MMM yyyy',
        datetimeUTC: process.env.NODE_ENV == 'production'
      }
    },
    yaxis: [
      {
        seriesName: "Electricity availability",
        show: false
      },
      {
        seriesName: "Electricity availability",
        title: {
          text: "Duration (Hrs)"
        },
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0
        }
      },
      {
        seriesName: "Water consumption",
        opposite: true,
        title: {
          text: "Water Volume (KL)"
        }
      }
    ]
  };

  useEffect(() => {
    if (scheme_health["status"] == "SUCCESS") {
      setSchemeElecData(scheme_health["data"])
    }
  }, [scheme_health])

  useEffect(() => {
    let data = schemeElecData
    let keys = Object.keys(data)
    const chartSeriesTemp = [
      {
        data: [],
        name: 'Electricity availability'
      },
      {
        data: [],
        name: 'Motor Running'
      },
      {
        data: [],
        name: 'Water consumption'
      }
    ]

    let eleData = []
    let motorData = []
    let waterConsumption = []
    for (let i = 0; i < keys.length; i++) {
      eleData.push({ x: parseInt(keys[i]) * 1000, y: data[keys[i]]["electricityAvalability"] })
      motorData.push({ x: parseInt(keys[i]) * 1000, y: data[keys[i]]["motorRunningTime"] })
      waterConsumption.push({ x: parseInt(keys[i]) * 1000, y: data[keys[i]]["waterConsumption"] / 1000 })
    }
    chartSeriesTemp[0]["data"] = eleData
    chartSeriesTemp[1]["data"] = motorData
    chartSeriesTemp[2]["data"] = waterConsumption
    setChartSeries(chartSeriesTemp)
  }, [schemeElecData])

  const cardHead = filterType? "" : "(Last 5 days)"
  return (
    <>
      <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Grid>
          <CardHeader title={`Scheme Health ${cardHead}`} />
        </Grid>
        <Grid>
          <input style={{filter:"invert(95%)", fontFamily:"Roboto"}} type="date" onChange={handleFromDateChange} />
          <input style={{filter:"invert(95%)", fontFamily:"Roboto"}} type="date" onChange={handleToDateChange} />
          <Button onClick={() => submitDateFilter()}>Go</Button>
          <Button onClick={() => submitReset()}>Reset</Button>
        </Grid>
      </Box>
      <Chart
        height={400}
        options={chartOptions}
        series={chartSeries}
        type="bar"
      />
      {scheme_health["status"] == "loading" ? <Loader /> : ""}
    </>
  );
};

export default SchemeHealth;
