import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { resetUploadStatus, uploadCSV } from "../../../slices/uploadCSV";
import { useEffect, useState } from "react";
import { getDistrict, getDivision } from "../../../slices/siteInfo";
import { Loader } from "../../../components/Loader/Loader";
import { checkIfRoleExist } from "../../../utils/helper";
import toast from "react-hot-toast";

const Admin = () => {
  const dispatch = useDispatch();

  const [allDist, setAllDist] = useState([]);
  // const [allBlock, setAllBlock] = useState([])
  // const [allPanchayat, setAllPanchayat] = useState([])
  // const [allWard, setAllWard] = useState([])
  const [allDivision, setAllDivision] = useState([]);
  const [calledUpload, setCalledUpload] = useState(false);

  const { district, division } = useSelector((state) => state.siteinfo);
  const { uploadCSV: uploadCSVStatus } = useSelector(
    (state) => state.uploadCSV
  );
  const [currentDistrict, setCurrentDistrict] = useState(0);
  const [currentDivision, setCurrentDivision] = useState(0);

  useEffect(() => {
    if (uploadCSVStatus.status == "success") {
      toast(
        "Success. It takes some time to reflect in UI. As it is processing CSV!"
      );
      dispatch(resetUploadStatus());
    }
  }, [uploadCSVStatus.status]);

  const onChange = function (e) {
    if (!calledUpload) {
      if (currentDistrict > 0) {
        if (currentDivision > 0) {
          const file = e.target.files[0];
          if (
            file &&
            (file["type"] == "text/csv" ||
              file["type"] == "application/vnd.ms-excel")
          ) {
            const cDistObj = allDist.filter((e) => e.id == currentDistrict);
            const cDivObj = allDivision.filter((e) => e.id == currentDivision);
            if (cDistObj.length) {
              dispatch(
                uploadCSV(
                  e.target.files[0],
                  currentDistrict,
                  cDistObj[0]["name"],
                  currentDivision,
                  cDivObj[0]["name"]
                )
              );
            } else toast("cDistObj length 0");
          } else {
            toast.error("Please select CSV file");
          }
        } else {
          toast.error("Please select division");
        }
      } else {
        toast.error("Please select district");
      }
    } else {
      setCalledUpload(true);
    }
  };

  const handleDistrictChange = function (e) {
    setCurrentDistrict(e.target.value);
    dispatch(getDivision(e.target.value));
  };

  const handleDivisionChange = function (e) {
    setCurrentDivision(e.target.value);
  };

  useEffect(() => {
    dispatch(getDistrict());
  }, []);

  useEffect(() => {
    if (district.data) setAllDist(district.data);
  }, [district]);

  useEffect(() => {
    if (division.data) setAllDivision(division.data);
  }, [division]);

  // useEffect(() => {
  //     if (block.data)
  //         setAllBlock(block.data)
  // }, [block])

  // useEffect(() => {
  //     if (block.data)
  //         setAllBlock(block.data)
  // }, [block])

  // useEffect(() => {
  //     if (panchayat.data)
  //         setAllPanchayat(panchayat.data)
  // }, [panchayat])

  // useEffect(() => {
  //     if (ward.data)
  //         setAllWard(ward.data)
  // }, [ward])

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Card>
              <CardHeader title="Upload file (CSV)" />
              <CardContent>
                {!checkIfRoleExist(localStorage.getItem("USER_TYPE"), [
                  "APP_DATA_MANAGEMENT",
                  "APP_SUPER_ADMIN",
                ]) && (
                  <span style={{ color: "red" }}>
                    Only Admin user can upload ward details
                  </span>
                )}

                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Grid container spacing={3}>
                      <Grid item md={2}>
                        <TextField
                          fullWidth
                          label="District"
                          name="district"
                          onChange={handleDistrictChange}
                          select
                          disabled={
                            !checkIfRoleExist(
                              localStorage.getItem("USER_TYPE"),
                              ["APP_DATA_MANAGEMENT", "APP_SUPER_ADMIN"]
                            )
                          }
                          SelectProps={{ native: true }}
                          value={currentDistrict.id}
                          variant="outlined"
                        >
                          {[{ id: 0, name: "" }, ...allDist].map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={2}>
                        <TextField
                          fullWidth
                          label="Division"
                          name="division"
                          onChange={handleDivisionChange}
                          select
                          disabled={
                            !checkIfRoleExist(
                              localStorage.getItem("USER_TYPE"),
                              ["APP_DATA_MANAGEMENT", "APP_SUPER_ADMIN"]
                            )
                          }
                          SelectProps={{ native: true }}
                          value={currentDivision.id}
                          variant="outlined"
                        >
                          {[{ id: 0, name: "" }, ...allDivision].map(
                            (category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            )
                          )}
                        </TextField>
                      </Grid>
                      <Grid item md={4}>
                        <Button
                          variant="contained"
                          component="label"
                          size="large"
                          sx={{ mr: 3 }}
                          disabled={
                            !checkIfRoleExist(
                              localStorage.getItem("USER_TYPE"),
                              ["APP_DATA_MANAGEMENT", "APP_SUPER_ADMIN"]
                            )
                          }
                        >
                          Choose CSV File
                          <input
                            type="file"
                            onChange={onChange}
                            hidden
                            accept="text/csv, application/vnd.ms-excel"
                          />
                        </Button>

                        <a
                          href={`https://rollzproddocuments.blob.core.windows.net/templates/UploadNewDeviceDataFormate.csv?id=${new Date().getTime()}`}
                          rel="noreferrer"
                        >
                          Download Template
                        </a>
                      </Grid>
                      <Grid item md={12}>
                        {uploadCSVStatus.status &&
                        uploadCSVStatus.status != "failed"
                          ? uploadCSVStatus.status
                          : ""}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {uploadCSVStatus.status == "loading" && <Loader />}
    </>
  );
};

export default Admin;
