import { API } from "../config";
import { get, post } from "../slices/service";
import toast from "react-hot-toast";

export const createCallingWard = async (payload) => {
  try {
    const cWRes = await post(API.base_url + "phed/calling-ward", payload);
    if (cWRes.statusCode === 201 || cWRes.statusCode === 200) {
      toast.success(cWRes?.message || "Successfully saved!", {
        duration: 4000,
      });
      return cWRes;
    } else if (cWRes.statusCode === 400) {
      toast.error(cWRes?.message || "Missing some fields!", {
        duration: 4000,
      });
      return;
    } else {
      throw new Error(cWRes.message || "Error in saving calling-ward!");
    }
  } catch (error) {
    toast.error(
      error.message || "Error occurred while saving the calling-ward!",
      {
        duration: 4000,
      }
    );
  }
};

export const getCallingWards = async ({
  district,
  division,
  block,
  panchayat,
  dateRange = null,
}) => {
  try {
    if (!district || !dateRange) {
      throw new Error("User id, district or date Range is missing");
    }
    const callingWardsRes = await get(API.base_url + "phed/calling-wards", {
      district,
      division,
      block,
      panchayat,
      date_range: dateRange,
    });
    if (callingWardsRes.statusCode === 200) {
      return callingWardsRes;
    } else if (callingWardsRes.statusCode === 404) {
      return;
    } else {
      throw new Error(
        callingWardsRes.message ||
          "Error occurred while getting the calling wards!"
      );
    }
  } catch (error) {
    toast.error(
      error.message || "Error occurred while getting the calling wards!",
      {
        duration: 4000,
      }
    );
  }
};
