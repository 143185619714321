import {
  Box,
  Grid,
  Typography,
  Container,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "../../icons/ChevronRight";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import useSettings from "../../hooks/useSettings";
import { LocationFilter } from "../../components/dashboard/shemes";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components/Loader/Loader";
import CallingList from "../../components/CallingList";
import { get24HoursDateRange, setDistrictName } from "../../utils/helper";
import { getCallingWards } from "../../actions/calling";
import { setFilters } from "../../slices/siteInfo";

const Calling = (props) => {
  const { settings } = useSettings();
  const [schemeLocation, setSchemeLocation] = useState({});
  const { district, lastSearchFilter } = useSelector((state) => state.siteinfo);
  const [distName, setDistName] = useState("");
  const [loading, setLoading] = useState(false);
  const [callingWards, setCallingWards] = useState([]);
  const dispatch = useDispatch();

  const fetchCallingWards = async ({
    district,
    division,
    block,
    panchayat,
  }) => {
    try {
      setLoading(true);
      setCallingWards([]);
      const dateRange = get24HoursDateRange();
      const fetchedComments = await getCallingWards({
        district,
        division,
        block,
        panchayat,
        dateRange,
      });
      setCallingWards(fetchedComments.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeLocation = (data) => {
    const keys = Object.keys(data);
    let allReset = true;
    for (let i = 0; i < keys.length; i++) {
      allReset = allReset && !data[keys[i]];
    }

    if (allReset) {
      setCallingWards([]);
    }
    setSchemeLocation(data);
  };

  useEffect(() => {
    setDistrictName({ district, schemeLocation, setDistName });
  }, [district, schemeLocation]);

  useEffect(() => {
    if (
      schemeLocation.district ||
      schemeLocation.block ||
      schemeLocation.panchayat ||
      schemeLocation.ward
    ) {
      let d = {};
      if (schemeLocation.district) {
        d["district"] = schemeLocation.district;
        setCallingWards([]);
      }
      if (schemeLocation.division) {
        d["division"] = schemeLocation.division;
        setCallingWards([]);
      }
      if (schemeLocation.block) {
        d["block"] = schemeLocation.block;
        setCallingWards([]);
      }
      if (schemeLocation.panchayat) {
        d["panchayat"] = schemeLocation.panchayat;
      }
      if (schemeLocation.ward) {
        d["ward"] = schemeLocation.ward;
      }

      if (schemeLocation.district) {
        fetchCallingWards(d);
      }
      dispatch(setFilters(schemeLocation));
    }
  }, [schemeLocation]);

  return (
    <>
      <Helmet>
        <title>Har Ghar Nal Jal Yojna | Calling Wards</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Calling
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/overview"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Calling
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ py: 3 }}>
            <LocationFilter
              onChange={onChangeLocation}
              initialValues={lastSearchFilter}
              showDistrictOnly={true}
              removeOptions={["division"]}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xl={12} md={12} xs={12}>
              <CallingList
                sx={{ height: "100%" }}
                schemes={callingWards}
                fileName={distName || ""}
                distName={"calling_Wards"}
                loading={loading}
                disableExport={false}
                title="Calling details:"
                noRowsLabel="calling wards not found :)"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {loading && <Loader />}
    </>
  );
};

export default Calling;
