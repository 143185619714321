const { BASE_URL } = require("../config");

const request = (url, params = {}, method = 'GET', type = null) => {
    let accessToken = localStorage.getItem("accessToken")

    let options = {
        method,
        headers: {
            "Content-type": "application/json",
            'Authorization': 'Bearer ' + accessToken
        }
    };

    if ('GET' === method) {
        url += '?' + (new URLSearchParams(params)).toString();
    } else if (type === "file") {
        delete options["headers"]["Content-type"]
        options["headers"]["redirect"] = 'follow'
        const formData = new FormData()
        const paramKeys = Object.keys(params)
        for (let i = 0; i < paramKeys.length; i++) {
            formData.append(paramKeys[i], params[paramKeys[i]])
        }
        // formData.append("districtId", params["dist"])
        // formData.append("name", params["name"])
        options.body = formData;
    } else {
        options.body = JSON.stringify(params);
    }

    return fetch(url, options).then(response => {
        if (response.statusText === "Unauthorized") {
            localStorage.clear()
            window.location.href = BASE_URL
        } else {
            return response.json()
        }
    });
};

const get = (url, params) => request(url, params, 'GET');
const post = (url, params) => request(url, params, 'POST');
const filepost = (url, data) => request(url, data, 'POST', "file");

module.exports = {
    get: get,
    post: post,
    filepost: filepost
}