import { useEffect, useState } from 'react';
import {
  Box
} from '@material-ui/core';
import ChevronRightIcon from '../../icons/ChevronRight';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DebuggingForm from '../../components/debugging/DebuggingForm';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceTelemetry } from '../../slices/debugging';
import { Loader } from '../../components/Loader/Loader';
import DebuggingControlUI from '../../components/debugging/DebuggingControlUI';

const Debugging = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch()
  const [deviceTelemetryData, setDeviceTelemetryData] = useState(null)
  const [deviceId, setDeviceId] = useState("")
  const [prevDeviceTelemetryData, setPrevDeviceTelemetryData] = useState(null)
  const { deviceTelemetry: deviceData } = useSelector((state) => state.debugging);

  const onFormSubmit = (inputDid, type = "initial") => {
    setDeviceId(inputDid)
    dispatch(getDeviceTelemetry(inputDid, type))
  }

  useEffect(() => {
    if (deviceData["status"] == "SUCCESS" && deviceData["data"]) {
      setDeviceTelemetryData({ ...deviceData["data"], reqType: deviceData["reqType"] })
      if (deviceData["reqType"] == "initial")
        setPrevDeviceTelemetryData(deviceData["data"])
    } else {
      // if(deviceData["type"] == "alert"){
      //   alert(deviceData["message"])
      // }
      setDeviceTelemetryData(null)
    }
    
  }, [deviceData])

  return (
    <>
      <Box>
          <Box sx={{ py: 3 }}>
            <DebuggingForm onSubmit={onFormSubmit} />
          </Box>
          {deviceTelemetryData &&
            <Box sx={{ py: 3 }} >
              <DebuggingControlUI data={deviceTelemetryData} prevData={prevDeviceTelemetryData} deviceId={deviceId} onDataRequest={onFormSubmit} />
            </Box>
          }
      </Box>
      {deviceData["status"] == "loading" && <Loader />}
    </>
  );
};

export default Debugging;
