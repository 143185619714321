import {
  Button,
  Card,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  DataGrid,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { removeAssignee } from "../../../slices/userManagement";
import CustomNoRowsOverlay from "../../../components/common/CustomNoRowsOverlay";
import CustomToolbar from "../../../components/common/CustomToolbar";
import { getInstallationFieldObj } from "../../../utils/helper";

const useStyles = makeStyles({
  root: {
    "& .scheme-data-table": {
      fontWeight: "bold",
    },
  },
});


const WardList = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);

  

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      props.schemes.map((page, i) => {
        tableDataTemp.push({
          ...page,
          ...page.other,
          nicId: page.nicdata ? page.nicdata.NICDeviceId : "",
          did: page.other.deviceid,
          wname: page.name,
          assignedOn: page.installation_user_mapping?page.installation_user_mapping.date:"",
          assignedTo: page.installation_user_mapping?page.installation_user_mapping.name:"",
          action: page.installation_user_mapping?page:null,
          id: page.id,
          slno: i + 1,
          completed_status: page,
        });
      });
    }

    setTableData(tableDataTemp);
  }, [props.schemes]);

  const handleClick = (data) => {
    if(data && data.installation_user_mapping)
      dispatch(removeAssignee({wardid: data.id, userid: data.installation_user_mapping.id}))
    else 
      alert("Details not found!!")
  }

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 130,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      hide: true,
      width: 130,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "districtName",
      headerName: "District",
      width: 130,
      sortable: false,
    },
    {
      field: "division",
      headerName: "Division",
      width: 130,
      sortable: false,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: false,
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: false,
    },
    {
      field: "wname",
      headerName: "Ward",
      width: 130,
      sortable: false,
    },
    {
      field: "subScheme",
      headerName: "Scheme Type",
      width: 130,
      sortable: false,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      width: 130,
    },
    {
      field: "assignedOn",
      headerName: "Assigned On",
      width: 130,
      sortable: false,
    },
    {
      field: "completed_status",
      headerName: "Installation Status",
      width: 140,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        const obj = getInstallationFieldObj(params["formattedValue"])
        return <span style={{ color: obj["color"], backgroundColor: obj["bgColor"] ? obj["bgColor"] : "" }}>{obj["text"]}</span>
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      sortable: false,
      renderCell: (params) => {
        let actionWidget = <Button onClick={() => handleClick(params["formattedValue"])}>Remove Assignee</Button>
        return params["formattedValue"]?actionWidget:<></>
      }
    },
  ];

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }} className={classes.root}>
        <DataGrid
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = tableData.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            props.onSelect(selectedRowData);
          }}
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          isRowSelectable={(params) => !params.row.installation_user_mapping}
          componentsProps={{
            toolbar: { title: props.title, fileName: props.fileName, locationName: props.distName },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{ noRowsLabel: "No Records  Found :)" }}
        />
      </div>
    </Card>
  );
};

export default WardList;
