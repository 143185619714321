import { API } from "../config";
import createResourceId from "../utils/createResourceId";
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from "../utils/jwt";
import wait from "../utils/wait";

const users = [
  {
    id: "5e86809283e28b96d2d38537",
    avatar: "",
    email: "",
    name: "PHED User",
    password: "Password123!",
    plan: "Premium",
  },
];

class AuthApi {
  async login({ email, password }) {
    await wait(500);

    return new Promise((resolve, reject) => {
      try {
        // Find the user

        const options = {
          method: "POST",
          body: JSON.stringify({
            userName: email,
            password: password,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };

        fetch(API.base_url + "auth/signin", options)
          .then((rawData) => rawData.json())
          .then(function (response) {
            response = response || {};
            resolve(response.data);
          })
          .catch(function (errResponse) {
            reject(new Error("Internal server error"));
          });
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async register(data) {
    return new Promise((resolve, reject) => {
      try {
        const options = {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          },
        };
        
        fetch(API.base_url + "auth/signup", options)
          .then((rawData) => rawData.json())
          .then(function (response) {
            response = response || {};
            resolve(response);
          })
          .catch(function (errResponse) {
            reject(new Error("Internal server error"));
          });
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  me(token) {
    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        const options = {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
        // Find the user
        //const user = users.find((_user) => _user.id === userId);
        fetch(API.base_url + "auth/user", options)
          .then((rawData) => rawData.json())
          .then(function (response) {
            response = response || {};
            resolve(response);
          })
          .catch(function (errResponse) {
            reject(new Error("Internal server error"));
          });
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const authApi = new AuthApi();
