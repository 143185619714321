import { useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import {
  Box,
  Card,
  CardContent,
  Container,
  Button,
  Divider,
  Grid,
  Link,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormHelperText,
  FormGroup,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-datepicker/dist/react-datepicker.css";
import AuthBanner from "../../components/authentication/AuthBanner";
import { LoginJWT } from "../../components/authentication/login";
import Logo from "../../components/Logo";
import useAuth from "../../hooks/useAuth";
// import gtm from "../../lib/gtm";
import login_screen_image from "../../images/login_screen_image.png";
import logo from "../../images/logo.png";
import {
  geronEmpTypes,
  mobileAppUserTyle,
  mobileAppUserType,
  title,
  userIdentityTypes,
} from "../../constants";
import { useState } from "react";
import { Formik } from "formik";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { generateOTP, verifyOtpAndUpdate } from "../../slices/userManagement";
import { useDispatch, useSelector } from "react-redux";

const SignUp = () => {
  const { register, regStatus } = useAuth();
  const [canLogin, setCanLogin] = useState(false);
  const dispatch = useDispatch();
  const [respMessage, setRespMessage] = useState({
    type: null,
    message: "",
    fields: [],
  });
  
  const { logout } = useAuth();
  const navigate = useNavigate()
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [resendOtpEnabled, setResendOtpEnabled] = useState(false);
  const { verifyOtpResponse, generateOTPResponse } = useSelector((state) => state.userManagement);
  // const { generateOTPResponse } = useSelector((state) => state.userManagement);
  const [votpRespMessage, setVotpRespMessage] = useState({
    type: null,
    message: "",
    fields: [],
  });

  const [resendOtpState, setResetOtpState] = useState(false)

  useEffect(() => {
    let message = "",
      type = null,
      fields = [];
    if (regStatus) {
      if (regStatus.statusCode == 200) {
        setShowOTP(true);
        setTimeout(() => {
          setResendOtpEnabled(true)
        }, 4000)
        message = regStatus.message + ".";
        type = "success";
      } else if (regStatus.statusCode == 400) {
        message = regStatus.message;
        type = "error";
        if (regStatus.errors && regStatus.errors.length)
          fields = regStatus.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = regStatus.message;
        type = "error";
      }

      setRespMessage({ type, message, fields });
    }
  }, [regStatus]);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  useEffect(() => {
    let message = "",
      type = null,
      fields = [];
    if (generateOTPResponse) {
      if (generateOTPResponse.statusCode == 200) {
        setResetOtpState(true)
        setTimeout(() => {
          setResetOtpState(false)
        }, 60000)
        message = generateOTPResponse.message + ".";
        type = "success";
      } else if (generateOTPResponse.statusCode == 400) {
        message = generateOTPResponse.message;
        type = "error";
        if (generateOTPResponse.errors && generateOTPResponse.errors.length)
          fields = generateOTPResponse.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = generateOTPResponse.message;
        type = "error";
      }
    }
  });
  
  useEffect(() => {
    let message = "",
      type = null,
      fields = [];

    if (verifyOtpResponse) {
      if (verifyOtpResponse.statusCode == 200) {
        message = verifyOtpResponse.message + ". Redirecting to login page in 4 seconds.";
        type = "success";
        setCanLogin(true);
        setTimeout(async () => {
          await logout();
          navigate("/");
        }, 5000);
      } else if (verifyOtpResponse.statusCode == 400) {
        message = verifyOtpResponse.message;
        type = "error";
        if (verifyOtpResponse.errors && verifyOtpResponse.errors.length)
          fields = verifyOtpResponse.errors.map((re) => {
            return <li>{re.msg}</li>;
          });
      } else {
        message = verifyOtpResponse.message;
        type = "error";
      }
    }

    setVotpRespMessage({ type, message, fields });
  }, [verifyOtpResponse]);

  return (
    <>
      <Helmet>
        <title>{title.label2}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AuthBanner />
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          marginTop={5}
        >
          <Card
            sx={{
              width: { xs: 1, sm: 1, md: 1 / 3, lg: 1 / 3, xl: 1 / 3 },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Typography variant="h5" component="h2">
                      Create Your Rollz/Geron Dashboard Account
                    </Typography>
                  </Box>
                  <Box sx={{}}>
                    <Formik
                      initialValues={{
                        email: "",
                        name: "",
                        mobile_number: "",
                        organization: "",
                        dob: "",
                        user_identity: "",
                        emp_type: [],
                        other_user_type: "",
                        mobile_sub_type: "",
                        npassword: "",
                        cpassword: "",
                        submit: null,
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email("Must be a valid email")
                          .max(255, "Max 255 length allowed")
                          .required("Email is required"),
                        name: Yup.string()
                          .max(255)
                          .required("Name is required"),
                        mobile_number: Yup.string()
                          .required("Mobile number is required")
                          .matches(/^[0-9]{10}$/, "Must be 10 digits"),
                        dob: Yup.date().required("DOB is required"),
                        organization: Yup.string()
                          .max(255)
                          .required("Organization name is required"),
                        user_identity: Yup.string().required(
                          "User Indentity is required"
                        ),
                        emp_type: Yup.array().when("user_identity", {
                          is: (user_identity) => user_identity == "GERON_",
                          then: Yup.array()
                            .of(Yup.string())
                            .min(1, "This field is required"),
                        }),
                        other_user_type: Yup.string().when("user_identity", {
                          is: (user_identity) => user_identity == "OTHER",
                          then: Yup.string().required("This field is required"),
                        }),
                        mobile_sub_type: Yup.string().when("emp_type", {
                          is: (et) => et.includes("MOBILE_APP_USER"),
                          then: Yup.string().required("This field is required"),
                        }),
                        npassword: Yup.string()
                          .required("Password is required")
                          .min(
                            8,
                            "Use 8 for more charecters with mix of letters, numbers & symbols"
                          ),
                        cpassword: Yup.string()
                          .oneOf(
                            [Yup.ref("npassword"), null],
                            "Passwords must match"
                          )
                          .required("Confirm password is required"),
                      })}
                      onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting }
                      ) => {
                        let updatedValues = {
                          ...values,
                          dob: dayjs(values.dob).format("DD/MM/YYYY"),
                        };
                        setMobileNumber(updatedValues["mobile_number"]);
                        try {
                          await register(updatedValues);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values,
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                          <Grid container spacing={2} style={{ marginTop: 0 }}>
                            <Grid item md={6}>
                              <TextField
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                autoFocus
                                fullWidth
                                label="Name"
                                margin="normal"
                                name="name"
                                variant="outlined"
                                value={values.name}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                error={Boolean(
                                  touched.mobile_number && errors.mobile_number
                                )}
                                helperText={
                                  touched.mobile_number && errors.mobile_number
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                label="Mobile Number"
                                margin="normal"
                                name="mobile_number"
                                variant="outlined"
                                value={values.mobile_number}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <TextField
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                label="Email Id (This will be your Username) "
                                margin="normal"
                                type="email"
                                name="email"
                                variant="outlined"
                                value={values.email}
                              />
                            </Grid>
                            <Grid item md={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  label="Date of Birth"
                                  inputFormat="DD/MM/YYYY"
                                  name="dob"
                                  value={values.dob}
                                  disableFuture
                                  onChange={(value) => {
                                    setFieldValue("dob", value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      style={{ marginTop: 16, marginBotton: 8 }}
                                      {...params}
                                      error={Boolean(touched.dob && errors.dob)}
                                      helperText={touched.dob && errors.dob}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                          <TextField
                            error={Boolean(
                              touched.organization && errors.organization
                            )}
                            helperText={
                              touched.organization && errors.organization
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            fullWidth
                            label="Organization"
                            margin="normal"
                            name="organization"
                            variant="outlined"
                            value={values.organization}
                          />
                          <FormControl
                            fullWidth={values.user_identity == "OTHER"}
                            error={Boolean(
                              touched.user_identity && errors.user_identity
                            )}
                          >
                            <FormLabel id="registration-user-type-radio-buttons">
                              Identify Yourself
                            </FormLabel>
                            <RadioGroup
                              onBlur={handleBlur}
                              onChange={handleChange}
                              aria-labelledby="registration-user-type-radio-buttons"
                              name="user_identity"
                            >
                              {userIdentityTypes.map((uit, k) => {
                                return (
                                  <FormControlLabel
                                    key={k}
                                    value={uit["value"]}
                                    control={<Radio />}
                                    label={uit["label"]}
                                  />
                                );
                              })}
                            </RadioGroup>
                            <FormHelperText>
                              {touched.user_identity && errors.user_identity}
                            </FormHelperText>
                          </FormControl>
                          {values.user_identity == "OTHER" && (
                            <TextField
                              error={Boolean(
                                touched.other_user_type &&
                                  errors.other_user_type
                              )}
                              helperText={
                                touched.other_user_type &&
                                errors.other_user_type
                              }
                              autoFocus
                              value={values.other_user_type}
                              label="Mention Other User Type"
                              margin="normal"
                              name="other_user_type"
                              variant="outlined"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          )}
                          {values.user_identity == "GERON_" && (
                            <FormControl
                              error={Boolean(
                                touched.emp_type && errors.emp_type
                              )}
                            >
                              <FormLabel id="registration-geron-type-radio-buttons">
                                Geron Employee Type
                              </FormLabel>
                              <FormGroup>
                                {geronEmpTypes.map((get, k) => {
                                  return (
                                    <FormControlLabel
                                      key={k}
                                      label={get["label"]}
                                      control={
                                        <Checkbox
                                          name="emp_type"
                                          value={get["value"]}
                                          checked={values.emp_type.includes(
                                            get["value"]
                                          )}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      }
                                    />
                                  );
                                })}
                                {values.emp_type.includes(
                                  "MOBILE_APP_USER"
                                ) && (
                                  <TextField
                                    error={Boolean(
                                      touched.mobile_sub_type &&
                                        errors.mobile_sub_type
                                    )}
                                    helperText={
                                      touched.mobile_sub_type &&
                                      errors.mobile_sub_type
                                    }
                                    fullWidth
                                    label="Mobile User Type"
                                    name="mobile_sub_type"
                                    onChange={handleChange}
                                    select
                                    value={values.mobile_sub_type}
                                    variant="outlined"
                                  >
                                    {mobileAppUserType.map((e, k) => {
                                      return (
                                        <MenuItem key={k} value={e.value}>
                                          {e.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                )}
                              </FormGroup>
                              <FormHelperText>
                                {touched.emp_type && errors.emp_type}
                              </FormHelperText>
                            </FormControl>
                          )}
                          <Grid container spacing={2} style={{ marginTop: 0 }}>
                            <Grid item>
                              <TextField
                                error={Boolean(
                                  touched.npassword && errors.npassword
                                )}
                                helperText={
                                  touched.npassword && errors.npassword
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Password:"
                                type={showNewPassword ? "text" : "password"}
                                name="npassword"
                                margin="normal"
                                fullWidth
                                value={values.npassword}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        edge="end"
                                      >
                                        {showNewPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                error={Boolean(
                                  touched.cpassword && errors.cpassword
                                )}
                                helperText={
                                  touched.cpassword && errors.cpassword
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Confirm Password:"
                                type={showCPassword ? "text" : "password"}
                                name="cpassword"
                                margin="normal"
                                fullWidth
                                value={values.cpassword}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowCPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                      >
                                        {showCPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              disabled={showOTP}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              Submit
                            </Button>
                          </Box>
                          {respMessage.type ? (
                            <div
                              style={{
                                color:
                                  respMessage.type == "success"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              <p>{respMessage.message}</p>
                              <ul>{respMessage.fields}</ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </form>
                      )}
                    </Formik>
                    {showOTP && (
                      <Formik
                        initialValues={{
                          otp: "",
                        }}
                        validationSchema={Yup.object().shape({
                          otp: Yup.string().required("OTP is required"),
                        })}
                        onSubmit={async (
                          values,
                          { setErrors, setStatus, setSubmitting }
                        ) => {
                          dispatch(
                            verifyOtpAndUpdate({
                              ...values,
                              mobile_number: mobileNumber,
                            })
                          );
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                        }) => (
                          <form noValidate onSubmit={handleSubmit}>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: 0 }}
                            >
                              <Grid item md={6}>
                                <TextField
                                  margin="normal"
                                  fullWidth
                                  error={Boolean(touched.otp && errors.otp)}
                                  helperText={touched.otp && errors.otp}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  name="otp"
                                  label="Enter OTP"
                                  value={values.otp}
                                />
                              </Grid>
                              <Grid item md={3} style={{ marginTop: 16 }}>
                                <Button
                                  // disabled={canLogin}
                                  style={{ padding: 14 }}
                                  type="submit"
                                  variant="contained"
                                  onClick={() => {
                                    // setShowOTP(true);
                                  }}
                                >
                                  Verify
                                </Button>
                              </Grid>
                              <Grid item md={3} style={{ marginTop: 16 }}>
                                {resendOtpEnabled?<Button disabled={resendOtpState} onClick={() => {
                                  dispatch(generateOTP({mobile_number:mobileNumber}))
                                }}>Resend OTP</Button> : ""}
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Formik>
                    )}
                    {votpRespMessage.type ? (
                      <div
                        style={{
                          color:
                            votpRespMessage.type == "success" ? "green" : "red",
                        }}
                      >
                        <p>{votpRespMessage.message}</p>
                        <ul>{votpRespMessage.fields}</ul>
                      </div>
                    ) : (
                      ""
                    )}
                    <Grid container marginTop={1} flexDirection="column">
                      <Grid item>
                        Already have an account?{" "}
                        <Link href={"/authentication/signin"}>Login</Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <span>
              Powered by <b>Geron Engineering Private Limited &#174;</b>
            </span>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default SignUp;
