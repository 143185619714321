import {
    Box, Card, CardHeader, Breadcrumbs, Container, Grid, Link, Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';
import { ElectricityAndMonitoring, SchemeHealth, SchemeIssues } from '../../components/dashboard/schemedashboard';
import { LocationFilter } from '../../components/dashboard/shemes';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { getSchemes } from '../../slices/siteInfo';
import { Loader } from '../../components/Loader/Loader';
import { getScheduledData } from '../../slices/scheduledData';
import { historyDate } from '../../utils/helper';
import { getElectricityAndMotor } from '../../slices/electricityAndMotor';
import FunctionalityUtilisation from './_functionality_utilisation';

const SchemeDashboard = (props) => {
    const { settings } = useSettings();
    const dispatch = useDispatch()
    const { schemes } = useSelector((state) => state.siteinfo);
    const { scheduled } = useSelector((state) => state.scheduledData);
    const { electricityAndMotor } = useSelector((state) => state.electricityAndMotor);
    const [schemeId, setSchemeId] = useState("--");
    const [oneScheme, setOneSchemes] = useState({})
    const [scheduleData, setScheduleData] = useState({})
    const [electricityAndMotorData, setElectricityAndMotorData] = useState({})

    useEffect(() => {
        if (Object.keys(oneScheme).length == 0) {
            if (!props.type && props.schemeId)
                dispatch(getSchemes({ ward: props.schemeId }))
        }
    }, [props.schemeId])

    useEffect(() => {
        const did = oneScheme["smsid"] ? oneScheme["smsid"] : ""
        if (did) {
            const h = historyDate(1)
            dispatch(getScheduledData(did, h))
        }
    }, [oneScheme])

    useEffect(() => {
        const did = oneScheme["smsid"] ? oneScheme["smsid"] : ""
        if (did)
            dispatch(getElectricityAndMotor(did, historyDate(10)))
    }, [oneScheme])

    useEffect(() => {
        if (scheduled["status"] == "SUCCESS")
            setScheduleData(scheduled["data"])
    }, [scheduled])

    useEffect(() => {
        if (!props.type)
            dispatch(getSchemes({ ward: schemeId }))
    }, [schemeId])

    useEffect(() => {
        const data = electricityAndMotor["status"] == "SUCCESS" ? electricityAndMotor["data"] : {}
        setElectricityAndMotorData(data)
    }, [electricityAndMotor])

    useEffect(() => {
        let schemeData = (schemes["data"] && schemes["data"].length) ? schemes["data"] : []
        if (schemeData.length > 1 && props.schemeId) {
            schemeData = schemeData.filter((e) => e.id == props.schemeId)
            if (schemeData.length)
                setOneSchemes(schemeData[0])
        } else if (schemeData.length == 1) {
            schemeData = schemeData[0]
            setOneSchemes(schemeData)
        }
    }, [schemes])

    return (
        <>
            {props.type != "subComponent" && <Helmet>
                <title>Scheme Dashboard | Har Ghar Nal Jal Yojna</title>
            </Helmet>}
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: props.type != "subComponent" ? 3 : 0
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    {props.type != "subComponent" && <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Scheme Dashboard
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Scheme Dashboard
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                        {/* <Grid item>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid item>
                                    <MobileDatePicker
                                        label="From Date"
                                        // onChange={(newDate) => setStartDate(newDate)}
                                        renderInput={(inputProps) => (
                                            <TextField
                                                variant="outlined"
                                                {...inputProps}
                                            />
                                        )}
                                    // value={}
                                    />
                                </Grid>
                                <Grid item>
                                    <MobileDatePicker
                                        label="To Date"
                                        // onChange={(newDate) => setStartDate(newDate)}
                                        renderInput={(inputProps) => (
                                            <TextField
                                                variant="outlined"
                                                {...inputProps}
                                            />
                                        )}
                                    // value={}
                                    />
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>}

                    <Box sx={{ mt: 3 }}>

                        <Grid
                            container
                            spacing={3}
                        >
                            {props.type != "subComponent" &&
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <LocationFilter onChange={(d) => { if (d["ward"]) { setSchemeId(d["ward"]) } }} />
                                </Grid>}
                            <Grid
                                item
                                md={4}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader title={"Information"} />
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        Site Name
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {oneScheme["blockName"]}-{oneScheme["panchayatName"]}-{oneScheme["subScheme"]}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        Scheme Name
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {oneScheme["subScheme"]}-{oneScheme["schemeId"]}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        SUB SCHEME NAME
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {oneScheme["subScheme"]}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        WARD
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        Ward - {oneScheme["name"]}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        PANCHAYAT
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {oneScheme["panchayatName"]}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        BLOCK
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {oneScheme["blockName"]}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        DIVISION
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {oneScheme["division"]}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        SCHEME CODE
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {oneScheme["schemeId"]}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="subtitle2"
                                                    >
                                                        DEVICE ID
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="body2"
                                                    >
                                                        {oneScheme["other"] ? oneScheme["other"]["deviceid"].padStart(5, '0') : "32"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                            >
                                <Card style={{ height: "100%" }}>
                                    <SchemeHealth schemeId={oneScheme["smsid"]} />
                                </Card>
                            </Grid>
                            <FunctionalityUtilisation scheduleData={scheduleData} />
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Card>
                                    <CardHeader title={"Electricity And Motor Running Hrs"} />
                                    <ElectricityAndMonitoring data={electricityAndMotorData} />
                                </Card>
                            </Grid>
                            {props.type != "subComponent" &&
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <Card>
                                        <CardHeader title={"Scheme Issues (Work In Progress)"} />
                                        <SchemeIssues />
                                    </Card>
                                </Grid>}
                        </Grid>
                    </Box>
                </Container>
            </Box>
            {schemes["status"] == "loading" ? <Loader /> : null}
        </>

    )
}

export default SchemeDashboard