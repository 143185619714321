import { createSlice } from "@reduxjs/toolkit";
import { API } from "../config";
import { get, post } from "./service";

const initialState = {
    nonFunctionalDevices: {
        status: null,
        data: null,
    },
    updateActionForIssue : {
        status: null,
        date: null
    },
    maintenanceHistory : {
        status: null,
        date: null
    },
    manualDeviceTrigger : {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: "maintenanceStore",
    initialState,
    reducers: {
        setNonFunctionalDevices(state, action) {
            state.nonFunctionalDevices = action.payload;
        },
        setUpateActionForIssue(state, action) {
            state.updateActionForIssue = action.payload;
        },
        setMaintenanceHistory(state, action) {
            state.maintenanceHistory = action.payload;
        },
        setManualDeviceTrigger(state, action) {
            state.manualDeviceTrigger = action.payload;
        }
    },
});

export const { reducer } = slice;

export const getNonFunctionalDevices = (location, dateRange) => async (dispatch) => {
    dispatch(slice.actions.setNonFunctionalDevices({ ...initialState.target, status: "loading" }));

    post(API.base_url + "phed/maintenance/non_functional_devices", { ...location, dateRange })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setNonFunctionalDevices(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setNonFunctionalDevices({ ...errResponse, status: "failed" }));
        });
};

export const updateActionForIssue = (data) => async (dispatch) => {
    dispatch(slice.actions.setUpateActionForIssue({ ...initialState.updateActionForIssue, status: "loading" }));

    post(API.base_url + "phed/maintenance/add_action_for_issue", data)
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setUpateActionForIssue(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setUpateActionForIssue({ ...errResponse, status: "failed" }));
        });
};

export const getMaintenanceHistory = (smsid) => async (dispatch) => {
    dispatch(slice.actions.setMaintenanceHistory({ ...initialState.maintenanceHistory, status: "loading" }));

    get(API.base_url + "phed/maintenance/history", {smsid})
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setMaintenanceHistory(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setMaintenanceHistory({ ...errResponse, status: "failed" }));
        });
};

export const manualDeviceTrigger = (data) => async (dispatch) => {
    dispatch(slice.actions.setManualDeviceTrigger({ ...initialState.manualDeviceTrigger, status: "loading" }));

    post(API.base_url + "phed/maintenance/manual_device_trigger", data)
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setManualDeviceTrigger(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setManualDeviceTrigger({ ...errResponse, status: "failed" }));
        });
};

export default slice;
