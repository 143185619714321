import { API } from "../config";
import { filepost, get } from "../slices/service";
import toast from "react-hot-toast";


export const uploadAndCreateSingleGlandDevices = async ({ file }) => {
  try {
    if (!file) {
      throw new Error("Please select a file!");
    }
    const singleGland = await filepost(
      API.base_url + "phed/upload-single-gland-devices",
      { single_gland_file: file }
    );

    if (singleGland.statusCode === 201) {
      toast.success(
        singleGland?.message || "Device details updated successfully!!",
        {
          duration: 4000,
        }
      );
      return singleGland;
    } else if (singleGland.statusCode === 400) {
      toast.error(singleGland?.message || "Missing some fields!", {
        duration: 4000,
      });
      return singleGland;
    } else if (singleGland.statusCode === 409) {
      toast.error(singleGland?.message || "All devices existed!", {
        duration: 4000,
      });
      return singleGland;
    } else {
      throw new Error(
        singleGland.message || "Error in uploading the csv file!"
      );
    }
  } catch (error) {
    toast.error(
      error.message || "Error occurred while uploading the csv file!",
      {
        duration: 4000,
      }
    );
  }
};

export const getSingleGlandDevices = async () => {
  try {
    const singleGland = await get(API.base_url + "phed/single-gland-devices");
    if (singleGland.statusCode === 200) {
      return singleGland;
    } else if (singleGland.statusCode === 404) {
      return;
    } else {
      throw new Error(
        singleGland.message ||
          "Error occurred while getting the single gland devices!"
      );
    }
  } catch (error) {
    toast.error(
      error.message || "Error occurred while getting the single gland devices!",
      {
        duration: 4000,
      }
    );
  }
};
