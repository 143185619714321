import { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "../../icons/ChevronRight";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { getRegisteredDevices } from "../../slices/firmwareRegistration";
import { convertToJSON, getDateTime, hasProperties2 } from "../../utils/helper";
import ShowWhen from "../../components/common/ShowWhen";
import { Loader } from "../../components/Loader/Loader";
import CustomFilter from "../../components/common/CustomFilter";
import RegisteredDevicesList from "../../components/common/RegisteredDevicesList";
import FirmwareExports from "../../components/FirmwareExports";

export default function RegisteredDeviceList() {
  const filterProperties = ["barcode", "imei", "simNumber"];
  const [filter, setFilter] = useState(null);
  const [exportFilter, setExportFilter] = useState(null);
  const [continuationTokens, setContinuationTokens] = useState([null]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rows, setRegDeviceData] = useState([]);
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [nextNewToken, setNewToken] = useState(null)
  const pageSize = 40
  const [totalDocuments, setTotalDocuments] = useState({
    documentsCount: 0,
  });
  const { firmwareData: firmwareDataApiResponse } = useSelector(
    (state) => state.firmwareRegistration
  );
  const dispatch = useDispatch();

  const handleSearch = () => {
    const hasValidFilter = Object.values(filter || {}).some(
      (value) => value.length >= 3
    );
    if (continuationTokens?.length > 0) {
      setContinuationTokens((prevTokens) => [prevTokens[0]]);
      setCurrentIndex(0);
    }
    if (hasValidFilter) {
      dispatch(getRegisteredDevices({ filter }));
    }
    setExportFilter(filter)
  }


  useEffect(() => {
    if (continuationTokens?.length > 0) {
      setContinuationTokens((prevTokens) => [prevTokens[0]]);
      setCurrentIndex(0);
    }

    if (isInitialFetch || !hasProperties2(filter)) {
      dispatch(getRegisteredDevices({ filter }));
      setIsInitialFetch(false);
    }
  }, [dispatch, filter]);

  useEffect(() => {
    if (firmwareDataApiResponse && firmwareDataApiResponse.data) {
      let tempTotalDocs = firmwareDataApiResponse?.headers?.["x-ms-resource-usage"];
      tempTotalDocs = convertToJSON(tempTotalDocs);
      setTotalDocuments(tempTotalDocs);

      const newToken = firmwareDataApiResponse.nextContinuationToken;
      if (continuationTokens[currentIndex] !== newToken && newToken) {
        setNewToken(newToken);
      }

      const rws = firmwareDataApiResponse.data.map((e, i) => {
        const dt = getDateTime(e._ts);
        i = i + 1;
        return {
          slno: pageSize * currentIndex + i,
          ...e,
          created_datetime: `${dt["date"]} ${dt["time"]}`,
        };
      });
      setRegDeviceData(rws);
    }
  }, [firmwareDataApiResponse,]);


  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 80,
      sortable: false,
      headerClassName: "scheme-data-table",
      hide: false
    },
    {
      field: "barcode",
      headerName: "Barcode",
      width: 120,
      sortable: false,
      hide: false
    },
    {
      field: "imei",
      headerName: "IMEI",
      width: 170,
      sortable: false,
    },
    {
      field: "deviceId",
      headerName: "Software Device Id",
      width: 140,
      sortable: false,
    },
    {
      field: "simNumber",
      headerName: "SIM Number",
      width: 200,
      sortable: false,
    },
    {
      field: "carrierName",
      headerName: "Carrier Name",
      width: 140,
      sortable: false,
    },
    {
      field: "user_name",
      headerName: "User Name",
      width: 200,
      sortable: false,
    },
    {
      field: "created_datetime",
      headerName: "Date Time",
      width: 200,
      sortable: false,
    },
    {
      field: "certificate_path",
      headerName: "Certificate",
      width: 130,
      sortable: false,
      renderCell: (params) => {
        const data = params["formattedValue"];
        return data ? (
          <Link target="_blank" href={data}>
            Download
          </Link>
        ) : (
          "Not Found"
        );
      },
    },
  ];

  const fetchNext = async () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1));
    setContinuationTokens((prevTokens) => [...prevTokens, nextNewToken]);
    dispatch(getRegisteredDevices({ filter, continuationToken: nextNewToken }));
  };

  const fetchPrevious = () => {
    if (currentIndex >= 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1));
      const prevToken = continuationTokens[continuationTokens.length - 2];
      setContinuationTokens((prevTokens) => prevTokens.slice(0, -1));
      dispatch(getRegisteredDevices({ filter, continuationToken: prevToken }));
    }
  };

  return (
    <>
      <Helmet>
        <title>Maintenance | Har Ghar Nal Jal Yojna</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Registered Device List
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/overview"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Firmware Loading
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ py: 3 }}>
            <div style={{ height: 650, width: "100%" }}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                <CustomFilter
                  filter={filter}
                  filterProperties={filterProperties}
                  setFilter={setFilter}
                  handleSearch={handleSearch}
                />
              </Box>
              <FirmwareExports filter={exportFilter} />
              <RegisteredDevicesList isShowTable fetchNext={fetchNext} fetchPrevious={fetchPrevious} hasMoreResults={!firmwareDataApiResponse?.hasMoreResults} columns={columns} currentIndex={currentIndex} pageSize={40} rows={rows} totalDocuments={totalDocuments?.documentsCount || 0} />
            </div>
          </Box>
        </Container>
      </Box>
      <ShowWhen
        when={firmwareDataApiResponse?.status === "loading"}
        component={<Loader text="Loading..." />}
      />
    </>
  );
}
