import {
    Box,
    Breadcrumbs,
    Container,
    Divider,
    Grid,
    Link,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';

const DivisionDashboard = () => {
    return (
        <>
            <Helmet>
                <title>Division Dashboard | Har Ghar Nal Jal Yojna</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2
                }}
            >
                <div>DivisionDashboard (Work In Progress)</div>
            </Box>
        </>

    )
}

export default DivisionDashboard