import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { getDateTime } from "../../../utils/helper";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { ISSUE_TYPES, priColor } from "../../../constants";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const issueTarget = Object.keys(ISSUE_TYPES);
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 2 * 4.5 + 2,
            width: "100%",
        },
    },
};

Array.prototype.move = function (x, y) {
    this.splice(y, 0, this.splice(x, 1)[0]);
    return this;
};

export default function UpdateActionModal(props) {
    const cdatetime = new Date().getTime() / 1000;
    const dateTimeArray = getDateTime(cdatetime);
    const [action, setAction] = useState("");
    const [issueTargetSelected, setIssueTargetSelected] = useState([]);

    const [selectedIssueList, setSelectedIssueList] = useState({});
    const [otherIssue, setOtherIssue] = useState({});

    useEffect(() => {
        setAction("");
        setIssueTargetSelected([]);
        setSelectedIssueList({});
        setOtherIssue({});
    }, [props.openStatus]);

    const handleChange = (e) => {
        const tmp = [...issueTargetSelected];
        if (tmp.includes(e.target.name)) {
            const index = tmp.indexOf(e.target.name);
            if (index > -1) {
                tmp.splice(index, 1);
            }
            setIssueTargetSelected(tmp);

            //Clear selected issues
            let selectedIssueListTmp = { ...selectedIssueList };
            if (selectedIssueListTmp[e.target.name]) {
                selectedIssueListTmp[e.target.name] = [];
            }
            setSelectedIssueList(selectedIssueListTmp);

            //Clear other issues
            let otherIssueTmp = { ...otherIssue };
            if (otherIssueTmp[e.target.name]) {
                otherIssueTmp[e.target.name] = [];
            }
            setOtherIssue(otherIssueTmp);
        } else {
            tmp.push(e.target.name);
            setIssueTargetSelected(tmp);

            //Add entry to the issue list
            let selectedIssueListTmp = { ...selectedIssueList };
            if (!selectedIssueListTmp[e.target.name]) {
                selectedIssueListTmp[e.target.name] = [];
            }
            setSelectedIssueList(selectedIssueListTmp);

            //Set Other Issue
            let otherIssueTmp = { ...otherIssue };
            if (!otherIssueTmp[e.target.name]) {
                otherIssueTmp[e.target.name] = [];
            }
            setOtherIssue(otherIssueTmp);
        }
    };

    const handleIssueChange = (type, sil) => {
        const tmpSelectedIL = { ...selectedIssueList };
        let tmpSelectedForType = [...tmpSelectedIL[type]];
        tmpSelectedForType = new Set([...sil.target.value]);
        tmpSelectedIL[type] = Array.from(tmpSelectedForType);
        setSelectedIssueList(tmpSelectedIL);
    };

    const handleOtherIssueChange = (type, sil) => {
        const tmpotherIssue = { ...otherIssue };
        tmpotherIssue[type] = sil.target.value;
        setOtherIssue(tmpotherIssue);
    };

    const isInValid = () => {
        let invalid = false;
        const keys = issueTargetSelected;
        if (keys.length) {
            for (let i = 0; i < keys.length; i++) {
                invalid = selectedIssueList[keys[i]].length == 0;
            }
        } else if (action == "") {
            invalid = true;
        }

        return invalid;
    };

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                onClose={(e, r) => {
                    if (r != "backdropClick") {
                        props.setOpenStatus(false);
                    }
                }}
                aria-labelledby="customized-dialog-title"
                open={props.openStatus}
                disableEscapeKeyDown={true}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => props.setOpenStatus(false)}
                >
                    Update action taken
                </DialogTitle>
                <DialogContent dividers>
                    {props.deviceDatails && (
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography gutterBottom>
                                District Name :{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["districtName"]}{" "}
                                </span>
                            </Typography>
                            <Typography gutterBottom>
                                Block Name:{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["blockName"]}{" "}
                                </span>{" "}
                            </Typography>

                            <Typography gutterBottom>
                                Panchayat Name:{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["panchayatName"]}
                                </span>
                            </Typography>

                            <Typography gutterBottom>
                                Ward :{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["name"]}
                                </span>{" "}
                            </Typography>

                            <Typography gutterBottom>
                                Device Id :{" "}
                                <span style={{ color: priColor }}>
                                    {props.deviceDatails["other"]["deviceid"]}{" "}
                                </span>
                                <br />
                            </Typography>
                        </Box>
                    )}
                    <Typography gutterBottom>
                        {dateTimeArray["date"]} - {dateTimeArray["time"]}
                    </Typography>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Select Issue Target</FormLabel>
                        <FormGroup>
                            {issueTarget.map((e) => (
                                <Fragment>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={issueTargetSelected.includes(e)}
                                                onChange={handleChange}
                                                name={e}
                                            />
                                        }
                                        label={e}
                                    />
                                    {issueTargetSelected.includes(e) && (
                                        <Fragment>
                                            <FormControl style={{ marginBottom: 20 }}>
                                                <InputLabel id="demo-mutiple-checkbox-label">
                                                    Select {e} issue type
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={selectedIssueList[e]}
                                                    onChange={(sil) => handleIssueChange(e, sil)}
                                                    input={<Input />}
                                                    select
                                                    renderValue={(selected) => selected.join(", ")}
                                                >
                                                    {ISSUE_TYPES[e].map((name) => (
                                                        <MenuItem key={name} value={name}>
                                                            <Checkbox
                                                                checked={selectedIssueList[
                                                                    e
                                                                ].includes(name)}
                                                            />
                                                            <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {selectedIssueList[e].includes("Other") && (
                                                <Fragment>
                                                    <TextField
                                                        fullWidth
                                                        value={otherIssue[e]}
                                                        onChange={(evnt) =>
                                                            handleOtherIssueChange(e, evnt)
                                                        }
                                                        name="issueDetails"
                                                        multiline
                                                        rows={2}
                                                        placeholder={`Type issue details for ${e}`}
                                                    />
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ))}
                        </FormGroup>
                    </FormControl>
                    <br />
                    <br />
                    <Typography gutterBottom>
                        Action Taken:
                        <TextField
                            fullWidth
                            value={action}
                            onChange={(e) => setAction(e.target.value)}
                            name="actionTaken"
                            multiline
                            rows={4}
                            placeholder="Type corrective action taken for above issue"
                        />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Tooltip open={isInValid()} title="Please enter issue details">
                        <Button
                            autoFocus
                            onClick={() =>
                                props.updateActionForIssue({
                                    smsid: props.deviceDatails["smsid"],
                                    issueList: selectedIssueList,
                                    otherIssue,
                                    action,
                                    cdatetime,
                                })
                            }
                            color="primary"
                            disabled={isInValid()}
                        >
                            Submit
                        </Button>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </div>
    );
}
