import { Card, CardHeader } from "@material-ui/core"
import React from "react"
import GoogleMapReact from 'google-map-react';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import water_tank from "../../../../images/water_tank.png"

const AnyReactComponent = ({ text }) => <img src={water_tank} width="40px" alt="water tank" />

const defaultPropsStatic = {
    center: {
        lat: 25.798702414,
        lng: 85.5214789847141
    },
    zoom: 8
};

const SiteInfoMap = (props) => {

    const [map, setMap] = useState(null)
    const [locationData, setLocationData] = useState(null)
    // const [locationData, setLocationData] = useState({
    //     center: {
    //         lat: 25.798702414,
    //         lng: 85.5214789847141
    //     },
    //     zoom: 8
    // })
    const { stateMapData, distMapData } = useSelector((state) => state.map);
    const [stateGeojson, setStateGeojson] = useState({
        "type": "FeatureCollection",
        "name": "India_Districts",
        "features": []
    })
    const [distGeojson, setDistGeojson] = useState({
        "type": "FeatureCollection",
        "name": "India_Districts",
        "features": []
    })

    useEffect(() => {
        if (props.locationinfo && props.locationinfo["lat"] != '' && !isNaN(props.locationinfo["lat"]) && !isNaN(props.locationinfo["lng"])) {
            setLocationData({
                center: {
                    lat: parseFloat(props.locationinfo["lat"]),
                    lng: parseFloat(props.locationinfo["lng"])
                },
                zoom: 8
            })
        }
    }, [props.locationinfo]);

    useEffect(() => {
        if (distMapData && distMapData.data && distMapData.data.type == "district" && distMapData.data.geojson) {
            setDistGeojson(distMapData.data.geojson)
        }
    }, [])

    useEffect(() => {
        if (map) {
            map.data.forEach((feature) => {
                if (feature.getProperty("type") == "state")
                    map.data.remove(feature);
            });
            map.data.addGeoJson(stateGeojson)
        }
    }, [stateGeojson])

    return (
        <Card {...props}>
            <CardHeader title="GIS Location" />
            <div style={{ height: '36vh', width: '100%' }}>
                {locationData && locationData.center && locationData.center.lat && locationData.center.lng ?
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyC_-u8-cI5xWvUj49k9Y2-bor3F_k_-__A' }}
                        defaultCenter={locationData.center}
                        defaultZoom={locationData.zoom}
                        options={{ mapId: "8467585508bc0de4" }}
                    >
                        <AnyReactComponent lat={locationData.center.lat} lng={locationData.center.lng} />
                    </GoogleMapReact> : <div style={{ padding: 20 }} >No Coordinate Details Found!</div>}
            </div>
        </Card>
    )
}

export default SiteInfoMap