import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get } from './service';

const initialState = {
    stateMapData: {
        status: null,
        data: null
    },

    distMapData: {
        status: null,
        data: null
    },
    status: null
};

const slice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setStateGeoJson(state, action) {
            state.stateMapData = action.payload;
            state.status = action.payload.status
        },
        setDistGeoJson(state, action) {
            state.distMapData = action.payload;
            state.status = action.payload.status
        }
    }
});

export const { reducer } = slice;

export const loadStateMapData = (type, name) => async (dispatch) => {
    
    dispatch(slice.actions.setStateGeoJson({ ...initialState.stateMapData, status: "loading" }));

    get(API.base_url + "phed/geolocation", { type: type, name: name })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setStateGeoJson(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setStateGeoJson({ ...errResponse, status: "failed" }))
        });
};

export const loadDistMapData = (type, name) => async (dispatch) => {
    dispatch(slice.actions.setDistGeoJson({ status: "loading" }));

    get(API.base_url + "phed/geolocation", { type: type, name: name })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDistGeoJson(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDistGeoJson({ ...errResponse, status: "failed" }))
        });
};

export default slice;