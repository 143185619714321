import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get } from './service';

const initialState = {
    electricityAndMotor: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'electricityAndMotor',
    initialState,
    reducers: {
        setElectricityAndMotor(state, action) {
            state.electricityAndMotor = action.payload
        }
    }
});

export const { reducer } = slice;

export const getElectricityAndMotor = (did, dateRange) => async (dispatch) => {
    dispatch(slice.actions.setElectricityAndMotor({ ...initialState.electricityAndMotor, status: "loading" }));

    get(API.base_url + "phed/electricity_and_motor", { id: did, from: dateRange[0], to: dateRange[1] })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setElectricityAndMotor(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setElectricityAndMotor({ ...errResponse, status: "failed" }))
        });
};

export default slice;