import { Grid, Typography } from "@material-ui/core";
import { priColor } from "../../constants";
import { useState } from "react";
import { DialogPopup } from "../ImageViewer";
import SimpleImageSlider from "react-simple-image-slider";

const ImagesWrapper = ({ images, title, mt = 0, titleStyle = "" }) => {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  return (
    <>
      {
        title && <Grid
          item
          md={12}
          mt={mt}
          display="flex"
          flexDirection={"row"}
          marginBottom={1}
        >
          <Typography
            variant="subtitle2"
            style={{ color: priColor }}
            sx={{
              ...titleStyle,
            }}
          >
            {title}
          </Typography>
        </Grid>
      }
      <Grid container spacing={1} gap={3}>
        {images.map((e, i) => {
          return (
            <Grid item key={i} md={2} sx={{ cursor: "pointer" }}>
              <img
                style={{ borderRadius: "10px", minHeight: "80px", minWidth: "80px" }}
                width={"100%"}
                src={e.url}
                alt=""
                onClick={() => {
                  setOpen(true);
                  setCurrentImage(i);
                }}
              />
              <span>{e.title}</span>
            </Grid>
          );
        })}
      </Grid>
      {currentImage !== null && (
        <DialogPopup open={open} handleClose={() => setOpen(false)}>
          <SimpleImageSlider
            width={500}
            height={700}
            startIndex={currentImage}
            images={images}
            showBullets={true}
            showNavs={true}
          />
        </DialogPopup>
      )}
    </>
  );
};

export default ImagesWrapper;
