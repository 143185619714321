import { useTheme } from "@emotion/react";
import moment from "moment-timezone";
import React, { useState, useEffect } from "react"
import Chart from 'react-apexcharts';

const ApexChart = (props) => {

    const theme = useTheme();
    const [series, setSeries] = useState([])
    const [labels, setLabels] = useState([])

    useEffect(() => {
        if (props.data) {
            const data = props.data
            const dates = Object.keys(data)
            if (dates.length) {
                let l = []
                l = dates.map(e => e * 1000)
                setLabels(l)


                let e = []
                let m = []
                for (let i = 0; i < dates.length; i++) {
                    e.push(data[dates[i]]["electricityAvalability"])
                    m.push(data[dates[i]]["motorRunning"])
                }

                let series1 = [{
                    name: 'Electricity',
                    type: 'column',
                    data: e
                }, {
                    name: 'Motor Running',
                    type: 'area',
                    data: m
                }]
                setSeries(series1)
            }
        }
    }, [props.data])

    const options = {
        chart: {
            type: 'line',
            background: 'parent'
        },
        legend: {
            labels: {
                colors: theme.palette.text.secondary
            },
            show: true
        },
        stroke: {
            width: [0, 2, 5],
            curve: 'smooth'
        },
        theme: {
            mode: theme.palette.mode
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },

        fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        grid: {
            show: false,
        },
        labels,
        markers: {
            size: 0
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: process.env.NODE_ENV == 'production'
            }
        },
        yaxis:
            [
                {
                    title: {
                        text: 'Time In Hrs',
                    },
                    min: 0,
                    axisBorder: {
                        show: true,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0
                    }
                }
            ]
        ,
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y;
                    }

                    return y;

                }
            }
        }
    }

    return <div>
        <Chart options={options} series={series} type="line" height={350} />
    </div>
}


export default ApexChart