import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import ChartPieIcon from "../../icons/ChartPie";
import HomeIcon from "../../icons/Home";
import UserIcon from "../../icons/User";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import {
  Dashboard,
  DeviceHubTwoTone,
  LocalDining,
  LocalFireDepartmentSharp,
  Phone,
} from "@material-ui/icons";
import BugReportIcon from "@material-ui/icons/BugReport";
import BuildIcon from "@material-ui/icons/Build";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import { getDeptName } from "../../utils/helper";

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const phedEnabled = getDeptName() === "PHED";
    const udhdEnabled = getDeptName() === "UDHD";
    const prdEnabled = getDeptName() === "PRD";
    let sec = [
      {
        title: "General",
        items: [
          {
            enabled: phedEnabled || udhdEnabled || prdEnabled,
            title: "Home",
            path: "/overview",
            icon: <HomeIcon fontSize="small" />,
          },
          {
            enabled: phedEnabled || udhdEnabled || prdEnabled,
            title: "Schemes",
            path: "/overview/schemes",
            icon: <LocalFireDepartmentSharp fontSize="small" />,
          },
          {
            enabled: phedEnabled || udhdEnabled,
            title: "Dashboards",
            path: "/dashboard",
            icon: <Dashboard fontSize="small" />,
            children: [
              {
                enabled: phedEnabled || udhdEnabled,
                title: "Scheme Dashboard",
                path: "/dashboard/schemedashboard",
              },
              {
                enabled: phedEnabled || udhdEnabled,
                title: "Block Dashboard",
                path: "/dashboard/blockdashboard",
              },
              {
                enabled: phedEnabled || udhdEnabled,
                title: "Division Dashboard",
                path: "/dashboard/divisiondashboard",
              },
              {
                enabled: phedEnabled || udhdEnabled,
                title: "State Dashboard",
                path: "/dashboard/statedashboard",
              },
            ],
          },
          {
            enabled: phedEnabled || udhdEnabled || prdEnabled,
            title: "Reports",
            path: "/overview/reports",
            icon: <ChartPieIcon fontSize="small" />,
          },
        ],
      },
    ];

    if (
      user &&
      user.userType &&
      (user.userType.includes("APP_SUPER_ADMIN") ||
        user.userType.includes("APP_DATA_ENTRY"))
    ) {
      if (sec[0]["items"].filter((e) => e.title == "Admin").length == 0) {
        sec[0]["items"].push({
          enabled: phedEnabled,
          title: "Admin",
          path: "/overview/admin",
          icon: <UserIcon fontSize="small" />,
        });
      }
    }

    if (
      user &&
      user.userType &&
      (user.userType.includes("APP_SUPER_ADMIN") ||
        user.userType.includes("GERON_CALLING"))
    ) {
      if (sec[0]["items"].filter((e) => e.title == "Calling").length == 0) {
        sec[0]["items"].push({
          enabled: phedEnabled || udhdEnabled || prdEnabled,
          title: "Calling",
          path: "/calling",
          icon: <Phone fontSize="small" />,
        });
      }
    }

    if (
      user &&
      user.userType &&
      (user.userType.includes("GERON_FIRMWARE_OPERATOR") ||
        user.userType.includes("APP_SUPER_ADMIN"))
    ) {
      if (sec[0]["items"].filter((e) => e.title == "Firmware").length == 0) {
        sec[0]["items"].push({
          enabled: phedEnabled,
          title: "Firmware",
          icon: <PhonelinkSetupIcon fontSize="small" />,
          children: [
            {
              title: "Firmware Loading",
              path: "/overview/firmware_dashboard",
              enabled: phedEnabled,
            },
            {
              title: "Device List",
              path: "/overview/device-list",
              enabled: phedEnabled,
            },
            {
              title: "Single Gland Devices",
              path: "/overview/single-gland-devices",
              enabled: phedEnabled,
            },
          ],
        });
      }
    }

    if (
      user &&
      user.userType &&
      (user.userType.includes("INSTALLATION_ADMIN") ||
        user.userType.includes("APP_SUPER_ADMIN"))
    ) {
      if (
        sec[0]["items"].filter((e) => e.title == "Installation").length == 0
      ) {
        sec[0]["items"].push({
          enabled: phedEnabled || udhdEnabled || prdEnabled,
          title: "Installation",
          icon: <PhonelinkSetupIcon fontSize="small" />,
          children: [
            {
              title: "Staff Assignment",
              path: "/overview/installation/staff-assignment",
              enabled: phedEnabled || udhdEnabled || prdEnabled,
            },
            {
              title: "Installation Report",
              path: "/overview/installation/installation-report",
              enabled: phedEnabled || udhdEnabled || prdEnabled,
            },
            {
              title: "Manually upload Installation Data",
              path: "/overview/installation/manual-installation",
              enabled: phedEnabled || udhdEnabled || prdEnabled,
            },
            {
              title: "Monitor a device",
              path: "/overview/installation/monitor-device",
              enabled: phedEnabled || udhdEnabled || prdEnabled,
            },
            {
              title: "Installation Tracker",
              path: "/overview/installation/installation-tracker",
              enabled: phedEnabled,
            },
            {
              title: "Manage Manual Installation Upload Ward Data",
              path: "/overview/installation/manage-manual-installation",
              enabled: phedEnabled || udhdEnabled || prdEnabled,
            },
          ],
        });
      }
    }

    if (
      user &&
      user.userType &&
      (user.userType.includes("APP_DATA_MANAGEMENT") ||
        user.userType.includes("APP_SUPER_ADMIN"))
    ) {
      if (
        sec[0]["items"].filter((e) => e.title == "Device Data Management")
          .length == 0
      ) {
        sec[0]["items"].push({
          enabled: phedEnabled || udhdEnabled || prdEnabled,
          title: "Device Data Management",
          path: "/overview/device_data_management",
          icon: <DeviceHubIcon fontSize="small" />,
        });
      }
    }

    if (
      user &&
      user.userType &&
      (user.userType.includes("APP_SUPER_ADMIN") ||
        user.userType.includes("APP_MAINTAINER"))
    ) {
      if (
        sec[0]["items"].filter((e) => e.title == "Installation & Debugging")
          .length == 0
      ) {
        sec[0]["items"].push({
          enabled: phedEnabled,
          title: "Installation & Debugging",
          path: "/overview/debugging",
          icon: <BugReportIcon fontSize="small" />,
        });
      }
      if (sec[0]["items"].filter((e) => e.title == "Maintenance").length == 0) {
        sec[0]["items"].push({
          title: "Maintenance",
          enabled: phedEnabled,
          path: "/overview/maintenance",
          icon: <BuildIcon fontSize="small" />,
        });
      }
    }

    setSections(sec);
  }, [user]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        {/* <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box> */}
        {/* <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Your plan:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box> */}
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        {/* <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
