import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, TextField } from "@material-ui/core"
import { Fragment, useState } from "react"
import { useDispatch } from "react-redux"
import { resetDeviceTelemetry } from "../../slices/debugging"

const DebuggingForm = function (props) {
    const [deviceId, setDeviceId] = useState("")
    const [CTInstalled, setCTInstalled] = useState(false)
    const [CMInstalled, setCMInstalled] = useState(false)
    const dispatch = useDispatch()

    const onClickGo = () => {
        if (deviceId) {
            props.onSubmit(deviceId)
        }
    }
    
    const onClickReset = () => {
        dispatch(resetDeviceTelemetry())
    }

    const isFormIsValid = () => {
        const didInt = parseInt(deviceId)
        if (didInt && CTInstalled && CMInstalled) {
            return true
        } else {
            return false
        }
    }

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <TextField label="Device Id" value={deviceId} name="deviceId" onChange={(e) => setDeviceId(e.target.value.replaceAll(" ", ""))} />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={6} display={"flex"} justifyContent={"left"}>
                    <FormGroup>
                        <FormControlLabel
                            disabled={!deviceId}
                            control={<Checkbox checked={CTInstalled} name="CT Installed" onChange={(e) => setCTInstalled(e.target.checked)} />}
                            label="Is CT installed correctly?"
                        />
                        <FormHelperText>(Please confirm from field that CT is installed to the Line wire going to the Motor and locked properly)</FormHelperText>
                        <br />
                        <FormControlLabel
                            disabled={!deviceId}
                            control={<Checkbox checked={CMInstalled} name="CM Installed" onChange={(e) => setCMInstalled(e.target.checked)} />}
                            label="Is Control Module installed correctly?"
                        />
                        <FormHelperText>(Please confirm from field that Control module powered from the mains not from the starter)</FormHelperText>
                    </FormGroup>
                </Grid>
            </Grid>
            <br />
            <Grid container>
                <Grid item md={6} display={"flex"} justifyContent={"center"}>
                    <Button variant="contained" disabled={!isFormIsValid()} onClick={onClickGo}>Go</Button> &nbsp;
                    <Button onClick={onClickReset}>Reset</Button>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default DebuggingForm